import clsx from 'clsx';
import { FC, Fragment, ReactNode, MouseEvent } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { TextMBold } from '../../theme/typography.module.scss';
import { Close as CloseIcon } from '../Icon';
import { IconButton } from '../Button';
import {
  closeButton,
  container,
  content,
  title,
  row,
  columnLeft,
  columnRight,
  iconColor,
} from './Notification.module.scss';

export interface NotificationProps extends WithIProps<'div'> {
  title?: string;
  content?: string;
  error?: boolean;
}

export const Notification: FC<NotificationProps> = ({ children, className, testID, ...props }) => {
  return (
    <div
      aria-labelledby="notification-title"
      aria-describedby="notification-content"
      {...dataTestID(testID, 'container')}
      {...props}
      className={clsx(container, className)}
    >
      {children}
    </div>
  );
};

export interface NotificationTitleProps extends WithIProps<'h1'> {
  error?: boolean;
  icon?: ReactNode;
  notificationId?: number | string;
  onClose?: (event: MouseEvent<HTMLElement>) => void;
}

export const NotificationTitle: FC<NotificationTitleProps> = ({
  icon,
  notificationId,
  children,
  className,
  onClose,
  testID,
  ...rest
}) => (
  <Fragment>
    {onClose && (
      <IconButton
        labelProps={{ className: iconColor }}
        size="small"
        aria-label="close"
        className={closeButton}
        variant="text"
        onClick={onClose}
        data-notification={notificationId}
        {...dataTestID(testID, 'close-button')}
      >
        <CloseIcon />
      </IconButton>
    )}
    <div className={row}>
      <div className={columnLeft}>{icon || <span>&nbsp;</span>}</div>
      <div className={columnRight}>
        <h1
          id="notification-title"
          className={clsx(title, TextMBold, className)}
          {...dataTestID(testID, 'title')}
          {...rest}
        >
          {children}
        </h1>
      </div>
    </div>
  </Fragment>
);

export const NotificationContent: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => (
  <div className={row}>
    <div className={columnLeft}>&nbsp;</div>
    <div className={columnRight}>
      <div
        id="notification-content"
        className={clsx(content, className)}
        {...dataTestID(testID, 'content')}
        {...rest}
      />
    </div>
  </div>
);
