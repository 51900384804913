import { Dispatch, useEffect, useMemo, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getBaseURL, ParsedIdToken, TokenResponse, useAPIRequest } from '../api';
import { Action, Config, ConfigResponse, readInitialState, reducer } from './boot-reducer';
import { BusinessStream } from '../centres/businessStreamCheck';

export interface AppBootPending {
  loading: true;
  dispatch: Dispatch<Action>;
}

export interface AppBootError {
  loading: false;
  error: string;
  config: Config | null;
  token: TokenResponse | null;
  user: null;
  dispatch: Dispatch<Action>;
}

export interface AppBootReady {
  loading: false;
  error: null;
  config: Config;
  token: TokenResponse;
  user: ParsedIdToken;
  dispatch: Dispatch<Action>;
}

export type AppBootResult = AppBootPending | AppBootError | AppBootReady;

export const useAppBoot = (): AppBootResult => {
  const [state, dispatch] = useReducer(reducer, useMemo(readInitialState, []));
  const { loading, error, config, user, token, codeRequest, redirect, locationRequest } = state;
  const history = useHistory();

  const baseURL = getBaseURL(BusinessStream.ENGLISH);
  const configRequest = useAPIRequest<ConfigResponse>(useMemo(() => ({ url: '/config', baseURL }), [baseURL]));
  const tokenRequest = useAPIRequest<TokenResponse>(
    useMemo(() => codeRequest && { url: '/token', method: 'POST', data: codeRequest, baseURL }, [codeRequest, baseURL]),
  );

  useEffect(() => {
    if (configRequest.error || configRequest.result) dispatch(['CONFIG_RESPONSE', configRequest]);
  }, [configRequest]);

  useEffect(() => {
    if (tokenRequest.error || tokenRequest.result) dispatch(['TOKEN_RESPONSE', tokenRequest]);
  }, [tokenRequest]);

  useEffect(() => {
    if (redirect) history.replace(redirect);
  }, [history, redirect]);

  useEffect(() => {
    if (!token) sessionStorage.removeItem('token');
    else sessionStorage.setItem('token', JSON.stringify(token));
  }, [token]);

  useEffect(() => {
    if (locationRequest) location.assign(locationRequest);
  }, [locationRequest]);

  const mutableResult = useRef({ config, user, token, error });
  mutableResult.current = { config, user, token, error };

  return useMemo((): AppBootResult => {
    if (loading) return { loading: true, dispatch };
    const { current } = mutableResult;
    if (current.error) return { ...current, loading, dispatch } as AppBootError;
    return { ...current, loading, dispatch } as AppBootReady;
  }, [loading]);
};
