import clsx from 'clsx';
import { ChangeEvent, FC, MouseEvent, KeyboardEvent, useCallback, useMemo, useEffect, useReducer } from 'react';
import { Button } from '../../../components/Button';
import { Sidebar, SidebarTitle } from '../../../components/Sidebar';
import { TabButton, TabList, TabPanel } from '../../../components/Tabs';
import { SupervisorsCheckBox } from '../../../components/SupervisorsCheckBox';
import { childTestID, WithIProps, dataTestID } from '../../../util/test-id';
import { Separator } from '../../views';
import { EmptyMessage, SupervisorsComboBox } from './views';
import { useSidebar } from '../SidebarState';
import { Tooltip } from '../SupervisorsViews';
import { TextSBold, TextMRegular } from '../../../theme/typography.module.scss';
import { SupervisorsSaveAction } from './SupervisorsSaveAction';
import { initialState, reducer } from './reducer';
import { useFocusHandler } from './useFocusHandler';
import { TabId, TabInformation } from './types';
import {
  saveButton,
  sidebarActions,
  sidebarContainer,
  tabButton,
  tabLabel,
  tabList,
  tabPanel,
  tabsContainer,
  supervisor,
  supervisorName,
  sidebarSeparator,
  sidebarTitle,
  tabActive,
  sidebarInput,
} from './SupervisorsSidebar.module.scss';
import { useSupervisorsFilter } from './useSupervisorsFilter';
interface SidebarViewProps extends WithIProps<'div'> {
  onClose?: () => void;
}

type ChEvent = ChangeEvent<HTMLInputElement>;

const getTabId = (tabs: TabInformation[], activeTab: TabId, direction: -1 | 1): TabId => {
  const currentIndex = tabs.findIndex((tab) => tab.id === activeTab);
  const nextIndex = currentIndex + direction;
  if (nextIndex >= tabs.length) return tabs[0].id;
  if (nextIndex < 0) return tabs[tabs.length - 1].id;
  return tabs[nextIndex].id;
};

export const SupervisorsSidebar: FC<SidebarViewProps> = ({ className, testID, ...rest }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { focused, refs, activeTab, inputs, assignedSupervisors, allSupervisors } = state;
  const {
    state: { commonUsers, checkedUsers, supervisors, visible, hasChanged },
    dispatch: dispatchSidebar,
  } = useSidebar();

  useFocusHandler(refs, visible, dispatch);
  useSupervisorsFilter(state, supervisors, commonUsers, dispatch);

  const groupId = 'supervisors';

  const tabs: TabInformation[] = useMemo(
    () => [
      {
        id: 'assignedSupervisors',
        name: 'Assigned  supervisors',
        users: assignedSupervisors,
        testID: 'assigned-supervisors',
      },
      {
        id: 'allSupervisors',
        name: 'All supervisors',
        users: allSupervisors,
        testID: 'all-supervisors',
      },
    ],
    [allSupervisors, assignedSupervisors],
  );

  const tabpanelIdList = `${childTestID(groupId, `tabpanel-${tabs[0].testID}`)} ${childTestID(
    groupId,
    `tabpanel-${tabs[1].testID}`,
  )}`;

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      const { key } = event;
      if (key === 'ArrowRight') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTab, 1);
        dispatch(['SELECTED_CHANGE', nActiveTab]);
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTab, -1);
        dispatch(['SELECTED_CHANGE', nActiveTab]);
      }
    },
    [activeTab, tabs],
  );

  const onClear = useCallback(() => {
    refs.input.current?.focus();
    dispatch(['CLEAR_INPUT', activeTab]);
  }, [activeTab, refs.input]);

  const onTabClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const datset = event.currentTarget.dataset;
    const tabid = datset.tabid as TabId | undefined;
    tabid && dispatch(['SELECTED_CHANGE', tabid]);
  }, []);

  const onClose = useCallback(() => {
    dispatchSidebar(['UNSELECT_ALL_SESSIONS']);
  }, [dispatchSidebar]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      const email = event.currentTarget.value;
      if (isChecked) {
        dispatchSidebar(['SET_CHECKED_SINGLE_USER', email]);
      } else {
        dispatchSidebar(['UNSET_CHECKED_SINGLE_USER', email]);
      }
    },
    [dispatchSidebar],
  );

  const onInputChange = useCallback(({ currentTarget }: ChEvent) => {
    dispatch(['INPUT_CHANGE', currentTarget.value]);
  }, []);

  useEffect(() => {
    if (!visible) {
      dispatch(['SELECTED_CHANGE', 'assignedSupervisors']);
      dispatch(['CLEAR_ALL_INPUTS']);
    }
  }, [activeTab, tabs, visible]);

  if (!visible) return null;

  return (
    <Sidebar className={clsx(sidebarContainer, className)} testID={testID} {...rest}>
      <SidebarTitle className={clsx(sidebarTitle, TextSBold)} onClose={onClose} testID={childTestID(testID, 'title')}>
        Supervisors
      </SidebarTitle>
      <Separator className={sidebarSeparator} aria-hidden="true" />
      <div className={tabsContainer}>
        <TabList className={tabList} testID={childTestID(testID, 'tab-list')}>
          {tabs.map((tab) => (
            <TabButton
              id={tab.testID}
              key={tab.id}
              group={groupId}
              tabId={tab.id}
              className={clsx({ [tabActive]: activeTab === tab.id }, tabButton)}
              labelProps={{ className: tabLabel }}
              testID={testID}
              active={activeTab === tab.id}
              onKeyDown={onKeyDown}
              onClick={onTabClick}
            >
              {tab.name}
            </TabButton>
          ))}
        </TabList>
        <SupervisorsComboBox
          id={childTestID(testID, 'combobox')}
          onInputChange={onInputChange}
          input={inputs[activeTab]}
          onClear={onClear}
          refs={refs}
          focused={focused}
          tabpanelIdList={tabpanelIdList}
          testID={childTestID(testID, 'combobox')}
          className={sidebarInput}
        />
        {tabs.map((tab) => (
          <TabPanel id={tab.testID} key={tab.id} group={groupId} active={tab.id === activeTab} testID={testID}>
            <div className={tabPanel} tabIndex={-1}>
              {tab.users.map((u) => (
                <label
                  className={supervisor}
                  key={u.email}
                  htmlFor={`${tab.id}-${u.email}`}
                  {...dataTestID(testID, `label-${tab.testID}`)}
                >
                  <SupervisorsCheckBox
                    onChange={onChange}
                    inputProps={{
                      id: `${tab.id}-${u.email}`,
                      value: u.email,
                      checked: checkedUsers.some((cu) => cu.email === u.email),
                      testID: childTestID(testID, `${tab.testID}-checkbox-${u.email}`),
                    }}
                  />
                  <Tooltip
                    className={clsx(supervisorName, TextMRegular)}
                    text={u.fullName}
                    tooltipText={u.email}
                    testID={childTestID(testID, `${tab.testID}-tooltip-${u.email}`)}
                  />
                </label>
              ))}
              {!tab.users.length && !!inputs[activeTab] && (
                <EmptyMessage>
                  <strong>No supervisors found.</strong> Please try different search terms.
                </EmptyMessage>
              )}
            </div>
          </TabPanel>
        ))}
      </div>
      <Separator className={sidebarSeparator} aria-hidden="true" />
      <div className={sidebarActions}>
        <Button size="small" onClick={onClose} testID={childTestID(testID, `cancel-button`)}>
          Cancel
        </Button>
        <SupervisorsSaveAction
          className={saveButton}
          disabled={!hasChanged}
          testID={childTestID(testID, `save-button`)}
        />
      </div>
    </Sidebar>
  );
};

export const SidebarView: FC<SidebarViewProps> = () => (
  <SupervisorsSidebar testID="invigilators-sidebar"></SupervisorsSidebar>
);
