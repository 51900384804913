import { createContext, createElement, FC, ReactNode, useContext, useState } from 'react';

type DialogState = (value: ReactNode) => void;

const DialogContext = createContext<DialogState>(() => {});

export const useDialogState = (): DialogState => useContext(DialogContext);

export const DialogState: FC = ({ children }) => {
  const [modal, setModal] = useState<ReactNode>(null);
  return createElement(DialogContext.Provider, { value: setModal }, children, modal);
};
