import { MouseEvent, useMemo } from 'react';
import { Centre, Message } from '../api';
import { RemovalMessageView, ReplacementMessageView } from './views';
import { Option } from '../components/FilterComboBox';
import { Tab } from '../components/DatePicker';
import { hash } from '../sessions/readSessions';
import dayjs from 'dayjs';
import { buildDateQuery, buildQuery } from '../sessions';
import { utcDate } from '../components/Calendar';
import { isMessageItemCE } from './utils';

export const useRenderMessages = (
  messages: Message[],
  onMessageClick: (event: MouseEvent<HTMLElement>) => void,
  centre: Centre | null,
  active: string | null,
  testID?: string,
): JSX.Element[] =>
  useMemo(
    () =>
      messages.map((message) => {
        switch (message.type) {
          case 'REPLACEMENT':
            return (
              <ReplacementMessageView
                active={active === message.id}
                key={message.id}
                item={message}
                onMessageClick={onMessageClick}
                link={useLink(message, centre)}
                testID={testID}
                time={dayjs(message.createdAt * 1000)}
              />
            );

          case 'REMOVAL':
            return (
              <RemovalMessageView
                active={active === message.id}
                key={message.id}
                item={message}
                onMessageClick={onMessageClick}
                testID={testID}
                time={dayjs(message.createdAt * 1000)}
              />
            );
        }
      }),
    [active, centre, messages, onMessageClick, testID],
  );

interface SittingMap {
  [key: string]: string;
}

const useLink = (item: Message, centre: Centre | null) => {
  const filterOptions: Option[] = [];
  const dateRange: Tab | null = {
    from: item.startDate ? utcDate(item.startDate) : utcDate(item.date),
    to: null,
    id: 'date',
  };

  if (isMessageItemCE(item)) {
    const { product, sitting, specialArrangement } = item;
    const sittingLabels: SittingMap = { AM: 'AM', PM: 'PM', EV: 'EVE' };

    if (product) {
      filterOptions.push({
        group: 'product',
        value: hash(product),
        label: product,
      });
    }

    if (sitting) {
      filterOptions.push({
        group: 'sitting',
        value: sitting,
        label: sittingLabels[sitting],
      });
    }

    filterOptions.push({
      group: 'specialArrangement',
      value: specialArrangement ? 'special_arrangement' : 'not_special_arrangement',
      label: specialArrangement ? 'Yes' : 'No',
    });
  } else {
    const { assessmentId, qualificationShortName, componentId } = item;

    if (assessmentId) {
      filterOptions.push({
        group: 'assessmentId',
        value: assessmentId,
        label: assessmentId,
      });
    }

    if (qualificationShortName) {
      filterOptions.push({
        group: 'qualificationShortName',
        value: qualificationShortName,
        label: qualificationShortName,
      });
    }

    if (componentId) {
      filterOptions.push({
        group: 'componentId',
        value: componentId,
        label: componentId,
      });
    }
  }

  const filterQuery = buildQuery(filterOptions);
  const dateQuery = buildDateQuery(dateRange);

  const params = new URLSearchParams();
  filterQuery && params.set('q', filterQuery);
  dateQuery && params.set('d', dateQuery);

  return centre ? `/centre/${centre.id}?${params}` : undefined;
};
