import { FC } from 'react';
import { ErrorPageLayout } from '../components/ErrorPageLayout';
import { SoundWave } from '../components/SoundWave';
import { GoToHomepageButton } from './views';

export const NoAccessPage: FC = () => {
  const upperContentSection = `You don't have access for this centre.`;
  const lowerContentSection = 'If you believe you should have access for this centre please contact customer support.';

  return (
    <ErrorPageLayout
      errorCode="403"
      upperContentSection={upperContentSection}
      lowerContentSection={lowerContentSection}
      testID="page-no-access"
      rightColumnChildren={<SoundWave />}
      leftColumnChildren={<GoToHomepageButton testID="page-no-access" />}
    />
  );
};
