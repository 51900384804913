import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useBootState } from '../boot';
import { MessagesDropdown } from '../components/MessagesDropDown';
import { useDispatchEvent } from '../util/EventDispatcher';
import { childTestID } from '../util/test-id';
import { useRenderMessages } from './hooks';
import { useMessages } from './MessagesState';
import { useCentres } from '../centres';

export const MessagesCentre: FC<{ testID?: string }> = ({ testID }) => {
  const menuId = childTestID(testID, 'messages-list');
  const boot = useBootState();
  const {
    state: { messages, unreadMessages },
    pending,
    dispatch,
  } = useMessages();
  const dispatchEvent = useDispatchEvent();
  const { selectedCentre } = useCentres();

  const showCount = useMemo(() => !!messages.length && !!unreadMessages, [messages, unreadMessages]);
  const [active, setActive] = useState<string | null>(null);

  const onMessageClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const { message } = event.currentTarget.dataset;
      if (message && typeof message === 'string') {
        setActive((active) => (active === message ? null : message));
        dispatch(['READ', message]);
      }
    },
    [dispatch],
  );

  const onOpen = useCallback(() => {
    dispatchEvent('refreshMessages', null);
  }, [dispatchEvent]);

  const messagesList = useRenderMessages(messages, onMessageClick, selectedCentre, active, menuId);

  if (boot.loading) return null;

  return (
    <MessagesDropdown
      count={unreadMessages}
      showCount={showCount}
      list={messagesList}
      onDropdownOpen={onOpen}
      loading={pending}
      testID={testID}
    />
  );
};
