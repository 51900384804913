import { KeyboardEvent, useMemo } from 'react';
import { Action, Dispatch, StateResult } from './types';

type OnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => void;

export const useKeyboardHandler = (state: StateResult, dispatch: Dispatch): OnKeyDown => {
  return useMemo(
    (): OnKeyDown => (event) => {
      const target = event.target as HTMLInputElement;
      const { key } = event;
      const { selectionStart, selectionEnd } = target;
      const { current } = state;
      const { open, selected, activeSelect, activeSuggest } = current;

      const isInputStart = !selectionStart && !selectionEnd;

      const trigger = (action: Action) => {
        event.preventDefault();
        dispatch(action);
      };

      if (key === 'ArrowLeft') {
        if (activeSuggest >= 0) trigger(['PREV_SUGGEST']);
        else if (isInputStart) trigger(['PREV_SELECT']);
      }

      if (key === 'ArrowRight') {
        if (activeSuggest >= 0) trigger(['NEXT_SUGGEST']);
        else if (activeSelect >= 0) trigger(['NEXT_SELECT']);
      }

      if (key === 'ArrowUp') {
        if (state.current.suggested.length > 0) trigger(['PREV_SUGGEST_LINE']);
      }

      if (key === 'ArrowDown') {
        if (state.current.suggested.length > 0) trigger(['NEXT_SUGGEST_LINE']);
      }

      if (key === 'Escape') {
        if (open) trigger(['HIDE_SUGGEST']);
      }

      if (key === 'Enter') {
        if (activeSelect >= 0 || activeSuggest >= 0) trigger(['ACTIVE_SELECT_CHANGE']);
        else if (!open) trigger(['SHOW_SUGGEST']);
      }

      if (key === 'Backspace') {
        if (isInputStart && activeSelect !== 0 && selected.length) trigger(['UNSELECT_PREV']);
      }

      if (key === 'Delete') {
        if (activeSelect >= 0 && activeSelect !== selected.length - 1) trigger(['UNSELECT_NEXT']);
      }
    },
    [dispatch, state],
  );
};
