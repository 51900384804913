import { createContext, createElement, FC, useContext, useMemo } from 'react';
import { match, matchPath, RouteProps, useLocation } from 'react-router-dom';

export enum Route {
  HOME = 'home',
  CENTRE = 'centre',
  REPORTS = 'reports',
  SUPERVISORS = 'supervisors',
  NOT_FOUND = 'notFound',
  NO_ACCESS = 'noAccess',
  INTERNAL_ERROR = 'internalError',
  EULA = 'eula',
  BUSINESS = 'business',
}

export type RouteResult =
  | [Route.HOME, match]
  | [Route.REPORTS, match]
  | [Route.EULA, match]
  | [Route.BUSINESS, match]
  | [Route.SUPERVISORS, match<{ id: string }>]
  | [Route.CENTRE, match<{ id: string }>]
  | [Route.NOT_FOUND, match];

export type Query = Record<string, string | string[]>;

type QueryUpdate = Record<string, string | string[] | null | undefined>;

const routes: [RouteResult[0], RouteProps][] = [
  [Route.HOME, { path: '/', exact: true }],
  [Route.REPORTS, { path: '/reports' }],
  [Route.EULA, { path: '/eula' }],
  [Route.BUSINESS, { path: '/business' }],
  [Route.CENTRE, { path: '/centre/:id', exact: true }],
  [Route.SUPERVISORS, { path: '/centre/:id/supervisors' }],
  [Route.NOT_FOUND, { path: '*' }],
];

const CurrentRoute = createContext<RouteResult>([Route.NOT_FOUND, { params: {}, isExact: false, path: '', url: '' }]);
const CurrentQuery = createContext<Query>({});

export const parseQuery = (input: string): Query => {
  if (input === '' || input === '?') return {};
  const list = input.slice(1).split('&');
  const result: Query = {};
  for (const item of list) {
    const parts = item.split('=');
    const name = decodeURIComponent(parts.shift() || '');
    const value = decodeURIComponent(parts.join('=')).replace(/\+/g, ' ');
    const prev = result[name];
    if (!name) continue;
    if (typeof prev === 'string') {
      result[name] = [prev, value];
    } else if (prev) {
      prev.push(value);
    } else {
      result[name] = value;
    }
  }
  return result;
};

export const stringifyQuery = (input: QueryUpdate): string => {
  const keys = Object.keys(input);
  if (!keys.length) return '';
  const result: string[] = [];
  for (const key of keys) {
    const value = input[key];
    if (value == null) continue;
    if (typeof value === 'string') {
      result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    } else {
      for (const val of value) {
        result.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }
    }
  }
  if (!result.length) return '';
  return `?${result.join('&')}`;
};

const matchRoute = (pathname: string): RouteResult => {
  for (const [route, props] of routes) {
    const match = matchPath(pathname, props);
    if (match) return [route, match] as RouteResult;
  }

  /* istanbul ignore next */
  throw new Error('You should define `*` route to match 404');
};

export const Routes: FC = ({ children }) => {
  const { pathname, search } = useLocation();

  const route = useMemo(() => matchRoute(pathname), [pathname]);
  const query = useMemo(() => parseQuery(search), [search]);

  return createElement(
    CurrentRoute.Provider,
    { value: route },
    createElement(CurrentQuery.Provider, { value: query }, children),
  );
};

export const useRoute = (): RouteResult => useContext(CurrentRoute);
export const useQuery = (): Query => useContext(CurrentQuery);

const defaultQuery = {};

export const useQueryURL = (query: QueryUpdate, force = false): string => {
  const { pathname, search } = useLocation();
  const previous = useMemo(() => (force ? defaultQuery : parseQuery(search)), [force, search]);
  return useMemo(() => pathname + stringifyQuery({ ...previous, ...query }), [pathname, previous, query]);
};
