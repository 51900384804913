import { FC, useCallback } from 'react';
import { Tab } from '../../../components/DatePicker';
import { useSupervisorSessionFilter } from '../SupervisorsSessionsFilterProvider';
import { SupervisorsSessionsDatePickerView } from './SupervisorsSessionsDatePickerView';

export const SupervisorsSessionsDatePicker: FC = () => {
  const {
    datePicker: { onRangeSelect, selectedRange, tabs },
  } = useSupervisorSessionFilter();

  const onChange = useCallback(
    (selectedRange: Tab | null): void => {
      onRangeSelect(selectedRange);
    },
    [onRangeSelect],
  );

  return (
    <SupervisorsSessionsDatePickerView
      id="date-picker-component"
      selected={selectedRange}
      onApply={onChange}
      tabs={tabs}
      testID="date-picker"
      range={{ monthsBefore: 1, monthsAfter: 2 }}
    />
  );
};
