import loadEnv from 'application.env';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ApplicationEnv {
    interface Env {
      API_CE_DOMAIN: string;
      API_CI_DOMAIN: string;
    }
  }
}

export const configureEnvironment = (): Promise<ApplicationEnv.Env> =>
  loadEnv().then(() => {
    return window.env;
  });
