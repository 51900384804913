import { MutableRefObject, useMemo, useRef } from 'react';
import { OptionKey, Option } from './types';

type KeysByType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

export const mapIt = <T, K extends KeysByType<T, string>>(
  list: T[],
  key: K | ((item: T) => string),
): Record<string, T> => {
  const rkey = typeof key === 'function' ? key : (item: any): string => item[key];
  return list.reduce<Record<string, T>>((acc, item) => ((acc[rkey(item)] = item), acc), {});
};

export const useRefWrap = <T>(value: T): MutableRefObject<T> => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

export const useHasChanged = <T>(value: T): boolean => {
  const changed = useRefWrap(false);
  useMemo(() => (changed.current = !value || true), [changed, value]);
  return changed.current;
};

export const optionKey = ({ group, value }: OptionKey): string => `${group}-${value}`;

const intersectOptions = (a: Option[], b: Option[]) => {
  const keys = mapIt(b, optionKey);
  return a.filter((option) => !keys[optionKey(option)]);
};

const isExactSuggestedOption = (input: string, defaultOptions: Option[], option: Option) => {
  return option.value === input && defaultOptions.findIndex((o) => isSameOption(o, option)) !== -1;
};

export const sortOptions = (input: Option[], exactInput: string, defaultOptions?: Option[]): Option[] => {
  const groups = input.map((o) => o.group);
  return input.slice().sort((a, b) => {
    if (defaultOptions) {
      if (isExactSuggestedOption(exactInput, defaultOptions, a)) {
        return -1;
      } else if (isExactSuggestedOption(exactInput, defaultOptions, b)) {
        return 1;
      }
    }
    return groups.indexOf(a.group) - groups.indexOf(b.group);
  });
};

export const readSuggestions = (options: (input: string) => Option[], input: string, selected: Option[]): Option[] => {
  const defaultOptions = intersectOptions(options(''), selected);
  return sortOptions(intersectOptions(options(input), selected), input, defaultOptions);
};

export const isSameOption = (a: OptionKey, b: OptionKey): boolean => a.group === b.group && a.value === b.value;
