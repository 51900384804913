import clsx from 'clsx';
import { FC } from 'react';
import { WithIProps } from '../../util/test-id';
import { CircularProgress } from './CircularProgress';
import { container } from './styles.module.scss';

export const LoadingState: FC<WithIProps<'div'>> = ({ testID, className, children, ...rest }) => (
  <div className={clsx(container, className)} {...rest}>
    <CircularProgress testID={testID} />
    {children}
  </div>
);
