import { FC, useCallback } from 'react';
import { Group, Option } from '../../components/FilterComboBox';
import { useChildSessionFilter, useSessionFilter } from '..';
import { SessionsFilterComboBoxView } from './SessionsFilterComboBoxView';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const filterOptions = (options: Option[], value: string): Option[] => {
  const regex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return options.filter(
    (option) => regex.exec(option.label) || (!value.includes(',') && option.acronym && regex.exec(option.acronym)),
  );
};

interface SessionsFilterComboBoxProps {
  isChild?: boolean;
}

export const SessionsFilterComboBox: FC<SessionsFilterComboBoxProps> = ({ isChild }) => {
  let selected: Option[], setSelected: (options: Option[]) => void, options: Option[], groups: Group[];

  if (isChild) {
    ({
      comboBox: { selected, setSelected, options, groups },
    } = useChildSessionFilter());
  } else {
    ({
      comboBox: { selected, setSelected, options, groups },
    } = useSessionFilter());
  }

  const onSelectionChange = useCallback(
    (options: Option[]): void => {
      setSelected(options);
    },
    [setSelected],
  );

  const readOptions = useCallback((input: string) => (!input ? options : [...filterOptions(options, input)]), [
    options,
  ]);

  return (
    <SessionsFilterComboBoxView
      id="search-component"
      testID="search-component"
      selected={selected}
      onSelectionChange={onSelectionChange}
      options={readOptions}
      groups={groups}
    />
  );
};
