import { BusinessStreamName } from '../api/models';

export enum BusinessStream {
  ENGLISH = 'English',
  INTERNATIONAL = 'International',
  LEGACY_INTERNATIONAL = 'CIE',
}

export const allowedBusinessStreams = [
  BusinessStream.ENGLISH.toLowerCase(),
  BusinessStream.INTERNATIONAL.toLowerCase(),
  BusinessStream.LEGACY_INTERNATIONAL.toLowerCase(),
];

export function isBusinessStreamCE(businessStreamName: BusinessStreamName): boolean {
  return businessStreamName?.toLowerCase() === BusinessStream.ENGLISH.toLowerCase();
}

export function isBusinessStreamCI(businessStreamName: BusinessStreamName): boolean {
  return businessStreamName?.toLowerCase() === BusinessStream.INTERNATIONAL.toLowerCase();
}

export function isBusinessStreamAllowed(businessStreamName: string): boolean {
  return allowedBusinessStreams.includes(businessStreamName.toLowerCase());
}
