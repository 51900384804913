import { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

export interface CircularProgressProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  thickness?: number;
  testID?: string;
}

const SIZE = 44;

export const CircularProgress: FC<CircularProgressProps> = ({
  size = 40,
  thickness = 3.6,
  testID,
  style,
  className,
  ...rest
}) => (
  <div
    className={clsx(styles.root, className)}
    style={{ width: size + 'px', height: size + 'px', ...style }}
    data-qa-id={testID}
    {...rest}
  >
    <svg className={styles.svg} viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
      <circle
        cx={SIZE}
        cy={SIZE}
        r={(SIZE - thickness) / 2}
        fill="none"
        strokeWidth={thickness}
        className={styles.circle}
      ></circle>
    </svg>
    {rest.children}
  </div>
);
