import clsx from 'clsx';
import { ChangeEvent, FC, Fragment, ReactNode, Ref } from 'react';
import { Sitting, Supervisor } from '../../api';
import { DateFormat, DateTime } from '../../components/DateTime';
import { TextMBold, TextMRegular, TextSRegular, TextSBold, TextXSRegular } from '../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import {
  pagination as paginationStyle,
  table,
  tableCell,
  tableHeaderCell,
  checkboxTableHeaderCell,
  checkboxTableCell,
  shrinkedTable,
  supervisorsCell,
  tooltip,
  tooltipWrapper,
  tooltipContainer,
  supervisorsFilter,
  visuallyhidden,
  selectedRow,
  userName,
  highlightedTooltip,
} from './styles.module.scss';
import { SupervisorsSessionsFilterComboBox } from './SupervisorsSessionsFilterComboBox';
import { SupervisorsSessionsDatePicker } from './SupervisorsSessionsDatePicker';
import { SupervisorsMainCheckbox } from './SupervisorsContainers';
import { SupervisorsCheckBox } from '../../components/SupervisorsCheckBox';
import { useSupervisorSessionFilter } from './SupervisorsSessionsFilterProvider';
import { sortSupervisorsInCell } from './SupervisorsProvider';
import { SortIcon } from '../../components/SortIcon';
import { useSessionSort, OrderKeyType } from '../../sessions';
import { Dayjs } from 'dayjs';

export const SupervisorsSessionsTableHeader: FC<WithIProps<'thead'>> = ({ testID, ...rest }) => {
  const {
    sort: { onClickSort, order },
  } = useSessionSort();

  const product = order.find((o) => o.key === 'product');
  const KAD = order.find((o) => o.key === 'KAD');
  const sitting = order.find((o) => o.key === 'sitting');
  const supervisors = order.find((o) => o.key === 'supervisors');

  return (
    <thead {...dataTestID(testID)} {...rest}>
      <tr>
        <th className={checkboxTableHeaderCell} scope="col">
          <SupervisorsMainCheckbox />
        </th>
        <th className={clsx(tableHeaderCell, TextSBold)} scope="col" {...dataTestID(testID, 'product')}>
          <SortIcon
            order={(product?.order as OrderKeyType) || 'default'}
            isActive={product?.order !== 'default'}
            onClick={() => {
              onClickSort('product');
            }}
            columnLabel={'Product'}
            testID={childTestID(testID, 'product')}
          />
        </th>
        <th className={clsx(tableHeaderCell, TextSBold)} scope="col" {...dataTestID(testID, 'key-assessment-date')}>
          <SortIcon
            order={(KAD?.order as OrderKeyType) || 'default'}
            isActive={KAD?.order !== 'default'}
            onClick={() => {
              onClickSort('KAD');
            }}
            columnLabel={'Exam date'}
            testID={childTestID(testID, 'key-assessment-date')}
          />
        </th>
        <th className={clsx(tableHeaderCell, TextSBold)} scope="col" {...dataTestID(testID, 'sitting')}>
          <SortIcon
            order={(sitting?.order as OrderKeyType) || 'default'}
            isActive={sitting?.order !== 'default'}
            onClick={() => {
              onClickSort('sitting');
            }}
            columnLabel={'Sitting'}
            testID={childTestID(testID, 'sitting')}
          />
        </th>
        <th className={clsx(tableHeaderCell, TextSBold)} scope="col" {...dataTestID(testID, 'supervisors')}>
          <SortIcon
            order={(supervisors?.order as OrderKeyType) || 'default'}
            isActive={supervisors?.order !== 'default'}
            onClick={() => {
              onClickSort('supervisors');
            }}
            columnLabel={'Supervisors'}
            testID={childTestID(testID, 'supervisors')}
          />
        </th>
      </tr>
    </thead>
  );
};

export interface SupervisorsSessionsTableViewProps extends WithIProps<'table'> {
  rows: ReactNode;
  pagination?: ReactNode;
  sidebarOpen?: boolean;
}

export const SupervisorsSessionsTableView: FC<SupervisorsSessionsTableViewProps> = ({
  testID,
  rows,
  className,
  children,
  pagination,
  sidebarOpen,
  ...rest
}) => (
  <table className={clsx(table, { [shrinkedTable]: sidebarOpen }, className)} {...dataTestID(testID)} {...rest}>
    <SupervisorsSessionsTableHeader testID={childTestID(testID, 'header')} />
    <tbody {...dataTestID(testID, 'body')}>{rows}</tbody>
    {pagination && (
      <tfoot>
        <tr>
          <td colSpan={5} className={paginationStyle}>
            {pagination}
          </td>
        </tr>
      </tfoot>
    )}
    {children}
  </table>
);

const sittings = {
  AM: 'Morning',
  PM: 'Afternoon',
  EV: 'Evening',
};
interface SessionsRowSupervisorsProps extends WithIProps<'tr'> {
  product: string;
  date: Dayjs;
  sitting: Sitting;
  specialArrangements: boolean;
  supervisors: Supervisor[];
  checkbox: ReactNode;
  selected: boolean;
}

export const SessionRowSupervisors: FC<SessionsRowSupervisorsProps> = ({
  testID,
  product,
  date,
  sitting,
  specialArrangements,
  supervisors,
  checkbox,
  selected,
  ...rest
}) => {
  return (
    <tr className={clsx({ [selectedRow]: selected })} {...dataTestID(testID)} {...rest}>
      <td className={checkboxTableCell} {...dataTestID(testID, 'check')}>
        {checkbox}
      </td>
      <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'product')}>
        {product} {specialArrangements && <span className={TextMBold}>Special Arr.</span>}
      </td>
      <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'key-assessment-date')}>
        <DateTime format={DateFormat.DATE} value={date} />
      </td>
      <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'sitting')}>
        {sittings[sitting]}
      </td>
      <SupervisorsTableCell supervisors={supervisors} testID={testID} />
    </tr>
  );
};

interface SupervisorsTableCheckboxProps extends Omit<WithIProps<'label'>, 'onChange'> {
  inputId: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  forwardRef?: Ref<HTMLInputElement>;
  label: string;
}

export const SupervisorsTableCheckbox: FC<SupervisorsTableCheckboxProps> = ({
  inputId,
  onChange,
  forwardRef,
  label,
  ...rest
}) => {
  return (
    <Fragment>
      <SupervisorsTableCheckboxLabel label={label} inputId={inputId} {...rest} />
      <SupervisorsCheckBox onChange={onChange} forwardRef={forwardRef} inputProps={{ id: inputId, testID: inputId }} />
    </Fragment>
  );
};

interface SupervisorsTableCheckboxLabelProps extends WithIProps<'label'> {
  label: string;
  inputId: string;
}

export const SupervisorsTableCheckboxLabel: FC<SupervisorsTableCheckboxLabelProps> = ({
  inputId,
  label,
  className,
  ...rest
}) => {
  return (
    <label className={clsx(visuallyhidden, className)} htmlFor={inputId} {...dataTestID(`${inputId}-label`)} {...rest}>
      {label}
    </label>
  );
};

interface SupervisorsTableCellProps extends WithIProps<'td'> {
  supervisors: Supervisor[];
}

export const SupervisorsTableCell: FC<SupervisorsTableCellProps> = ({ supervisors, testID }) => {
  const {
    comboBox: { selected },
  } = useSupervisorSessionFilter();

  supervisors = sortSupervisorsInCell(selected, supervisors);
  const selectedSupervisors = (selected || []).map((item) => item.value);

  return (
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'supervisors')}>
      <div className={clsx(supervisorsCell)}>
        {supervisors.map((u, i, arr) => {
          const highlight = selectedSupervisors.includes(u.fullName);
          return (
            <Fragment key={u.id}>
              <Tooltip text={u.fullName} tooltipText={u.email} highlight={highlight} testID={testID} />
              {i !== arr.length - 1 ? ', ' : ''}
            </Fragment>
          );
        })}
      </div>
    </td>
  );
};

interface TooltipProps extends WithIProps<'div'> {
  text: string;
  tooltipText: string;
  highlight?: boolean;
}
export const Tooltip: FC<TooltipProps> = ({ text, tooltipText, highlight, testID, className, ...rest }) => (
  <div className={clsx(tooltipContainer, className)} {...dataTestID(testID, 'supervisor-name')} {...rest}>
    <span className={clsx(tooltipWrapper, TextSRegular)} {...dataTestID(testID, 'supervisor-email')}>
      <span className={tooltip}>{tooltipText}</span>
    </span>
    <span className={clsx({ [highlightedTooltip]: highlight }, userName)}>{text}</span>
  </div>
);

export const SupervisorsSessionsFilter: FC<WithIProps<'div'>> = ({ className, ...rest }) => (
  <div className={clsx(supervisorsFilter, className)} {...rest}>
    <SupervisorsSessionsFilterComboBox />
    <SupervisorsSessionsDatePicker />
  </div>
);

interface SupervisorsHeaderLinkProps extends WithIProps<'span'> {
  link: string;
}

export const SupervisorsHeaderLink: FC<SupervisorsHeaderLinkProps> = ({ link, className, ...rest }) => (
  <span className={clsx(TextXSRegular, className)} {...rest}>
    If you cannot see the user you wish to assign please click&nbsp;
    <a href={link} target="_blank" rel="noopener noreferrer">
      here
    </a>
    &nbsp;to manage users
  </span>
);
