import { FC, ReactNode } from 'react';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import clsx from 'clsx';
import {
  messageStyle,
  messageIcon,
  messageTime,
  messageTitle,
  activeMessage,
  readMessage,
  activeContent,
  messageContent,
  messageLink,
} from './styles.module.scss';
import { TextSBold, TextSRegular, TextXSRegular } from '../../theme/typography.module.scss';
import { AnchorButton } from '../Button';
import { AssistHint } from '../AssistHint';
import dayjs, { Dayjs } from 'dayjs';

export interface MessageTitleProps extends WithIProps<'span'> {
  date: Dayjs;
  icon: ReactNode;
  active: boolean;
  read: boolean;
}

type TimeFormat = 'sec' | 'min' | 'h' | 'd';
const timeFormatMap: Record<TimeFormat, string> = {
  sec: 'seconds',
  min: 'minutes',
  h: 'hours',
  d: 'days',
};

interface TimeProps {
  number: number;
  timeFormat: TimeFormat;
}

const calcTime = (date: Dayjs): TimeProps => {
  const time = date.unix();
  const now = dayjs().unix();
  const diff = now >= time ? now - time : 0;

  if (diff < 60) return { number: Math.floor(diff), timeFormat: 'sec' };
  if (diff < 60 * 60) return { number: Math.floor(diff / 60), timeFormat: 'min' };
  if (diff < 60 * 60 * 24) return { number: Math.floor(diff / 60 / 60), timeFormat: 'h' };

  return { number: Math.floor(diff / 60 / 60 / 24), timeFormat: 'd' };
};

export const MessageTitle: FC<MessageTitleProps> = ({
  active,
  read,
  icon,
  id,
  date,
  className,
  children,
  testID,
  ...rest
}) => {
  const time = calcTime(date);
  const assistId1 = `${testID}-title-assist-1`;
  const assistId2 = `${testID}-title-assist-2`;
  const assistId3 = `${testID}-title-assist-3`;

  return (
    <span
      aria-controls={`${id}-content`}
      aria-expanded={active}
      aria-labelledby={`${assistId1} ${assistId2} ${assistId3}`}
      tabIndex={0}
      role="button"
      data-item="messageitem"
      data-message={id}
      className={clsx(messageTitle, TextSBold, className)}
      {...dataTestID(testID, 'title')}
      {...rest}
    >
      <MessageIcon testID={childTestID(testID, `message-${id}`)} aria-hidden>
        {icon}
      </MessageIcon>
      <MessageTime time={`${time.number}${time.timeFormat}`} testID={testID} />
      {children}
      <AssistHint id={assistId1} hide>
        message, {!read && ' unread,'}
      </AssistHint>
      <AssistHint id={assistId2} hide>
        created {time.number} {timeFormatMap[time.timeFormat]} ago,
      </AssistHint>
      <AssistHint id={assistId3} hide>
        {children}
      </AssistHint>
    </span>
  );
};

export interface MessageContentProps extends WithIProps<'span'> {
  active: boolean;
}

export const MessageContent: FC<MessageContentProps> = ({ id, active, className, children, testID, ...rest }) => (
  <span
    id={`${id}-content`}
    className={clsx(TextSRegular, messageContent, { [activeContent]: active }, className)}
    {...dataTestID(testID, 'content')}
    {...rest}
  >
    {children}
  </span>
);

export const MessageIcon: FC<WithIProps<'span'>> = ({ className, children, testID, ...rest }) => (
  <span className={clsx(messageIcon, className)} {...dataTestID(testID, 'icon')} {...rest}>
    {children}
  </span>
);

export interface MessageTimeProps extends WithIProps<'span'> {
  time: string;
}

export const MessageTime: FC<MessageTimeProps> = ({ time, className, children, testID, ...rest }) => (
  <span className={clsx(messageTime, TextXSRegular, className)} {...dataTestID(testID, 'time')} {...rest}>
    {time}
    {children}
  </span>
);

interface MessageLinkProps extends WithIProps<'div'> {
  link: string;
  product?: string;
  specialArr?: boolean;
  assessmentId?: string;
  qualificationShortName?: string;
  componentId?: string;
  date: Date;
  sitting?: 'AM' | 'PM' | 'EV';
}

export const MessageLink: FC<MessageLinkProps> = ({
  link,
  product,
  specialArr,
  date,
  sitting,
  assessmentId,
  qualificationShortName,
  componentId,
  className,
  children,
  testID,
  ...rest
}) => (
  <div className={clsx(messageLink, className)} {...dataTestID(testID, 'message-link')} {...rest}>
    <AnchorButton
      data-product={product}
      data-specialarr={specialArr}
      data-date={date}
      data-sitting={sitting}
      data-assessmentid={assessmentId}
      data-qualificationshortname={qualificationShortName}
      data-componentid={componentId}
      href={link}
      size="small"
      variant="outlined"
    >
      {children}
    </AnchorButton>
  </div>
);

export interface MessageProps extends WithIProps<'div'> {
  id: string;
  active: boolean;
  read: boolean;
}

export const MessageContainer: FC<MessageProps> = ({ id, className, active, read, testID, children, ...rest }) => (
  <div
    key={id}
    className={clsx(messageStyle, { [activeMessage]: active, [readMessage]: read }, className)}
    {...dataTestID(testID, `message-${id}`)}
    {...rest}
  >
    {children}
  </div>
);
