import clsx from 'clsx';
import { FC } from 'react';
import { SupervisorsRequest } from '../../../../api';
import { Button } from '../../../../components/Button';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '../../../../components/Dialog';
import { HourGlass } from '../../../../components/Icon';
import { SubheadingBold, TextMBold, TextMRegular } from '../../../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../../../util/test-id';
import {
  cancelButton,
  dialog,
  dialogAction,
  dialogContent,
  form,
  text,
  updateProgressText,
  userName,
} from './SupervisorsSaveAction.module.scss';

export const SaveChangesDialog: FC<DialogProps> = ({ className, children, ...rest }) => (
  <Dialog className={clsx(dialog, className)} {...rest}>
    <DialogTitle onClose={rest.onCancel} testID={childTestID(rest.testID, 'title')}>
      Change supervisors assignment
    </DialogTitle>
    <DialogContent className={dialogContent}>{children}</DialogContent>
  </Dialog>
);

export interface UpdateSupervisorsFormProps {
  assignUsers: SupervisorsRequest[];
  removeUsers: SupervisorsRequest[];
  onCancel: () => void;
}

export const UpdateSupervisorsInProgressMessage: FC<WithIProps<'span'>> = ({ testID, className, ...rest }) => (
  <span className={clsx(SubheadingBold, updateProgressText, className)} {...dataTestID(testID)} {...rest}>
    <HourGlass /> Updating...
  </span>
);

export interface UpdateSupervisorsFormViewProps extends WithIProps<'form'> {
  assignUsers: SupervisorsRequest[];
  removeUsers: SupervisorsRequest[];
  onClose: () => void;
}

export const UpdateSupervisorsFormView: FC<UpdateSupervisorsFormViewProps> = ({
  onClose,
  onSubmit,
  assignUsers,
  removeUsers,
  testID,
  className,
  children,
  ...rest
}) => (
  <form onSubmit={onSubmit} className={clsx(form, className)} {...dataTestID(testID)} {...rest}>
    <span className={clsx(TextMRegular, text)}>
      {assignUsers.map((u) => (
        <AssignMessage
          key={u.user.email}
          name={u.user.fullName}
          sessions={u.sessions.length}
          testID={childTestID(testID, `assign-${u.user.email}`)}
        />
      ))}
      {removeUsers.map((u) => (
        <RemoveMessage
          key={u.user.email}
          name={u.user.fullName}
          sessions={u.sessions.length}
          testID={childTestID(testID, `remove-${u.user.email}`)}
        />
      ))}
      Are you sure?
    </span>
    <div className={dialogAction}>
      <Button
        type="button"
        variant="text"
        className={cancelButton}
        onClick={onClose}
        testID={childTestID(testID, 'cancel')}
      >
        Cancel
      </Button>
      <Button type="submit" testID={childTestID(testID, 'submit')}>
        Yes
      </Button>
    </div>
    {children}
  </form>
);

export interface UpdateMessageProps extends WithIProps<'span'> {
  sessions: number;
  name: string;
}

export const AssignMessage: FC<UpdateMessageProps> = ({ name, sessions, testID, children, ...rest }) => (
  <span {...dataTestID(testID)} {...rest}>
    You are about to assign <span className={clsx(TextMBold, userName)}>{name}</span> to{' '}
    <span className={TextMBold}>{sessions}</span> exam sessions.
    {children}
    <br />
  </span>
);

export const RemoveMessage: FC<UpdateMessageProps> = ({ name, sessions, testID, children, ...rest }) => (
  <span {...dataTestID(testID)} {...rest}>
    You are about to remove <span className={clsx(TextMBold, userName)}>{name}</span> from{' '}
    <span className={TextMBold}>{sessions}</span> exam sessions.
    {children}
    <br />
  </span>
);
