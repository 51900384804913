export type IntrinsicProps<T extends keyof JSX.IntrinsicElements> = Omit<JSX.IntrinsicElements[T], 'key' | 'ref'>;

export type WithIProps<T extends keyof JSX.IntrinsicElements> = IntrinsicProps<T> & TestIDProps;

export interface TestIDProps {
  testID?: string;
}

interface ChildTestID {
  (id: string, suffix?: string): string;
  (id: undefined, suffix?: string): undefined;
  (id?: string, suffix?: string): string | undefined;
}

export const childTestID: ChildTestID = (id?: any, suffix?: any): any => {
  if (!id) return;
  return suffix ? `${id}-${suffix}` : id;
};

export const dataTestID = (id?: string, suffix?: string): { 'data-qa-id'?: string } => {
  const res = childTestID(id, suffix);
  return res ? { 'data-qa-id': res.replace(/ /g, '-') } : {};
};
