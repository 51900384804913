import { FC, useCallback } from 'react';
import { Tab } from '../../components/DatePicker';
import { useSessionFilter } from '..';
import { SessionsDatePickerView } from './SessionsDatePickerView';

export const SessionsDatePicker: FC = () => {
  const {
    datePicker: { onRangeSelect, selectedRange, tabs },
  } = useSessionFilter();

  const onChange = useCallback(
    (selectedRange: Tab | null): void => {
      onRangeSelect(selectedRange);
    },
    [onRangeSelect],
  );

  return (
    <SessionsDatePickerView
      id="date-picker-component"
      selected={selectedRange}
      onApply={onChange}
      tabs={tabs}
      testID="date-picker"
      range={{ monthsBefore: 1, monthsAfter: 2 }}
    />
  );
};
