import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const utcDate = (input?: Dayjs | string | number): Dayjs => {
  const date = input ? dayjs.utc(input) : dayjs.utc();
  const result = date.set('hours', 12);
  return result;
};

export const getUtcMonthStart = (date: Dayjs): Dayjs => {
  const utc = utcDate(date);
  const result = utc.set('date', 1);
  return result;
};

export const getUtcMonthEnd = (date: Dayjs): Dayjs => {
  const start = getUtcMonthStart(date);
  const nextMonth = start.add(1, 'month');
  const end = nextMonth.subtract(1, 'day');
  return end;
};

export const addUtcMonth = (date: Dayjs, amount: number): Dayjs => {
  const utc = utcDate(date);
  const result = utc.add(amount, 'month');
  return result;
};

export const substractUtcMonth = (date: Dayjs, amount: number): Dayjs => {
  const utc = utcDate(date);
  const result = utc.subtract(amount, 'month');
  return result;
};

export const getUtcDay = (date: Dayjs): number => (utcDate(date).get('day') + 6) % 7;

export const getUtcWeekStart = (date: Dayjs): Dayjs => {
  const utc = utcDate(date);
  const result = utc.set('date', utc.get('date') - getUtcDay(utc));
  return result;
};

export const getUtcWeekEnd = (date: Dayjs): Dayjs => {
  const utc = utcDate(date);
  const result = utc.set('date', utc.get('date') + (6 - getUtcDay(utc)));
  return result;
};

export const toDateString = (date: Dayjs): string => date.format('YYYY-MM-DD');

export const coords2date = (view: Dayjs, [row, column]: [number, number]): Dayjs => {
  const mstart = getUtcMonthStart(view);
  const mend = getUtcMonthEnd(view);
  const dcount = getUtcDay(mstart) + mend.get('date');
  const minRow = column < getUtcDay(mstart) ? 1 : 0;
  const maxRow = (column > getUtcDay(mend) ? Math.floor(dcount / 7) : Math.ceil(dcount / 7)) - 1;
  const nrow = Math.min(Math.max(row, minRow), maxRow);

  const start = getUtcWeekStart(mstart);
  return start.set('date', start.get('date') + nrow * 7 + column);
};

export const date2coords = (value: Dayjs): [number, number] => {
  const mstart = getUtcMonthStart(value);
  const row = Math.floor((utcDate(value).get('date') + getUtcDay(mstart) - 1) / 7);
  return [row, getUtcDay(value)];
};
