import clsx from 'clsx';
import { FC, Fragment, KeyboardEvent, useMemo } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { Close as CloseIcon } from '../../components/Icon';
import { HeadingSBold } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';
import { AssistHint } from '../AssistHint';
import { IconButton } from '../Button';
import { closeButton, container, content, overlay, title } from './Dialog.module.scss';

// FIXME: Create dialog counter and add suffix to the id attributes corresponding to the dialog shown

export interface DialogProps extends WithIProps<'div'> {
  open?: boolean;
  onCancel?: () => void;
}

export const Dialog: FC<DialogProps> = ({ open, onCancel, children, className, testID, ...props }) => {
  const onKeyDown = useMemo(
    () =>
      onCancel &&
      (({ key, target, currentTarget }: KeyboardEvent<HTMLDivElement>): void => {
        if (
          key === 'Escape' &&
          (target === currentTarget || (target as HTMLElement).getAttribute('aria-label') === 'close')
        )
          onCancel();
      }),
    [onCancel],
  );

  const lockProps = useMemo(() => ({ onKeyDown, tabIndex: -1, ...dataTestID(testID, 'overlay') }), [onKeyDown, testID]);

  if (!open) return null;

  return (
    <FocusLock autoFocus returnFocus className={overlay} lockProps={lockProps}>
      <RemoveScroll
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-content"
        removeScrollBar={false}
        className={clsx(container, className)}
        {...dataTestID(testID)}
        {...props}
      >
        {children}
      </RemoveScroll>
    </FocusLock>
  );
};

export interface DialogTitleProps extends WithIProps<'h1'> {
  onClose?: () => void;
}

export const DialogTitle: FC<DialogTitleProps> = ({ children, className, onClose, testID, ...rest }) => (
  <Fragment>
    {onClose && (
      <IconButton
        aria-label="close"
        className={closeButton}
        variant="text"
        onClick={onClose}
        {...dataTestID(testID, 'close')}
      >
        <CloseIcon />
        <AssistHint>close</AssistHint>
      </IconButton>
    )}
    <h1 id="dialog-title" className={clsx(title, HeadingSBold, className)} {...dataTestID(testID)} {...rest}>
      {children}
    </h1>
  </Fragment>
);

export const DialogContent: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => (
  <div id="dialog-content" className={clsx(content, className)} {...dataTestID(testID)} {...rest} />
);
