import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import {
  messageContainer,
  columnLeft,
  columnRight,
  title,
  upperContentText,
  lowerContentText,
  errorNumber,
  rowRight,
  soundWaveWrapper,
  mainWrapper,
} from './ErrorPageLayout.module.scss';

interface ErrorPageProps extends WithIProps<'div'> {
  errorCode: string;
  upperContentSection?: string;
  lowerContentSection?: string;
  leftColumnChildren?: ReactNode;
  rightColumnChildren?: ReactNode;
}

export const ErrorPageLayout: FC<ErrorPageProps> = ({
  errorCode,
  upperContentSection,
  lowerContentSection,
  leftColumnChildren,
  rightColumnChildren,
  testID,
  className,
  ...rest
}) => {
  const sorryTitle = `We're sorry.`;
  return (
    <div
      aria-live="assertive"
      aria-label={`error-${errorCode}-${testID}`}
      className={clsx(className, mainWrapper)}
      {...dataTestID(testID)}
      {...rest}
    >
      <div className={columnLeft}>
        <div className={messageContainer} {...dataTestID(testID, 'message')}>
          <b className={title}>{sorryTitle}</b>
          <span className={upperContentText}>{upperContentSection}</span>
          <span className={lowerContentText}>{lowerContentSection}</span>
          {leftColumnChildren}
        </div>
      </div>
      <div className={columnRight}>
        <div className={rowRight}>
          <span className={errorNumber} {...dataTestID(testID, 'error-code')}>
            {errorCode}
          </span>
        </div>
        <div className={rowRight}>
          <div className={soundWaveWrapper}>{rightColumnChildren}</div>
        </div>
      </div>
    </div>
  );
};
