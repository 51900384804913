import {
  createContext,
  createElement,
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

export interface Events {
  uploadComplete: number;
  refreshList: null;
  refreshUserInfo: null;
  refreshMessages: null;
}

const DisptacherContext = createContext<DisptacherContextValue>({
  store: { current: {} },
  dispatch: () => {
    throw new Error('Missing EventDispatcher provider');
  },
});

export const useEventListener = <T extends keyof Events>(type: T, listener: undefined | Listener<Events[T]>): void => {
  const { store } = useContext(DisptacherContext);

  useEffect(() => {
    if (type && listener) {
      const listeners = store.current;
      const sub: Listener<Events[T]> = (event) => listener(event);
      if (!listeners[type]) listeners[type] = [];
      const list = listeners[type] as Listener<Events[T]>[];
      list.push(sub);

      return (): void => {
        if (list.length === 1) {
          delete listeners[type];
        } else {
          list.splice(list.indexOf(sub), 1);
        }
      };
    }
  }, [listener, store, type]);
};

export const useDispatchEvent = (): DispatchEvent => useContext(DisptacherContext).dispatch;

export const EventDispatcher: FC = ({ children }) => {
  const store = useRef<EventStore>({});

  const dispatch: DispatchEvent = useCallback((type, event) => {
    const listeners = store.current[type] as Listener<Events[keyof Events]>[];
    if (listeners) listeners.forEach((listener) => listener(event));
  }, []);

  return createElement(
    DisptacherContext.Provider,
    { value: useMemo(() => ({ store, dispatch }), [dispatch]) },
    children,
  );
};

type Listener<T> = (event: T) => void;
type EventStore = { [K in keyof Events]?: Listener<Events[K]>[] };
type DispatchEvent = <T extends keyof Events>(type: T, event: Events[T]) => void;

interface DisptacherContextValue {
  store: MutableRefObject<EventStore>;
  dispatch: DispatchEvent;
}
