import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SupervisorsRequest } from '../../../../api';
import { useCentres } from '../../../../centres';
import { Button, ButtonProps } from '../../../../components/Button';
import { useNotification } from '../../../../notifications';
import { useDispatchEvent } from '../../../../util/EventDispatcher';
import { useDialogState } from '../../../DialogState';
import { useSidebar } from '../../SidebarState';
import { useSupervisorsAssignRequest, useSupervisorsRemoveRequest } from '../../SupervisorsProvider';
import { useSupervisorsSessions } from '../../SupervisorsSessionsProvider';
import {
  SaveChangesDialog,
  UpdateSupervisorsFormView,
  UpdateSupervisorsInProgressMessage,
} from './SupervisorsSaveActionViews';

export const SupervisorsSaveAction: FC<ButtonProps> = ({ ...rest }) => {
  const renderDialog = useDialogState();
  const {
    state: { selectedSessions, checkedUsers, commonUsers },
  } = useSidebar();

  const { data: sessions } = useSupervisorsSessions();

  const assignUsers: SupervisorsRequest[] = useMemo(
    () =>
      checkedUsers
        .filter((checked) => !commonUsers.find((common) => common.email === checked.email))
        .map((u) => {
          const filteredSessions =
            sessions &&
            selectedSessions.filter(
              (selected) => !sessions.items[selected].venueUsers.some((vu) => vu.email === u.email),
            );

          return { user: { fullName: u.fullName, email: u.email, userId: u.id }, sessions: filteredSessions || [] };
        })
        .filter((u) => !!u.sessions.length),
    [checkedUsers, commonUsers, selectedSessions, sessions],
  );

  const removeUsers: SupervisorsRequest[] = useMemo(
    () =>
      commonUsers
        .filter((common) => !checkedUsers.find((checked) => common.email === checked.email))
        .map((u) => {
          const filteredSessions =
            sessions &&
            selectedSessions.filter((selected) =>
              sessions.items[selected].venueUsers.some((vu) => vu.email === u.email),
            );

          return { user: { fullName: u.fullName, email: u.email, userId: u.id }, sessions: filteredSessions || [] };
        })
        .filter((u) => !!u.sessions.length),
    [checkedUsers, commonUsers, selectedSessions, sessions],
  );

  const onCancel = useCallback(() => {
    renderDialog(null);
  }, [renderDialog]);

  const onClick = useCallback(() => {
    renderDialog(
      <SaveChangesDialog testID="save-changes-dialog" open onCancel={onCancel}>
        <UpdateSupervisorsForm assignUsers={assignUsers} removeUsers={removeUsers} onCancel={onCancel} />
      </SaveChangesDialog>,
    );
  }, [assignUsers, onCancel, removeUsers, renderDialog]);

  return (
    <Button size="small" onClick={onClick} {...rest}>
      Save changes
    </Button>
  );
};

export interface UpdateSupervisorsFormProps {
  assignUsers: SupervisorsRequest[];
  removeUsers: SupervisorsRequest[];
  onCancel: () => void;
}

export const UpdateSupervisorsForm: FC<UpdateSupervisorsFormProps> = ({ assignUsers, removeUsers, onCancel }) => {
  const { dispatch } = useSidebar();

  const [submit, setSubmit] = useState(false);
  const { selectedCentre } = useCentres();

  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchNotification, messages } = useNotification();

  const assignRequest = useSupervisorsAssignRequest(submit && selectedCentre?.id, assignUsers);
  const removeRequest = useSupervisorsRemoveRequest(submit && selectedCentre?.id, removeUsers);

  const assignPending = useMemo(() => assignRequest?.pending || null, [assignRequest]);
  const removePending = useMemo(() => removeRequest?.pending || null, [removeRequest]);

  const aerror = assignRequest && assignRequest.error;
  const rerror = removeRequest && removeRequest.error;

  useEffect(() => {
    if (aerror || rerror) {
      const message =
        aerror && rerror
          ? messages.FAILED_SAVE_CHANGES
          : aerror
          ? messages.FAILED_TO_ASSIGN_SUPERVISORS
          : messages.FAILED_TO_REMOVE_SUPERVISORS;
      dispatchNotification([
        'ADD',
        {
          title: `Failed to save changes`,
          body: message,
        },
        'ERROR',
      ]);
    }
  }, [aerror, dispatchNotification, messages, rerror]);

  const renderDialog = useDialogState();

  useEffect(() => {
    if (submit && !assignPending && !removePending) {
      dispatch(['UNSELECT_ALL_SESSIONS']);
      dispatchEvent('refreshList', null);
      renderDialog(null);
    }
  }, [assignPending, dispatch, dispatchEvent, removePending, renderDialog, submit]);

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmit(true);
  }, []);

  return submit ? (
    <UpdateSupervisorsInProgressMessage testID="save-changes-progress-message" />
  ) : (
    <UpdateSupervisorsFormView
      testID="save-changes-form"
      onSubmit={onSubmit}
      onClose={onCancel}
      assignUsers={assignUsers}
      removeUsers={removeUsers}
    />
  );
};
