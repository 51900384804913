import { FC, useCallback, useMemo } from 'react';
import { utcDate, addUtcMonth, substractUtcMonth } from './date-util';
import clsx from 'clsx';
import { dataTestID, childTestID } from '../../util/test-id';
import { RightArrow, LeftArrow } from '../Icon';
import { CalendarRangeProps } from './types';
import { IconButton } from '../Button';
import { TextMBold, SubheadingRegular } from '../../theme/typography.module.scss';
import { selectContainer, start, end, selectArrowRedesign, selectMonth, selectYear } from './styles.module.scss';

import { MonthYearDropdown } from './MonthYearDropdown';
import { Selection } from './views';

const MONTHS_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const SelectionMonthYear: FC<CalendarRangeProps> = ({
  id,
  view,
  onNextMonth,
  onPreviousMonth,
  onMonthChange,
  onYearsChange,
  pastMonthsCount,
  futureMonthsCount,
  testID,
  initialView,
  yearFrom,
  noFutureDates,
}) => {
  const startDate = utcDate(initialView);
  const pastEndDate = substractUtcMonth(initialView, pastMonthsCount);
  const futureEndDate = addUtcMonth(initialView, futureMonthsCount);
  const selectedYear = view.get('year');
  const selectedMonth = MONTHS_LIST[view.get('month')];
  const availableDates: number[] = useMemo(() => [], []);

  let loopBefore = utcDate(startDate);
  while (loopBefore >= pastEndDate) {
    if (yearFrom) {
      if (loopBefore.get('year') >= yearFrom.get('year')) {
        availableDates.push(loopBefore.valueOf());
      }
    } else {
      availableDates.push(loopBefore.valueOf());
    }
    const newDate = substractUtcMonth(loopBefore, 1);
    loopBefore = newDate;
  }

  let loopAfter = utcDate(startDate);
  while (loopAfter <= futureEndDate) {
    availableDates.push(loopAfter.valueOf());
    const newDate = addUtcMonth(loopAfter, 1);
    loopAfter = newDate;
  }

  const yearsList = [...new Set(availableDates.map((date: number) => utcDate(date).get('year')))].sort();

  const renderMonthOptions = () =>
    MONTHS_LIST.map((month, index) => {
      const utc = utcDate(startDate);
      const monthDate = utc.set('year', selectedYear).set('month', index);

      const disabled = !availableDates.includes(monthDate.valueOf());
      return (
        <Selection
          key={month}
          id={childTestID(id, month)}
          testID={testID}
          label={month}
          selected={!!selectedMonth && month === selectedMonth}
          disabled={disabled}
          onClick={() => {
            !disabled && onSelectMonth(month);
          }}
        />
      );
    });

  const renderYearsOptions = yearsList.map((year: number) => (
    <Selection
      key={year}
      id={childTestID(id, String(year))}
      testID={testID}
      label={String(year)}
      selected={!!selectedYear && year === selectedYear}
      onClick={() => {
        onSelectYear(year);
      }}
    />
  ));

  const onSelectMonth = useCallback(
    (option: string) => {
      const value = MONTHS_LIST.findIndex((i) => i === option);
      onMonthChange(value, selectedYear);
    },
    [onMonthChange, selectedYear],
  );

  const onSelectYear = useCallback(
    (option: number) => {
      const utc = utcDate(view);
      const newDate = utc.set('year', option);

      if (yearFrom) {
        onYearsChange(addUtcMonth(newDate, 1));
      } else {
        onYearsChange(newDate);
      }

      if (selectedYear < option && !availableDates.includes(newDate.valueOf())) {
        onMonthChange(0, option);
      } else if (selectedYear > option && !availableDates.includes(newDate.valueOf())) {
        onMonthChange(MONTHS_LIST.length - 1, option);
      }
    },
    [onYearsChange, onMonthChange, selectedYear, availableDates, view, yearFrom],
  );

  let viewCopy: Date | number = view.valueOf();
  viewCopy = new Date(viewCopy);
  viewCopy = viewCopy.setUTCDate(viewCopy.getUTCDate() - 1);

  return (
    <div className={clsx(selectContainer, onPreviousMonth && start, onNextMonth && end)}>
      {onPreviousMonth && (
        <div>
          <IconButton
            aria-label="previous-month"
            size="small"
            variant="text"
            onClick={onPreviousMonth}
            {...dataTestID(testID, 'previous-month')}
            disabled={view.valueOf() <= utcDate().subtract(pastMonthsCount, 'month').valueOf()}
          >
            <LeftArrow className={SubheadingRegular} />
          </IconButton>
        </div>
      )}
      <div className={selectContainer}>
        <MonthYearDropdown
          tabIndex={0}
          items={renderMonthOptions()}
          selected={selectedMonth}
          className={clsx(TextMBold, selectArrowRedesign, selectMonth)}
          testID={childTestID(testID, 'select-month')}
          {...dataTestID(testID, 'change-month')}
        />
        <MonthYearDropdown
          tabIndex={0}
          items={renderYearsOptions}
          selected={selectedYear}
          className={clsx(TextMBold, selectArrowRedesign, selectYear)}
          testID={childTestID(testID, 'select-year')}
          {...dataTestID(testID, 'change-year')}
        />
      </div>
      {onNextMonth && (
        <div>
          <IconButton
            aria-label="next-month"
            variant="text"
            size="small"
            onClick={onNextMonth}
            {...dataTestID(testID, 'next-month')}
            disabled={
              (noFutureDates && futureEndDate.valueOf() < addUtcMonth(view, 1).valueOf()) ||
              viewCopy.valueOf() >= addUtcMonth(initialView, futureMonthsCount - 1).valueOf()
            }
          >
            <RightArrow className={SubheadingRegular} />
          </IconButton>
        </div>
      )}
    </div>
  );
};
