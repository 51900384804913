import { FC, useMemo } from 'react';
import { childTestID } from '../../util/test-id';
import {
  getUtcDay,
  getUtcMonthEnd,
  getUtcMonthStart,
  getUtcWeekEnd,
  getUtcWeekStart,
  utcDate,
  toDateString,
} from './date-util';
import { CalendarSelected } from './types';

export const useRenderRows = (
  viewValue: string,
  Cell: FC<{ value: string; disabled?: boolean; testID?: string }>,
  selected?: CalendarSelected,
  noFutureDates?: boolean,
  maxSpanOfDaysBetweenDates?: number,
  testID?: string,
): JSX.Element[] => {
  return useMemo(() => {
    const view = utcDate(viewValue);
    const monthStart = getUtcMonthStart(view);
    const monthEnd = getUtcMonthEnd(view);
    const start = getUtcWeekStart(monthStart);
    const end = getUtcWeekEnd(monthEnd);
    const result: JSX.Element[][] = [];
    const viewMonth = view.get('month');

    for (let d = start; end.diff(d) >= 0; d = d.add(1, 'day')) {
      const day = getUtcDay(d);
      if (!day) result.push([]);
      const row = result[result.length - 1];

      let disabled = false;
      const today = utcDate();

      if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
        const firstDate = utcDate(selected[0]);
        const date = utcDate(d);

        const diff = Math.abs(firstDate.valueOf() - date.valueOf());
        const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

        disabled = Boolean(noFutureDates && (+d > +today || differenceInDays >= maxSpanOfDaysBetweenDates));
      } else {
        disabled = Boolean(noFutureDates && +d > +today);
      }

      row[day] =
        d.get('month') === viewMonth ? (
          <Cell
            key={day}
            value={toDateString(d)}
            disabled={disabled}
            testID={childTestID(testID, `date-${toDateString(d)}`)}
          />
        ) : (
          <td key={day} aria-hidden="true" />
        );
    }

    return result.map((row, key) => (
      <tr key={key} role="row">
        {row}
      </tr>
    ));
  }, [viewValue, maxSpanOfDaysBetweenDates, selected, Cell, testID, noFutureDates]);
};
