import { FC } from 'react';
import { ErrorPageLayout } from '../components/ErrorPageLayout';
import { SoundWave } from '../components/SoundWave';
import { GoToHomepageButton } from './views';

export const NotFoundPage: FC = () => {
  const upperContentSection = 'The page you are trying to reach was not found.';

  return (
    <ErrorPageLayout
      errorCode="404"
      upperContentSection={upperContentSection}
      testID="page-not-found"
      rightColumnChildren={<SoundWave />}
      leftColumnChildren={<GoToHomepageButton testID="page-not-found" />}
    />
  );
};
