import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode, RefObject } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { TextMBold } from '../../theme/typography.module.scss';
import { dataTestID, TestIDProps, WithIProps } from '../../util/test-id';
import {
  alone,
  contained,
  end,
  icon,
  label,
  large,
  medium,
  outlined,
  root,
  small,
  start,
  text,
  disabled,
  active as activeStyle,
} from './Button.module.scss';

const sizes: Record<ButtonSize, string> = {
  small: small,
  medium: medium,
  large: large,
};

const variants: Record<ButtonVariant, string> = {
  contained: contained,
  outlined: outlined,
  text: text,
};

const render = <P extends RenderProps>({
  forwardRef,
  startIcon,
  endIcon,
  children,
  size = 'medium',
  variant = 'contained',
  active,
  customIconStyle,
  className,
  testID,
  labelProps,
  ...rest
}: P): any => ({
  ref: forwardRef,
  className: clsx(
    root,
    { [disabled]: rest.disabled, [activeStyle]: active },
    TextMBold,
    sizes[size],
    variants[variant],
    className,
  ),
  ...dataTestID(testID),
  ...rest,
  children: (
    <span
      {...labelProps}
      className={clsx(label, labelProps?.className)}
      {...dataTestID(testID, 'label')}
      {...dataTestID(labelProps?.testID)}
    >
      {startIcon && (
        <span className={clsx(customIconStyle, !customIconStyle && icon, children && start, !children && alone)}>
          {startIcon}
        </span>
      )}
      {children}
      {endIcon && <span className={clsx(icon, children && end)}>{endIcon}</span>}
    </span>
  ),
});

type RenderProps = PropsWithChildren<
  ButtonProps | LinkButtonProps | AnchorButtonProps | DisabledLinkButtonProps | LabelButtonProps
>;

export interface ButtonProps extends BaseButtonProps<HTMLButtonElement>, WithIProps<'button'> {}
export const Button: FC<ButtonProps> = (props) => <button {...render(props)} />;

export interface AnchorButtonProps extends BaseButtonProps<HTMLAnchorElement>, WithIProps<'a'> {}
export const AnchorButton: FC<AnchorButtonProps> = (props) => <a {...render(props)} />;

export interface LabelButtonProps extends BaseButtonProps<HTMLLabelElement>, WithIProps<'label'> {}
export const LabelButton: FC<LabelButtonProps> = (props) => <label {...render(props)} />;

export interface SpanButtonProps extends BaseButtonProps<HTMLSpanElement>, WithIProps<'span'> {}
export const SpanButton: FC<SpanButtonProps> = (props) => <span role="button" {...render(props)} />;

export interface LinkButtonProps extends BaseButtonProps<HTMLAnchorElement>, LinkProps, TestIDProps {}
export const LinkButton: FC<LinkButtonProps> = (props) => <Link {...render(props)} />;

export type IconProps<T extends RenderProps> = Omit<T, 'startIcon' | 'endIcon'>;

export const IconButton: FC<IconProps<ButtonProps>> = ({ children, ...props }) => (
  <Button startIcon={children} {...props} />
);

export const LinkIconButton: FC<IconProps<LinkButtonProps>> = ({ children, ...props }) => (
  <LinkButton startIcon={children} {...props} />
);

export interface DisabledLinkButtonProps extends BaseButtonProps<HTMLSpanElement>, WithIProps<'span'> {}

export const DisabledLinkButton: FC<DisabledLinkButtonProps> = (props) => (
  <span {...render({ disabled: true, ...props })} />
);

export const DisabledLinkIconButton: FC<IconProps<DisabledLinkButtonProps>> = ({ children, ...props }) => (
  <DisabledLinkButton startIcon={children} {...props} />
);

export const AnchorIconButton: FC<IconProps<AnchorButtonProps>> = ({ children, ...props }) => (
  <AnchorButton startIcon={children} {...props} />
);

type ButtonVariant = 'contained' | 'outlined' | 'text';
type ButtonSize = 'small' | 'medium' | 'large';

interface BaseButtonProps<T> {
  forwardRef?: RefObject<T>;
  variant?: ButtonVariant;
  size?: ButtonSize;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  labelProps?: WithIProps<'span'>;
  disabled?: boolean;
  active?: boolean;
  customIconStyle?: string;
}
