import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { Falsy, useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async';

const defaultRes: { pending: false; error: null; result: null } = { pending: false, error: null, result: null };

export type Result<R, E = AxiosError | Error> =
  | {
      pending: true;
      error: null;
      result: null;
    }
  | {
      pending: false;
      error: E;
      result: null;
    }
  | {
      pending: false;
      error: null;
      result: R;
    };

export const useRequest = <T>(params: AxiosRequestConfig | Falsy): Result<T> => {
  const task = useAsyncTaskAxios<AxiosResponse<T>>(Axios, params);
  useAsyncRun(params && task);
  return useMemo(() => {
    if (task.result) return { ...defaultRes, result: task.result.data };
    if (task.error) return { ...defaultRes, error: task.error };
    return { ...defaultRes, pending: task.pending };
  }, [task]);
};
