import { createRef, MutableRefObject, useMemo, useRef } from 'react';
import {
  activeSelectChange,
  clearSelected,
  toggleSelected,
  toggleSuggested,
  unselectNext,
  unselectPrev,
} from './selectionHandlers';
import { moveSuggestionCursor, nextSuggestionLine, prevSuggestionLine } from './suggestionNavigation';
import { Action, Option, ReadOptions, ReducerState } from './types';
import { isSameOption, readSuggestions, sortOptions } from './util';

const refs = (): ReducerState['refs'] => ({ container: createRef(), suggestions: createRef(), input: createRef() });

const defaultReducerState: ReducerState = {
  open: false,
  focused: false,
  input: '',
  options: () => [],
  selected: [],
  suggested: [],
  activeSelect: -1,
  activeSuggest: -1,
  lastChange: null,
  refs: refs(),
};

export const useReducerState = (): MutableRefObject<ReducerState> =>
  useRef(useMemo(() => ({ ...defaultReducerState, refs: refs() }), []));

const isSameOptions = (a: Option[], b: Option[]) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) if (!isSameOption(a[i], b[i])) return false;
  return true;
};

type H<P> = (state: ReducerState, payload: P) => ReducerState;

const selectedChange: H<Option[]> = (state, payload) => {
  const { selected, options, input } = state;
  const nselected = sortOptions(payload, input);
  if (!isSameOptions(nselected, selected)) {
    const nsuggested = readSuggestions(options, input, nselected);
    return { ...state, selected: nselected, suggested: nsuggested, activeSelect: -1, activeSuggest: -1 };
  }
  return state;
};

const optionsChange: H<ReadOptions> = (state, payload) => {
  const { selected, suggested, input } = state;
  const nsuggested = readSuggestions(payload, input, selected);
  if (!isSameOptions(nsuggested, suggested)) {
    return { ...state, options: payload, suggested: nsuggested, activeSuggest: -1 };
  }
  return { ...state, options: payload };
};

const inputChange: H<string> = (state, input): ReducerState => {
  const { selected, options } = state;
  const suggested = readSuggestions(options, input, selected);
  return { ...state, input, open: true, suggested, activeSuggest: -1, activeSelect: -1 };
};

const moveSelectedCursor: H<-1 | 1> = (state, direction) => {
  const { activeSelect, selected } = state;
  const moved = (activeSelect < 0 ? selected.length : activeSelect) + direction;
  const result = moved < 0 ? 0 : moved >= selected.length ? -1 : moved;
  if (result !== activeSelect) return { ...state, activeSelect: result };
  return state;
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  const { open, focused } = state;

  switch (action[0]) {
    case 'SHOW_SUGGEST':
      return open ? state : { ...state, open: true };

    case 'FOCUS_IN':
      return focused ? state : { ...state, focused: true };

    case 'FOCUS_OUTSIDE':
      return !focused ? state : { ...state, open: false, focused: false, activeSuggest: -1 };

    case 'HIDE_SUGGEST':
      return !open ? state : { ...state, open: false, activeSuggest: -1 };

    case 'SELECTED_CHANGE':
      return selectedChange(state, action[1]);

    case 'OPTIONS_CHANGE':
      return optionsChange(state, action[1]);

    case 'TOGGLE_OPTION':
      if (action[1].list === 'selected') return toggleSelected(state, action[1]);
      return toggleSuggested(state, action[1]);

    case 'INPUT_CHANGE':
      return inputChange(state, action[1]);

    case 'PREV_SELECT':
      return moveSelectedCursor(state, -1);

    case 'NEXT_SELECT':
      return moveSelectedCursor(state, 1);

    case 'NEXT_SUGGEST_LINE':
      return nextSuggestionLine(state);

    case 'PREV_SUGGEST_LINE':
      return prevSuggestionLine(state);

    case 'PREV_SUGGEST':
      return moveSuggestionCursor(state, -1);

    case 'NEXT_SUGGEST':
      return moveSuggestionCursor(state, 1);

    case 'ACTIVE_SELECT_CHANGE':
      return activeSelectChange(state);

    case 'UNSELECT_NEXT':
      return unselectNext(state);

    case 'UNSELECT_PREV':
      return unselectPrev(state);

    case 'CLEAR_SELECTED':
      return clearSelected(state);
  }
};
