import { useMemo } from 'react';
import { Selection } from './views';
import { Article } from './types';

export interface RenderOptionsProps {
  id: string;
  testID?: string;
}

export const useRenderOptions = (
  options: Article[],
  selected: Article | null,
  onSelect: (id: string, name: string) => void,
  { id, testID }: RenderOptionsProps,
): JSX.Element[] =>
  useMemo(
    () =>
      options.map((option) => (
        <Selection
          key={option.id}
          id={id}
          testID={testID}
          articleId={option.id}
          label={`${option.id} (${option.name})`}
          selected={!!selected && option.id === selected.id}
          onClick={() => {
            onSelect(option.id, option.name);
          }}
        />
      )),
    [id, options, selected, testID, onSelect],
  );
