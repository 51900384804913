import { FC, FormEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateUserInfoRequest, useUserInfo } from '../../userSession/UserInfoProvider';
import { useDispatchEvent } from '../../util/EventDispatcher';
import { useNotification } from '../../notifications';
import { useHistory } from 'react-router-dom';
import { LoadingState } from '../../components/CircularProgress';
import { Agreed, EulaActionWrapper, EulaFormView, EulaTextView } from './views';
import { useEula } from '../../userSession/EulaProvider';
import { childTestID } from '../../util/test-id';

export const EulaPage: FC = () => {
  const { eulaText } = useEula();
  const { agreedToEULA } = useUserInfo();
  const testID = 'eula-page';

  if (!eulaText) return null;

  return (
    <Fragment>
      <EulaTextView testID={childTestID(testID, 'text')} eulaText={eulaText} />
      <EulaActionWrapper>{agreedToEULA ? <Agreed testID={testID} /> : <EulaForm testID={testID} />}</EulaActionWrapper>
    </Fragment>
  );
};

export const EulaForm: FC<{ testID?: string }> = ({ testID }) => {
  const [submit, setSubmit] = useState(false);
  const dispatchEvent = useDispatchEvent();
  const { dispatch: dispatchNotification, messages } = useNotification();
  const { eulaHash } = useEula();
  const history = useHistory();

  const eulaVersion = useMemo(() => !!eulaHash && { eulaVersion: eulaHash }, [eulaHash]);

  const updateRequest = useUpdateUserInfoRequest(submit && eulaVersion);

  const updatePending = useMemo(() => updateRequest?.pending || null, [updateRequest]);
  const error = updateRequest && updateRequest.error;

  useEffect(() => {
    if (error) {
      dispatchNotification([
        'ADD',
        {
          title: `Error`,
          body: messages.FAILED_UPDATE_USER_INFO,
        },
        'ERROR',
      ]);
      setSubmit(false);
    }
  }, [error, dispatchNotification, messages]);

  useEffect(() => {
    if (submit && !updatePending && !error) {
      dispatchEvent('refreshUserInfo', null);
      history.replace('/');
    }
  }, [dispatchEvent, error, history, submit, updatePending]);

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmit(true);
  }, []);

  return submit ? (
    <LoadingState testID={childTestID(testID, 'loading')} />
  ) : (
    <EulaFormView testID={testID} onSubmit={onSubmit} />
  );
};
