import { ParsedIdToken, Role } from '../api';
import { BusinessStream } from './businessStreamCheck';
import { BusinessStreamRolesMap } from './roles';

export const canCallEndpoint = (
  user: ParsedIdToken | null | false,
  businessStream: BusinessStream[],
): boolean | undefined => {
  if (!user) return;
  if (user.isHelpdesk || user.isInternalCompliance) {
    const businessStreams = (user.businessStream?.split(',') || []).map((businessName) =>
      businessName.toLowerCase().trim(),
    );
    const allowedBusinessStreams = businessStream.map((businessName) => businessName.toLowerCase());
    return businessStreams.some((businessName) => allowedBusinessStreams.includes(businessName));
  }

  const userRoles: Role[] = [];

  user.roles?.forEach((orgRole) => {
    orgRole.roleNames.forEach((roleName) => {
      if (!userRoles.includes(roleName)) {
        userRoles.push(roleName);
      }
    });
  });

  return Object.entries(BusinessStreamRolesMap).some(([bs, roles]) => {
    if (businessStream.includes(bs as BusinessStream)) {
      return roles.some((role) => userRoles.includes(role as Role));
    }
    return false;
  });
};
