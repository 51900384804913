import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useState } from 'react';

const findFocusIndex = (currentTarget: HTMLElement): [number, number] => {
  const items = currentTarget.querySelectorAll<HTMLAnchorElement>('[data-item="messageitem"]');
  const { activeElement } = document;
  for (let i = 0; i < items.length; i++) {
    if (items[i] === activeElement) {
      return [i, items.length];
    }
  }
  return [-1, items.length];
};

export interface MessagesNavigationInput {
  containerRef: RefObject<HTMLElement>;
  open: boolean;
  loading: boolean;
}

export interface MessagesNavigationOutput {
  selected: number;
  onKeyDown: (event: KeyboardEvent<HTMLElement>) => void;
}

export const useDropdownMessagesNavigation = (input: MessagesNavigationInput): MessagesNavigationOutput => {
  const { open, containerRef, loading } = input;
  const container = containerRef.current;
  const [selected, setSelected] = useState(0);
  const [forceSelect, setForceSelect] = useState({});

  useMemo(() => !open && setSelected(0), [open]);
  useMemo(() => open && !loading && setForceSelect({}), [open, loading]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (!container) return;
      const { key } = event;

      if (key === ' ') {
        event.preventDefault();
        const element = event.target as HTMLSpanElement;
        const dataItemAttribute = element.getAttribute('data-item');

        if (dataItemAttribute === 'messageitem') element.click();
      }

      if (key === 'ArrowDown') {
        event.preventDefault();
        const [index, total] = findFocusIndex(container);
        if (index < 0) return;

        if (index === total - 1) {
          setSelected(0);
          if (selected === 0) setForceSelect({});
        } else {
          setSelected(index + 1);
          if (selected === index + 1) setForceSelect({});
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        const [index, total] = findFocusIndex(container);

        if (index < 0) return;

        if (index === 0) {
          setSelected(total - 1);
          if (selected === total - 1) setForceSelect({});
        } else {
          setSelected(index - 1);
          if (selected === index - 1) setForceSelect({});
        }
      }
    },
    [container, selected],
  );

  useEffect(() => {
    if (!open || !container || !forceSelect || loading) return;
    // Delay focus to prevent jup on dropdown repositioning
    const timer = setTimeout(() => {
      const items = container.querySelectorAll<HTMLElement>('[data-item="messageitem"]');
      const itemsLength = items.length;
      if (!itemsLength) return;
      items[(itemsLength + selected) % itemsLength].focus();
    }, 0);
    return (): void => clearTimeout(timer);
  }, [container, forceSelect, selected, open, loading]);

  return useMemo(() => ({ selected, onKeyDown }), [onKeyDown, selected]);
};
