import { Dispatch, useEffect } from 'react';
import { Supervisor } from '../../../api';
import { ReducerState, Action } from './types';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterOptions = (supervisors: Supervisor[], value: string): Supervisor[] => {
  const rex = new RegExp(value.split('').map(escapeRegex).join(''), 'i');
  return supervisors.filter((supervisor) => rex.exec(supervisor.fullName));
};

const readSupervisors = (input: string, list: Supervisor[]) => (!input ? list : [...filterOptions(list, input)]);

export const useSupervisorsFilter = (
  state: ReducerState,
  allSupervisors: Supervisor[],
  assignedSupervisors: Supervisor[],
  dispatch: Dispatch<Action>,
): void => {
  const { allSupervisors: allSupervisorsInput, assignedSupervisors: assignedSupervisorsInput } = state.inputs;

  useEffect(() => {
    const nSupervisors = readSupervisors(allSupervisorsInput, allSupervisors);
    dispatch(['SUPERVISORS_CHANGE', 'allSupervisors', nSupervisors]);
  }, [allSupervisors, allSupervisorsInput, dispatch]);

  useEffect(() => {
    const nSupervisors = readSupervisors(assignedSupervisorsInput, assignedSupervisors);
    dispatch(['SUPERVISORS_CHANGE', 'assignedSupervisors', nSupervisors]);
  }, [assignedSupervisors, assignedSupervisorsInput, dispatch]);
};
