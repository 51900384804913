import { FC, useMemo } from 'react';
import { useIsInternalCompliance } from '../../boot';
import { useReports } from '../reports';
import { DownloadReportButton, GraphImage, ReportsWrapper } from './views';
import { ReportsFilterContainer } from './containers';

export const ReportsPage: FC = () => {
  const {
    articleComboBox: { selectedArticle },
    centresComboBox: { selectedCentres },
    datePicker: { selectedRange },
    tabId,
    onReportDownloadClick,
  } = useReports();

  const isInternalCompliance = useIsInternalCompliance();

  const disabledArticle = useMemo(
    () => tabId === 'ARTICLE' && (!selectedRange || !selectedArticle || !Object.keys(selectedArticle).length),
    [selectedRange, selectedArticle, tabId],
  );

  const disabledCentre = useMemo(
    () => tabId === 'CENTRE' && (!selectedRange || !selectedCentres || !selectedCentres.length),
    [selectedRange, selectedCentres, tabId],
  );

  const disabledAllDownloads = useMemo(() => tabId === 'ALL' && !selectedRange, [selectedRange, tabId]);

  const disabled = useMemo(() => disabledCentre || disabledArticle || disabledAllDownloads, [
    disabledAllDownloads,
    disabledArticle,
    disabledCentre,
  ]);

  return (
    <ReportsWrapper isInternalCompliance={isInternalCompliance}>
      <ReportsFilterContainer testID="reports-filter-container" />
      <DownloadReportButton onClick={onReportDownloadClick} disabled={disabled} testID="reports-page" />
      <GraphImage testID="graph-image" />
    </ReportsWrapper>
  );
};
