import { FC, useCallback, useMemo } from 'react';
import { Tab } from '../../components/DatePicker';
import { WithIProps } from '../../util/test-id';
import { Tabs } from '../../components/Tabs';
import {
  LabelView,
  ReportsArticleFilterContainerView,
  ReportsDatePickerView,
  ReportsDatePickerWrapper,
  ReportsDatePickerWrapperProps,
  ReportsFilterArticleComboBoxView,
  ReportsFilterCentreComboBoxView,
  ReportsFilterWrapper,
} from './views';
import { TabId, useReports } from './ReportsProvider';
import { Article } from '../../components/ReportsFilterComboBox';
import { Option } from '../../components/FilterComboBox';
import { utcDate } from '../../components/Calendar';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterArticleOptions = (articles: Article[], value: string): Article[] => {
  const rex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return articles.filter((article) => rex.exec(`${article.id}`) || rex.exec(`${article.name}`));
};

const filterCentresOptions = (options: Option[], value: string): Option[] => {
  const regex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return options.filter((option) => regex.exec(option.label));
};

export const ReportsFilterContainer: FC<WithIProps<'div'>> = ({ ...rest }) => {
  const { tabId, tabList, setTabIdValue } = useReports();

  return (
    <Tabs
      id="reports-tabs"
      activeTabPanel={tabId}
      onTabSelect={setTabIdValue}
      tabs={tabList.map(({ id, name }) => ({
        id,
        name,
        panel: (
          <ReportsArticleFilterContainerView>
            {id === 'ARTICLE' && <ReportsArticleFilter />}
            {id === 'CENTRE' && <ReportsCentreFilter />}
            <ReportsDatePicker tabId={id} single={id === 'ALL'} />
          </ReportsArticleFilterContainerView>
        ),
      }))}
      testID="reports-tabs"
      {...rest}
    />
  );
};

interface ReportsDatePickerProps extends ReportsDatePickerWrapperProps {
  tabId: TabId;
}
const ReportsDatePicker: FC<ReportsDatePickerProps> = ({ tabId, ...rest }) => {
  const {
    datePicker: { onRangeSelect, selectedRange, tabs, maxDays, startDate },
  } = useReports();

  const onDatepickerApply = useCallback(
    (selectedRange: Tab | null): void => {
      onRangeSelect(selectedRange);
    },
    [onRangeSelect],
  );

  const safeCurrentDate = utcDate();
  const safePastDate = useMemo(() => utcDate(startDate), [startDate]);

  const diff =
    safeCurrentDate.get('year') * 12 +
    safeCurrentDate.get('month') -
    (safePastDate.get('year') * 12 + safePastDate.get('month'));

  const range = useMemo(() => ({ monthsBefore: diff, monthsAfter: 1 }), [diff]);

  const tab = selectedRange?.reportId === tabId.toLowerCase() ? selectedRange : null;
  return (
    <ReportsDatePickerWrapper {...rest}>
      <LabelView>Date range</LabelView>
      <ReportsDatePickerView
        id={`reports-date-picker-component-${tabId.toLowerCase()}`}
        selected={tab}
        onApply={onDatepickerApply}
        tabs={tabs}
        testID={`reports-date-picker-component-${tabId.toLowerCase()}`}
        range={range}
        noFutureDates={true}
        maxSpanOfDaysBetweenDates={maxDays}
        yearFrom={safePastDate}
      />
    </ReportsDatePickerWrapper>
  );
};

const ReportsArticleFilter: FC<WithIProps<'div'>> = ({ ...rest }) => {
  const {
    articleComboBox: { selectedArticle, articleOptions, focused, onSelectArticle, setFocusedContainer },
  } = useReports();

  const readArticles = useCallback(
    (input: string) => (!input ? articleOptions : [...filterArticleOptions(articleOptions, input)]),
    [articleOptions],
  );

  return (
    <ReportsFilterWrapper {...rest}>
      <LabelView>Article No.</LabelView>
      <ReportsFilterArticleComboBoxView
        id="reports-article-filter-component"
        selected={selectedArticle}
        articles={readArticles}
        focused={focused}
        testID="reports-article-filter-component"
        onSelectArticle={onSelectArticle}
        articleOptions={articleOptions}
        setFocusedContainer={setFocusedContainer}
      />
    </ReportsFilterWrapper>
  );
};

const ReportsCentreFilter: FC<WithIProps<'div'>> = ({ ...rest }) => {
  const maxSuggestions = 25;

  const {
    centresComboBox: { selectedCentres, centreOptions, groups, setSelectedCentre },
  } = useReports();

  const readCentres = useCallback(
    (input: string) => {
      const selectedCentreIds = selectedCentres.map((sc) => sc.value);
      const filteredCentreOptions = filterCentresOptions(centreOptions, input || '').filter(
        (fc) => !selectedCentreIds.includes(fc.value),
      );
      groups[0].totalOptions = filteredCentreOptions.length;
      return [...filteredCentreOptions.slice(0, maxSuggestions)];
    },
    [centreOptions, groups, selectedCentres],
  );

  const onSelectionChange = useCallback(
    (options: Option[]): void => {
      setSelectedCentre(options);
    },
    [setSelectedCentre],
  );

  return (
    <ReportsFilterWrapper {...rest}>
      <LabelView>Centre number</LabelView>
      <ReportsFilterCentreComboBoxView
        id="reports-centre-filter-component"
        selected={selectedCentres}
        options={readCentres}
        groups={groups}
        onSelectionChange={onSelectionChange}
        testID="reports-centre-filter-component"
      />
    </ReportsFilterWrapper>
  );
};
