import clsx from 'clsx';
import { FC, useEffect, useRef } from 'react';
import { TextSBold } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps, childTestID } from '../../util/test-id';
import { SpanButton, SpanButtonProps } from '../Button';
import { TextSRegular } from '../../theme/typography.module.scss';
import { Done } from '../Icon';
import {
  cell,
  cellbg,
  cellbox,
  container,
  group,
  hcell,
  marked,
  selectEnd,
  selectMiddle,
  selectStart,
  table,
  disabledCell,
  suggestionBase,
  activeSuggestion,
  selectedItem,
  doneIcon,
  dropdownLabel,
} from './styles.module.scss';

export interface CellProps extends WithIProps<'td'> {
  active: boolean;
  selected: boolean;
  selectionStart: boolean;
  selectionEnd: boolean;
  selectionMiddle: boolean;
  disabled?: boolean;
}

export const Cell: FC<CellProps> = ({
  active,
  selected,
  selectionStart,
  selectionEnd,
  selectionMiddle,
  disabled,
  className,
  testID,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (active && ref.current) ref.current.focus();
  }, [active]);

  return (
    <td
      ref={ref}
      role="gridcell"
      className={clsx(cell, { [disabledCell]: disabled }, className)}
      tabIndex={active && !disabled ? 0 : -1}
      aria-selected={selectionStart || selectionMiddle || selectionEnd}
      aria-hidden={disabled}
      {...dataTestID(testID)}
      {...rest}
    >
      <span
        className={clsx(cellbg, {
          [selectMiddle]: selectionMiddle && !disabled,
          [selectStart]: selectionStart && !disabled,
          [selectEnd]: selectionEnd && !disabled,
        })}
      >
        <span className={clsx(cellbox, { [cellbox]: !disabled, [marked]: selected })}>{children}</span>
      </span>
    </td>
  );
};

const headers: [string, string][] = [
  ['Monday', 'Mon'],
  ['Tuesday', 'Tue'],
  ['Wednesday', 'Wed'],
  ['Thursday', 'Thu'],
  ['Friday', 'Fri'],
  ['Saturday', 'Sat'],
  ['Sunday', 'Sun'],
];

export const Table: FC<WithIProps<'table'>> = ({ className, children, testID, ...rest }) => (
  <table role="grid" className={clsx(table, className)} {...dataTestID(testID)} {...rest}>
    <thead className={TextSBold}>
      <tr>
        {headers.map(([abbr, name]) => (
          <th key={abbr} className={hcell} scope="col" role="columnheader" abbr={abbr}>
            {name}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

export type ContainerProps = WithIProps<'div'>;

export const Container: FC<ContainerProps> = ({ className, testID, ...rest }) => {
  return <div className={clsx(container, className)} {...dataTestID(testID)} {...rest} />;
};

export type GroupProps = WithIProps<'div'>;

export const Group: FC<GroupProps> = ({ testID, className, ...rest }) => {
  return <div className={clsx(group, className)} {...dataTestID(testID)} {...rest} />;
};

interface SelectionProps extends SpanButtonProps {
  label: string;
  selected: boolean;
}

export const Selection: FC<SelectionProps> = ({ id, label, selected, disabled, testID, className, ...rest }) => {
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <SpanButton
      forwardRef={ref}
      id={childTestID(id, 'date-dropdown')}
      className={clsx(
        TextSRegular,
        { [activeSuggestion]: !disabled, [suggestionBase]: disabled, [selectedItem]: selected && !disabled },
        className,
      )}
      size="small"
      variant="text"
      role="option"
      data-item="menuitem"
      disabled={disabled}
      {...(selected ? { 'aria-selected': 'true' } : {})}
      testID={childTestID(testID, 'date-dropdown')}
      {...rest}
    >
      {label}
      {selected && <Done className={doneIcon} />}
    </SpanButton>
  );
};

export const MonthYearLabel: FC<WithIProps<'span'>> = ({ testID, className, ...rest }) => (
  <span className={clsx(dropdownLabel, className)} {...dataTestID(testID)} {...rest} />
);
