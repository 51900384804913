import { createRef } from 'react';
import { Action, ReducerState } from './types';

const refs = (): ReducerState['refs'] => ({ input: createRef(), container: createRef(), close: createRef() });

export const initialState: ReducerState = {
  inputs: { allSupervisors: '', assignedSupervisors: '' },
  activeTab: 'assignedSupervisors',
  focused: false,
  assignedSupervisors: [],
  allSupervisors: [],
  refs: refs(),
};

type H<P> = (state: ReducerState, payload: P) => ReducerState;

const inputChange: H<string> = (state, input): ReducerState => {
  const { activeTab, inputs } = state;
  return { ...state, inputs: { ...inputs, [activeTab]: input } };
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  const { focused, inputs } = state;
  switch (action[0]) {
    case 'FOCUS_IN':
      return focused ? state : { ...state, focused: true };

    case 'FOCUS_OUTSIDE':
      return !focused ? state : { ...state, focused: false };

    case 'SELECTED_CHANGE':
      return { ...state, activeTab: action[1] };

    case 'SUPERVISORS_CHANGE':
      return { ...state, [action[1]]: action[2] };

    case 'INPUT_CHANGE':
      return inputChange(state, action[1]);

    case 'CLEAR_INPUT':
      return { ...state, inputs: { ...inputs, [action[1]]: '' } };

    case 'CLEAR_ALL_INPUTS':
      return { ...state, inputs: { allSupervisors: '', assignedSupervisors: '' } };
  }
};
