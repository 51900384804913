import clsx from 'clsx';
import { FC } from 'react';
import { TextMRegular } from '../../theme/typography.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';
import { actionsWrapper, content, wrapper } from './styles.module.scss';

export const Bar: FC<WithIProps<'div'>> = ({ testID, className, children, ...rest }) => {
  return (
    <div className={clsx(wrapper, className)} {...dataTestID(testID, 'wrapper')} {...rest}>
      {children}
    </div>
  );
};

export const BarActions: FC<WithIProps<'div'>> = ({ testID, className, children, ...rest }) => {
  return (
    <div className={clsx(actionsWrapper, className)} {...dataTestID(testID, 'actions')} {...rest}>
      {children}
    </div>
  );
};

export const BarContent: FC<WithIProps<'span'>> = ({ testID, className, children, ...rest }) => (
  <span className={clsx(content, TextMRegular, className)} {...dataTestID(testID, 'content')} {...rest}>
    {children}
  </span>
);
