import { AxiosError } from 'axios';
import { createContext, createElement, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getBaseURL, TokenResponse, useAPIRequest } from '../api';
import { useBootState } from '../boot';
import { BusinessStream } from '../centres/businessStreamCheck';
import { useQuery, useRoute } from '../Routes';
import { useSignature } from '../singnature';
import { sleep } from '../util/helpers';

interface Context {
  token: TokenResponse | null;
  timeout: NodeJS.Timeout | undefined;
  pending: boolean;
  error: AxiosError | Error | null;
  refreshToken: () => void;
}

const Context = createContext<Context>({
  token: null,
  timeout: undefined,
  pending: false,
  error: null,
  refreshToken: () => {
    throw new Error('Missing UserExtentionProvider');
  },
});

const delay = 3 * 60 * 1000;

const checkActivity = (time: number) => {
  const now = new Date();
  return now.getTime() - delay < time;
};

export const useUserSessionExtension = (): Context => useContext(Context);

export const UserSessionExtensionProvider: FC = ({ children }) => {
  const { dispatch } = useBootState();
  const refresh = useRefreshTokenParams();

  const params = useMemo(() => refresh && refresh(), [refresh]);
  const [active, setActive] = useState(false);
  const [retry, setRetry] = useState(false);
  const [time, setTime] = useState(0);
  const baseURL = getBaseURL(BusinessStream.ENGLISH);
  const tokenResponse = useAPIRequest<TokenResponse>(
    useMemo(
      () =>
        (active || retry) && {
          url: '/token',
          method: 'POST',
          data: params,
          baseURL,
        },
      [active, params, retry, baseURL],
    ),
  );

  const { result, error, pending } = tokenResponse;
  useEffect(() => {
    if (result) {
      setActive(false);
      dispatch(['REFRESH_TOKEN_RESPONSE', result]);
      sessionStorage.setItem('token', JSON.stringify(result));
    }
  }, [dispatch, result]);

  useEffect(() => {
    if (error && !retry) {
      console.error('error', error);
      setActive(false);
      sleep(2000).then(() => setRetry(true));
    }
  }, [error, retry]);

  const refreshError = useMemo(() => (retry && error) || null, [error, retry]);

  const onCheck = useCallback(() => {
    if (checkActivity(time)) {
      setActive(true);
    }
  }, [time]);

  const refreshToken = useCallback(() => {
    setActive(true);
  }, []);

  const timeout = useRecursiveTimeout(onCheck, delay);

  useEffect(() => {
    if (refreshError && timeout) {
      clearTimeout(timeout);
    }
    return () => timeout && clearTimeout(timeout);
  }, [refreshError, timeout]);

  const signature = useSignature();
  const prevSignature = useRef(signature);
  const query = useQuery();
  const prevQuery = useRef(query);
  const route = useRoute();
  const prevRoute = useRef(route);

  const onClick = useCallback(() => {
    setTime(new Date().getTime());
  }, []);

  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, [onClick]);

  useEffect(() => {
    if (query !== prevQuery.current) {
      setTime(new Date().getTime());
      prevQuery.current = query;
    }
  }, [query]);

  useEffect(() => {
    if (route !== prevRoute.current) {
      setTime(new Date().getTime());
      prevRoute.current = route;
    }
  }, [route]);

  useEffect(() => {
    if (signature !== prevSignature.current) {
      setTime(new Date().getTime());
      prevSignature.current = signature;
    }
  }, [signature]);

  return createElement(
    Context.Provider,
    { value: { token: result, timeout, pending, error: refreshError, refreshToken } },
    children,
  );
};

export const useRecursiveTimeout = (callback: () => void, delay: number): NodeJS.Timeout | undefined => {
  const savedCallback = useRef(callback);
  const [id, setId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
      setId(setTimeout(tick, delay));
    };

    setId(setTimeout(tick, delay));
  }, [delay]);

  return id;
};

export const useRefreshTokenParams = (): (() => string) | null => {
  const boot = useBootState();
  const config = (!boot.loading && boot.config) || null;
  const token = (!boot.loading && boot.token) || null;
  return useMemo(
    () =>
      config &&
      token &&
      (() => {
        const { AUTH_CLIENT_ID } = config;
        const { refresh_token } = token;
        const nonce = () => Math.random().toString(32).slice(2) + Date.now().toString(32);

        return new URLSearchParams({
          client_id: AUTH_CLIENT_ID,
          nonce: nonce(),
          grant_type: 'refresh_token',
          refresh_token,
        }).toString();
      }),
    [config, token],
  );
};
