import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { useSidebar } from './SidebarState';
import { SupervisorsSessionsFilter } from './SupervisorsViews';
import { shrinkedFilter } from './styles.module.scss';
import { SupervisorsSessionsTable } from './SupervisorsContainers';
import { SidebarView } from './SupervisorsSidebar';

export const SupervisorsPage: FC = () => {
  const {
    state: { visible },
  } = useSidebar();

  return (
    <Fragment>
      <SupervisorsSessionsFilter className={clsx({ [shrinkedFilter]: visible })} />
      <SupervisorsSessionsTable />
      <SidebarView />
    </Fragment>
  );
};
