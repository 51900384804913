import { Dispatch, useEffect } from 'react';
import { Action, ReducerState } from './types';

export const useFocusHandler = (refs: ReducerState['refs'], visible: boolean, dispatch: Dispatch<Action>): void => {
  useEffect(() => {
    const inputElement = refs.input.current;
    const containerElement = refs.container.current;
    const closeElement = refs.close.current;

    if (containerElement && visible) {
      const focusin = (event: FocusEvent) => {
        if (inputElement && inputElement !== event.target && event.target !== closeElement) {
          inputElement.focus();
        }

        const related = event.relatedTarget as HTMLElement | null;
        if (!related || !containerElement.contains(related)) {
          dispatch(['FOCUS_IN']);
        }
      };

      containerElement.addEventListener('focusin', focusin);
      return () => containerElement.removeEventListener('focusin', focusin);
    }
  }, [dispatch, refs, visible]);

  useEffect(() => {
    const containerElement = refs.container.current;
    if (containerElement && visible) {
      const focusout = (event: FocusEvent) => {
        const related = event.relatedTarget as HTMLElement | null;
        if (!related || !containerElement.contains(related)) {
          dispatch(['FOCUS_OUTSIDE']);
        }
      };

      containerElement.addEventListener('focusout', focusout);
      return () => containerElement.removeEventListener('focusout', focusout);
    }
  }, [dispatch, refs, visible]);
};
