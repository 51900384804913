import { createContext, Dispatch, FC, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { Supervisor } from '../../api';
import { useProvider } from '../../util/useProvider';
import { useSupervisors } from './SupervisorsProvider';
import { useSupervisorsSessions } from './SupervisorsSessionsProvider';

interface SidebarState {
  visible: boolean;
  selectedSessions: string[];
  commonUsers: Supervisor[];
  checkedUsers: Supervisor[];
  supervisors: Supervisor[];
  hasChanged: boolean;
}

interface SidebarStateContextProps {
  state: SidebarState;
  dispatch: Dispatch<Actions>;
}

const initialState: SidebarState = {
  visible: false,
  selectedSessions: [],
  commonUsers: [],
  checkedUsers: [],
  supervisors: [],
  hasChanged: false,
};

const SidebarContext = createContext<SidebarStateContextProps>({
  state: initialState,
  dispatch: () => {},
});

export const useSidebar = (): SidebarStateContextProps => useContext(SidebarContext);

type State = SidebarState;

type Actions =
  | ['SELECT_SESSIONS', string[]]
  | ['UNSELECT_SESSIONS', string[]]
  | ['SHOW_SIDEBAR']
  | ['HIDE_SIDEBAR']
  | ['SET_USERS', Supervisor[]]
  | ['SET_COMMON_USERS', Supervisor[]]
  | ['SET_CHECKED_USERS', Supervisor[]]
  | ['SET_CHECKED_SINGLE_USER', string]
  | ['UNSET_CHECKED_SINGLE_USER', string]
  | ['UNSELECT_ALL_SESSIONS']
  | ['SET_HAS_CHANGED', boolean]
  | ['RESET_STATE'];

const reducer = (state: State, action: Actions): State => {
  switch (action[0]) {
    case 'SELECT_SESSIONS': {
      const sessions = action[1];
      const joint = [...state.selectedSessions, ...sessions];
      const filteredJoint = joint
        .slice()
        .sort((a, b) => a.localeCompare(b))
        .reduce((a, b) => {
          if (a.slice(-1)[0] !== b) a.push(b);
          return a;
        }, [] as string[]);

      return {
        ...state,
        selectedSessions: [...filteredJoint],
      };
    }

    case 'UNSELECT_SESSIONS': {
      const sessions = action[1];
      const { selectedSessions } = state;
      return {
        ...state,
        selectedSessions: [...selectedSessions.filter((id) => sessions.indexOf(id) === -1)],
      };
    }

    case 'UNSELECT_ALL_SESSIONS': {
      return {
        ...state,
        selectedSessions: [],
      };
    }

    case 'SHOW_SIDEBAR': {
      return {
        ...state,
        visible: true,
      };
    }

    case 'HIDE_SIDEBAR': {
      return {
        ...state,
        visible: false,
        commonUsers: [],
        checkedUsers: [],
      };
    }

    case 'SET_USERS': {
      const users = action[1];
      return {
        ...state,
        supervisors: [...users],
      };
    }

    case 'SET_COMMON_USERS': {
      const users = action[1];
      return {
        ...state,
        commonUsers: [...users],
      };
    }

    case 'SET_CHECKED_USERS': {
      const users = action[1];
      return {
        ...state,
        checkedUsers: [...users],
      };
    }

    case 'SET_CHECKED_SINGLE_USER': {
      const email = action[1];
      const { checkedUsers, supervisors } = state;
      const user = supervisors.find((u) => u.email === email);

      if (!user) return state;

      return {
        ...state,
        checkedUsers: [user, ...checkedUsers.filter((u) => u.email !== user.email)].sort((a, b) =>
          a.fullName.localeCompare(b.fullName),
        ),
      };
    }

    case 'UNSET_CHECKED_SINGLE_USER': {
      const email = action[1];
      const { checkedUsers } = state;
      return {
        ...state,
        checkedUsers: [...checkedUsers.filter((u) => u.email !== email)],
      };
    }

    case 'SET_HAS_CHANGED': {
      return {
        ...state,
        hasChanged: action[1],
      };
    }

    case 'RESET_STATE': {
      return {
        ...initialState,
      };
    }
  }
};

export const SidebarState: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedSessions, checkedUsers, commonUsers, hasChanged } = state;

  const { data: supervisors } = useSupervisors();
  const { data: sessions } = useSupervisorsSessions();

  const noChange = useMemo(
    () =>
      commonUsers.length === checkedUsers.length &&
      commonUsers.every((u) => checkedUsers.some((cu) => cu.email === u.email)),
    [checkedUsers, commonUsers],
  );

  useEffect(() => {
    dispatch(['SET_HAS_CHANGED', !noChange]);
  }, [noChange]);

  useEffect(() => {
    if (sessions) {
      const usersMap: Record<string, Supervisor> = {};
      selectedSessions.map((s) => sessions.items[s].venueUsers.forEach((u) => (usersMap[u.email] = u)));
      const userArray = Object.values(usersMap);
      const commonUsers = userArray
        .filter((u) => selectedSessions.every((s) => sessions.items[s].venueUsers.some((su) => su.email === u.email)))
        .sort((a, b) => a.fullName.localeCompare(b.fullName));
      dispatch(['SET_COMMON_USERS', commonUsers]);
      dispatch(['SET_CHECKED_USERS', commonUsers]);
    }
  }, [sessions, selectedSessions]);

  useEffect(() => {
    if (supervisors) {
      dispatch(['SET_USERS', supervisors.sort((a, b) => a.fullName.localeCompare(b.fullName))]);
    }
  }, [supervisors]);

  useEffect(() => {
    if (!selectedSessions.length) {
      dispatch(['HIDE_SIDEBAR']);
    } else {
      dispatch(['SHOW_SIDEBAR']);
    }
  }, [selectedSessions]);

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasChanged) {
        event.preventDefault();
        event.returnValue = '';
      } else {
        return false;
      }
    },
    [hasChanged],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const value = useMemo<SidebarStateContextProps>(() => ({ state, dispatch }), [state]);

  return useProvider(SidebarContext, value, children);
};
