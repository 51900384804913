import { ChangeEvent, FC, useCallback, useMemo, ReactNode, Ref, useEffect, useRef } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { reducer, useReducerState } from './reducer';
import { Article, ReadArticles, StateResult } from './types';
import { useDispatcher } from './useDispatcher';
import { useFocusHandler } from './useFocusHandler';
import { useRenderOptions } from './useRenderOptions';
import { UserWidgetDropDownMenu as DropDownMenu } from '../../pages/views';
import { DropdownMenuContainer } from '../DropDown';
import { dropdownBox } from './ReportsFilterComboBox.module.scss';
import { EmptyDropdownMessage, InputContainerView, InputLabelView, InputView, Clear } from './views';

export interface ReportsFilterComboBoxProps extends WithIProps<'div'> {
  forwardRef?: Ref<HTMLDivElement>;
  before?: ReactNode;
  after?: ReactNode;
  id: string;
  selected: Article | null;
  articles: ReadArticles;
  onSelectArticle: (id: string, name: string) => void;
  articleOptions: Article[];
  setFocusedContainer: (value: boolean) => void;
}

const useStateFromProps = (state: StateResult, articles: ReadArticles, selected: Article | null) => {
  useMemo(() => {
    state.current = reducer(state.current, ['ARTICLES_CHANGE', articles]);
  }, [articles, state]);

  useMemo(() => {
    state.current = reducer(state.current, ['SELECTED_CHANGE', selected]);
  }, [selected, state]);
};

export const ReportsFilterComboBox: FC<ReportsFilterComboBoxProps> = (props) => {
  const dropdownId = `articles-dropdown`;

  const { id, testID, articles, onSelectArticle, articleOptions, setFocusedContainer, ...rest } = props;

  const state = useReducerState();
  const dispatch = useDispatcher(state);
  useStateFromProps(state, articles, props.selected);

  const { current } = state;
  const { input, refs, list, open, selected } = current;

  useFocusHandler(state, dispatch, setFocusedContainer);

  useEffect(() => {
    dispatch(['INPUT_CHANGE', selected?.id || '']);
  }, [selected, dispatch]);

  const onItemSelect = useCallback(
    (id: string, name: string) => {
      onSelectArticle(id, name);
      dispatch(['INPUT_CHANGE', id]);
      dispatch(['HIDE_SUGGEST']);
    },
    [dispatch, onSelectArticle],
  );

  const onInputChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      dispatch(['INPUT_CHANGE', currentTarget.value]);
      dispatch(['SHOW_SUGGEST']);

      const optionSelected = articleOptions.find((option) => option.id === currentTarget.value);

      if (!optionSelected) {
        onSelectArticle('', '');
      } else {
        onSelectArticle(optionSelected.id, optionSelected.name);
      }
    },
    [dispatch, articleOptions, onSelectArticle],
  );

  const skipOpen = useRef(false);

  const onClose = useCallback(() => {
    dispatch(['HIDE_SUGGEST']);
  }, [dispatch]);

  const onOpen = useCallback(() => {
    dispatch(['SHOW_SUGGEST']);
  }, [dispatch]);

  const onClick = useCallback(() => {
    if (!skipOpen.current && !open) dispatch(['SHOW_SUGGEST']);
    skipOpen.current = false;
  }, [open, dispatch]);

  const onClear = useCallback(() => {
    dispatch(['INPUT_CHANGE', '']);
    onSelectArticle('', '');
    skipOpen.current = true;
  }, [dispatch, onSelectArticle]);

  const centresList = useRenderOptions(list, selected, onItemSelect, props);

  return (
    <div ref={refs.container}>
      <DropdownMenuContainer
        autoFocus={false}
        testID={childTestID(testID, `articles-container`)}
        open={open}
        onClose={onClose}
        onClick={onClick}
        onOpen={onOpen}
        downOnly={true}
        {...rest}
      >
        <InputContainerView testID={childTestID(testID, 'input-container')}>
          <InputLabelView
            visible={!input && (!selected || Object.keys(selected).length === 0)}
            htmlFor={id}
            testID={childTestID(testID, 'input-label')}
          >
            Insert article no.
          </InputLabelView>
          <InputView
            value={input}
            forwardRef={refs.input}
            id={id}
            onChange={onInputChange}
            onClick={onClick}
            aria-controls={dropdownId}
            aria-expanded={open}
            aria-haspopup="true"
            aria-label="open articles dropdown"
            testID={childTestID(testID, 'input')}
            after={
              <span>
                <Clear
                  aria-label="Clear the input"
                  size="small"
                  disabled={!input && (!selected || (selected && Object.keys(selected).length === 0))}
                  onClick={onClear}
                  testID={childTestID(testID, 'clear-button')}
                />
              </span>
            }
          />
        </InputContainerView>

        <DropDownMenu
          visible={open}
          id={dropdownId}
          aria-labelledby={dropdownId}
          testID={childTestID(testID, dropdownId)}
          className={dropdownBox}
          items={[...(centresList.length ? centresList : [<EmptyDropdownMessage key="1" />])]}
        />
      </DropdownMenuContainer>
    </div>
  );
};
