import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Session } from '../api';
import { Group, Option } from '../components/FilterComboBox';
import { Tab, TabInformation } from '../components/DatePicker';
import { useProvider } from '../util/useProvider';
import {
  buildFilter,
  buildQuery,
  parseSearchQuery,
  parseDateQuery,
  readOptions,
  buildDateQuery,
} from './session-filter-util';
import { useSessions } from './SessionsProvider';
import { BusinessStream } from '../centres/businessStreamCheck';

interface FilterContextProps {
  comboBox: ComboBox;
  datePicker: DatePicker;
}

interface ComboBox {
  options: Option[];
  groups: Group[];
  selected: Option[];
  setSelected: (options: Option[]) => void;
  filter: (sessions: Session[]) => Session[];
}

interface DatePicker {
  tabs: TabInformation[];
  onRangeSelect: (selectedRange: Tab | null) => void;
  selectedRange: Tab | null;
}

const FilterContext = createContext<FilterContextProps>({
  comboBox: {
    filter: buildFilter([], null, ''),
    selected: [],
    setSelected: () => {
      throw new Error('Missing `ChildSessionFilterProvider`');
    },
    options: [],
    groups: [],
  },
  datePicker: {
    onRangeSelect: () => {},
    selectedRange: null,
    tabs: [],
  },
});

export const useChildSessionFilter = (): FilterContextProps => useContext(FilterContext);

const groups: Group[] = [
  { name: 'customers', label: 'Centre' },
  { name: 'qualificationShortName', label: 'Qualification name' },
  { name: 'assessmentId', label: 'Syllabus code' },
  { name: 'componentId', label: 'Component code' },
  { name: 'assessmentShortName', label: 'Syllabus name' },
  { name: 'componentShortName', label: 'Component name' },
  { name: 'isNew', label: 'New' },
  { name: 'sitting', label: 'Sitting' },
];

const tabs: TabInformation[] = [
  { id: 'date', name: 'Exam date' },
  { id: 'available', name: 'Availability date' },
];

export const ChildSessionFilterProvider: FC = ({ children }) => {
  const { data } = useSessions();
  const items = data?.childCentreItems;
  const options = useMemo(() => (items && readOptions(Object.values(items), BusinessStream.INTERNATIONAL)) || [], [
    items,
  ]);

  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const selected = useMemo(() => parseSearchQuery(params.get('q'), options), [options, params]);
  const selectedRange = useMemo(() => parseDateQuery(params.get('d')), [params]);

  const filter = useMemo(() => buildFilter(selected, selectedRange, BusinessStream.INTERNATIONAL), [
    selected,
    selectedRange,
  ]);

  const setSelected = useCallback(
    (selected: Option[]) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);
      params.delete('page');

      const query = buildQuery(selected);

      if (!query) params.delete('q');
      else params.set('q', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const onRangeSelect = useCallback(
    (selectedRange: Tab | null) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);
      params.delete('page');

      const query = selectedRange && buildDateQuery(selectedRange);

      if (!query) params.delete('d');
      else params.set('d', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const value = useMemo<FilterContextProps>(
    () => ({
      comboBox: {
        filter,
        setSelected,
        selected,
        options,
        groups: groups,
      },
      datePicker: { tabs, onRangeSelect, selectedRange },
    }),
    [filter, onRangeSelect, selectedRange, options, selected, setSelected],
  );

  return useProvider(FilterContext, value, children);
};
