import clsx from 'clsx';
import { FC } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { Button, ButtonProps } from '../../components/Button';
import { DatePicker, DatePickerProps } from '../../components/DatePicker';
import { ReportsFilterComboBox, ReportsFilterComboBoxProps } from '../../components/ReportsFilterComboBox';
import reportGraph from '../../public/reportGraph.svg';
import {
  input,
  datePicker,
  label,
  labelContainer,
  focusedContainer,
  graphImage,
  centreComboBox,
  reportsWrapper,
  reportsWrapperWithTabs,
  graphImageContainer,
  downloadButton,
  inputContainer,
  datePickerContainer,
  filterContainer,
  singleDatePickerContainer,
} from './styles.module.scss';
import { FilterComboBox, FilterComboBoxProps } from '../../components/FilterComboBox';
import { Dayjs } from 'dayjs';

interface ReportsFilterArticleComboBoxViewProps extends ReportsFilterComboBoxProps {
  focused: boolean;
}

export const ReportsFilterArticleComboBoxView: FC<ReportsFilterArticleComboBoxViewProps> = ({
  focused,
  className,
  ...rest
}) => <ReportsFilterComboBox className={clsx({ [focusedContainer]: focused }, input, className)} {...rest} />;

export const ReportsFilterCentreComboBoxView: FC<FilterComboBoxProps> = ({ className, ...rest }) => (
  <FilterComboBox className={clsx(centreComboBox, className)} {...rest} />
);

export const LabelView: FC<WithIProps<'label'>> = ({ className, testID, ...rest }) => (
  <div className={labelContainer}>
    <label className={clsx(label, className)} {...dataTestID(testID)} {...rest} />
  </div>
);

export const DownloadReportButton: FC<ButtonProps> = ({ testID, className, ...rest }) => (
  <Button
    className={clsx(downloadButton, className)}
    testID={`${testID}-download-report-button`}
    tabIndex={0}
    {...rest}
  >
    Download report
  </Button>
);

interface ReportsDatePickerViewProps extends DatePickerProps {
  noFutureDates?: boolean;
  maxSpanOfDaysBetweenDates?: number;
  yearFrom?: Dayjs;
}

export const ReportsDatePickerView: FC<ReportsDatePickerViewProps> = ({
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  className,
  ...rest
}) => (
  <DatePicker
    noFutureDates={noFutureDates}
    maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
    yearFrom={yearFrom}
    className={clsx(datePicker, className)}
    {...rest}
  />
);

export const GraphImage: FC<WithIProps<'div'>> = ({ testID, className, ...rest }) => (
  <div className={clsx(graphImageContainer, className)} {...dataTestID(testID, 'container')} {...rest}>
    <img src={reportGraph} alt="Graph" className={graphImage} {...dataTestID(testID)} />
  </div>
);

interface ReportsWrapperProps extends WithIProps<'div'> {
  isInternalCompliance: boolean;
}

export const ReportsWrapper: FC<ReportsWrapperProps> = ({ isInternalCompliance, children, className, ...rest }) => (
  <div
    className={clsx(
      { [reportsWrapper]: !isInternalCompliance, [reportsWrapperWithTabs]: isInternalCompliance },
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export const ReportsFilterWrapper: FC<WithIProps<'div'>> = ({ className, children, ...rest }) => (
  <div className={clsx(inputContainer, className)} {...rest}>
    {children}
  </div>
);

export interface ReportsDatePickerWrapperProps extends WithIProps<'div'> {
  single?: boolean;
}

export const ReportsDatePickerWrapper: FC<ReportsDatePickerWrapperProps> = ({
  single,
  className,
  children,
  ...rest
}) => (
  <div className={clsx(datePickerContainer, { [singleDatePickerContainer]: single }, className)} {...rest}>
    {children}
  </div>
);

export const ReportsArticleFilterContainerView: FC<WithIProps<'div'>> = ({ className, children, testID, ...rest }) => (
  <div className={clsx(filterContainer, className)} {...dataTestID(testID)} {...rest}>
    {children}
  </div>
);
