import { FC, useCallback } from 'react';
import { Option } from '../../../components/FilterComboBox';
import { useSupervisorSessionFilter } from '../SupervisorsSessionsFilterProvider';
import { SupervisorsSessionsFilterComboBoxView } from './SupervisorsSessionsFilterComboBoxView';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterOptions = (options: Option[], value: string): Option[] => {
  const rex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  const rexForSupervisors = new RegExp([value].map(escapeRegex).join('.*?'), 'i');

  return options.filter((option) => {
    if (option.group === 'supervisors') {
      return rexForSupervisors.exec(option.label);
    }
    return rex.exec(option.label);
  });
};

export const SupervisorsSessionsFilterComboBox: FC = () => {
  const {
    comboBox: { selected, setSelected, options, groups },
  } = useSupervisorSessionFilter();

  const onSelectionChange = useCallback(
    (options: Option[]): void => {
      setSelected(options);
    },
    [setSelected],
  );

  const readOptions = useCallback((input: string) => (!input ? options : [...filterOptions(options, input)]), [
    options,
  ]);

  return (
    <SupervisorsSessionsFilterComboBoxView
      id="search-component"
      testID="search-component"
      selected={selected}
      onSelectionChange={onSelectionChange}
      options={readOptions}
      groups={groups}
    />
  );
};
