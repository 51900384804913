import { createContext, createElement, Dispatch, FC, useContext, useEffect, useMemo, useReducer } from 'react';
import { Message } from '../api';
import { useMessagesProvider, useReadMessageRequest } from './MessagesProvider';

export type MessagesStateContextProps = {
  state: Omit<State, 'processing'>;
  pending: boolean;
  dispatch: Dispatch<Action>;
};

const MessagesStateContext = createContext<MessagesStateContextProps>({
  state: { messages: [], unreadMessages: 0 },
  pending: false,
  dispatch: () => {
    throw new Error('Missing `MessageState`');
  },
});

export const useMessages = (): MessagesStateContextProps => useContext(MessagesStateContext);

type Action = ['READ', string] | ['UNREAD', string] | ['CLEAR_PROCESSING', string] | ['SET_MESSAGES', Message[]];

interface State {
  messages: Message[];
  unreadMessages: number;
  processing: string[];
}

const initialState: State = { messages: [], unreadMessages: 0, processing: [] };

const reducer = (state: State, action: Action): State => {
  const { messages, processing } = state;
  switch (action[0]) {
    case 'READ': {
      const list = [...messages];
      const messageIndex = list.findIndex((m) => m.id === action[1]);
      const message = messageIndex >= 0 ? list[messageIndex] : null;

      if (message && !message.hasRead) {
        list[messageIndex] = { ...message, hasRead: true };

        return {
          ...state,
          messages: [...list],
          unreadMessages: list.reduce((p, c) => (!c.hasRead ? ++p : p), 0),
          processing: [...state.processing, message.id],
        };
      }
      return state;
    }
    case 'UNREAD': {
      const list = [...messages];
      const messageIndex = list.findIndex((m) => m.id === action[1]);
      const message = messageIndex >= 0 ? list[messageIndex] : null;

      if (message && message.hasRead) {
        list[messageIndex] = { ...message, hasRead: false };

        return {
          ...state,
          messages: [...list],
          unreadMessages: list.reduce((p, c) => (!c.hasRead ? ++p : p), 0),
          processing: [...state.processing],
        };
      }
      return state;
    }
    case 'CLEAR_PROCESSING': {
      return {
        ...state,
        processing: [...processing.filter((id) => id !== action[1])],
      };
    }
    case 'SET_MESSAGES': {
      return {
        messages: [...action[1].sort((a, b) => b.createdAt - a.createdAt)],
        unreadMessages: action[1].reduce((p, c) => (!c.hasRead ? ++p : p), 0),
        processing: [...state.processing],
      };
    }
  }
};

const ReadMessageTask: FC<{ id: string }> = ({ id }) => {
  const { dispatch } = useContext(MessagesStateContext);
  const task = useReadMessageRequest(useMemo(() => [id], [id]));

  useEffect(() => {
    if (task && task.result) {
      dispatch(['CLEAR_PROCESSING', id]);
    }
  }, [dispatch, id, task]);

  useEffect(() => {
    if (task && task.error) {
      dispatch(['UNREAD', id]);
    }
  }, [dispatch, id, task]);

  return null;
};

export const MessagesState: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { messages: intialMessages, loading } = useMessagesProvider();
  const { messages, unreadMessages, processing } = state;
  useEffect(() => {
    if (intialMessages) {
      dispatch(['SET_MESSAGES', intialMessages]);
    }
  }, [intialMessages]);

  const pending = useMemo(() => loading || (intialMessages && intialMessages.length && !messages.length) || false, [
    intialMessages,
    loading,
    messages.length,
  ]);
  const tasks = useMemo(() => processing.map((id) => createElement(ReadMessageTask, { id, key: id })), [processing]);

  const value = useMemo<MessagesStateContextProps>(() => ({ state: { messages, unreadMessages }, pending, dispatch }), [
    messages,
    pending,
    unreadMessages,
  ]);

  return createElement(MessagesStateContext.Provider, { value }, children, tasks);
};
