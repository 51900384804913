/* eslint-disable */
import React from 'react';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useBootState, useUserEmailHmac, useIsSupervisor } from '../boot';
import { Falsy } from 'react-hooks-async';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { Route, useRoute } from '../Routes';
import { useProvider } from '../util/useProvider';
import { BusinessStreamName, Centre, getBaseURL, ParsedIdToken, Result, useAuthRequest } from '../api';
import { BusinessStream } from '../centres/businessStreamCheck';
import { useUserInfo } from '../userSession';
import { canCallEndpoint } from './canCallEndpoint';
import { getBusinessStreamsFromCentre } from './roles';

const useCustomers = (run: boolean, businessStream: BusinessStreamName): Result<Centre[]> => {
  const params = useMemo((): AxiosRequestConfig | Falsy => {
    return run && { url: '/customers', baseURL: getBaseURL(businessStream) };
  }, [run, businessStream]);
  return useAuthRequest<Centre[]>(params);
};

type CustomerError = 'failed_to_load_customers';
type CustomerBusinessUnitError = 'failed_to_load_customers_business_unit';

interface CentresContextProps {
  centresList: Centre[] | null;
  error: CustomerError | CustomerBusinessUnitError | null;
  selectedCentre: Centre | null;
  demoCentre: Centre;
  hasNoCentres: boolean;
  loading: boolean;
  custLoading: boolean;
  businessStream: BusinessStreamName;
  allowedStreams: BusinessStream[];
  redirectToBusiness: boolean;
  setRedirectBusiness: React.Dispatch<React.SetStateAction<boolean>>;
  setBusinessStream: (businessStream: BusinessStreamName) => void;
}

const initialDemoCentre: Centre = {
  id: 'DEMO',
  name: 'DEMO',
  roles: [],
  productGroup: [],
};

const Context = createContext<CentresContextProps>({
  error: null,
  centresList: null,
  selectedCentre: null,
  demoCentre: initialDemoCentre,
  hasNoCentres: false,
  loading: false,
  custLoading: false,
  businessStream: null,
  allowedStreams: [],
  redirectToBusiness: false,
  setRedirectBusiness: () => {},
  setBusinessStream: () => {},
});

export const useCentres = (): CentresContextProps => useContext(Context);

const getSelectedCentre = (hmac: string): Centre | null => {
  try {
    const selectedCentre = sessionStorage.getItem(hmac);
    return selectedCentre && JSON.parse(selectedCentre);
  } catch (error) {
    return null;
  }
};

const setSelectedCentre = (hmac: string, centre: Centre): void => sessionStorage.setItem(hmac, JSON.stringify(centre));
const clearSelectedCentre = (hmac: string): void => sessionStorage.removeItem(hmac);

const clearBusinessStream = (): void => sessionStorage.removeItem('BU');

export const isValidCentre = (centre: Centre, centreList: Centre[]): boolean =>
  centreList.some((c) => c.id === centre.id);

export const CentresProvider: FC = ({ children }) => {
  const route = useRoute();
  const boot = useBootState();
  const userInfo = useUserInfo();
  const { dispatch } = boot;
  const user = useMemo(() => !boot.loading && userInfo.agreedToEULA && boot.user, [boot, userInfo]);
  const canCallUseCustomersCE = canCallEndpoint(user, [BusinessStream.ENGLISH]);
  const canCallUseCustomersCI = canCallEndpoint(user, [
    BusinessStream.INTERNATIONAL,
    BusinessStream.LEGACY_INTERNATIONAL,
  ]);
  const { result: resultCE, pending: pendingCE, error: cerrorCE } = useCustomers(
    !!canCallUseCustomersCE,
    BusinessStream.ENGLISH,
  );
  const { result: resultCI, pending: pendingCI, error: cerrorCI } = useCustomers(
    !!canCallUseCustomersCI,
    BusinessStream.INTERNATIONAL,
  );
  const [businessStream, setBusiness] = useState<BusinessStreamName>(
    (sessionStorage.getItem('BU') as BusinessStreamName) ?? null,
  );
  const [shouldRedirectToBusiness, setRedirectBusiness] = useState<boolean>(false);
  const [allowedStreams, setAllowedStreams] = useState<BusinessStream[]>([]);

  const setBusinessStream = (businessStream: BusinessStreamName): void => {
    sessionStorage.setItem('BU', businessStream as string);
    setBusiness(businessStream);
  };

  const redirectOrSetBusinessStream = (centre: Centre) => {
    if (centre.productGroup && centre.productGroup.length) {
      const { isHelpdesk, isInternalCompliance, businessStream: userLevelBusinessStream } = user as ParsedIdToken;
      let allowedBusinessStreams: BusinessStream[] = [];
      if ((isHelpdesk || isInternalCompliance) && userLevelBusinessStream) {
        allowedBusinessStreams = Array.from(new Set (userLevelBusinessStream
          .replace(/ /g, '')
          .replace(BusinessStream.LEGACY_INTERNATIONAL, BusinessStream.INTERNATIONAL)
          .split(',')
          .filter((userBusinessStream) =>
            [BusinessStream.ENGLISH, BusinessStream.INTERNATIONAL].includes(userBusinessStream as BusinessStream),
          )
          .filter((allowedBusinessStream) =>
            centre.productGroup?.includes(allowedBusinessStream as BusinessStreamName),
          ))) as BusinessStream[];
      } else {
        allowedBusinessStreams = getBusinessStreamsFromCentre(centre);
      }

      setAllowedStreams(allowedBusinessStreams);

      if (!allowedBusinessStreams.includes(businessStream as BusinessStream)) {
        if (allowedBusinessStreams.length > 1) {
          setRedirectBusiness(true);
        } else {
          if (allowedBusinessStreams[0] as string === BusinessStream.LEGACY_INTERNATIONAL) {
            setBusinessStream(BusinessStream.INTERNATIONAL);
          } else {
            setBusinessStream(allowedBusinessStreams[0] as BusinessStreamName);
          }
        }
      }
    }
  };

  const selected = useMemo(
    () => ((route[0] === Route.CENTRE || route[0] === Route.SUPERVISORS) && route[1].params.id) || null,
    [route],
  );

  const loading = boot.loading && !!user;

  const isCELoading = canCallUseCustomersCE !== false ? pendingCE : false;
  const isCILoading = canCallUseCustomersCI !== false ? pendingCI : false;
  const custLoading = isCELoading || isCILoading;

  const emailHmac = useUserEmailHmac();
  const hmac = useMemo(() => (user && emailHmac) || null, [emailHmac, user]);

  const lastSelectedCentre = useMemo(() => (user && user.lastSelectedCentre) || null, [user]);

  useEffect(() => {
    const checkAxiosError = (axiosError: AxiosError<any> | Error | null) => {
      if (axiosError && 'isAxiosError' in axiosError && axiosError.response) {
        if (axiosError.response.status === 401) dispatch(['RESPONSE_401', axiosError]);
        if (axiosError.response.status === 403) dispatch(['RESPONSE_403', axiosError]);
      }
    };
    checkAxiosError(cerrorCE);
    checkAxiosError(cerrorCI);
  }, [cerrorCE, cerrorCI, dispatch]);

  const error: CustomerError | null = useMemo(
    () => (!custLoading && (cerrorCE || cerrorCI) && 'failed_to_load_customers') || null,
    [cerrorCE, cerrorCI, custLoading],
  );

  const centresList = useMemo(() => {
    const ceCentres: Centre[] = resultCE || [];
    const ciCentres: Centre[] = resultCI || [];

    const map = new Map<string, Centre>();

    ceCentres.forEach((ceCentre) => {
      if (ceCentre.productGroup?.includes(BusinessStream.ENGLISH)) {
        map.set(ceCentre.id, ceCentre);
      }
    });

    ciCentres.forEach((ciCentre) => {
      if (ciCentre.productGroup?.includes(BusinessStream.INTERNATIONAL)) {
        if (map.has(ciCentre.id)) {
          const existingCentre = map.get(ciCentre.id);
          if (existingCentre && !existingCentre.productGroup?.includes(BusinessStream.INTERNATIONAL)) {
            existingCentre.productGroup?.push(BusinessStream.INTERNATIONAL);
            existingCentre.roles = Array.from(new Set(existingCentre.roles.concat(ciCentre.roles)));
            map.set(ciCentre.id, existingCentre);
          }
        } else {
          map.set(ciCentre.id, ciCentre);
        }
      }
    });
    let centres = Array.from(map.values());
    centres.forEach((centre) => {
      const supportedBusinessStreams: BusinessStream[] = [
        BusinessStream.ENGLISH,
        BusinessStream.INTERNATIONAL,
        BusinessStream.LEGACY_INTERNATIONAL,
      ];
      centre.productGroup = centre.productGroup?.filter((pg) => supportedBusinessStreams.includes(pg as BusinessStream));
    });
    // Filter out incorrect centres
    if (user && !(user.isHelpdesk || user.isInternalCompliance)) {
      centres = centres.filter(centre => getBusinessStreamsFromCentre(centre).length);
    }
    return centres;
  }, [resultCE, resultCI]);

  const demoCentre = useMemo(() => centresList.find((c) => c.id === initialDemoCentre.id) || initialDemoCentre, [
    centresList,
  ]);

  const firstCentre = useMemo(() => centresList.find((c) => c.id !== demoCentre.id) || null, [
    demoCentre.id,
    centresList,
  ]);

  let isBusinessStreamError = false;

  const selectedCentre = useMemo(() => {
    if (loading || !hmac || custLoading) return null;
    const storedCentre = getSelectedCentre(hmac);
    const selectedCentre = centresList?.find((centre) => centre.id === selected) || null;

    if (selected) {
      if (selectedCentre) {
        setSelectedCentre(hmac, selectedCentre);
        redirectOrSetBusinessStream(selectedCentre);
        return selectedCentre;
      }
      return null;
    }

    if (!firstCentre) {
      clearSelectedCentre(hmac);
      clearBusinessStream();
      setBusiness(null);
      return null;
    }

    if (storedCentre && isValidCentre(storedCentre, centresList)) {
      redirectOrSetBusinessStream(storedCentre);
      return storedCentre;
    }

    if (lastSelectedCentre && isValidCentre(lastSelectedCentre, centresList)) {
      const { productGroup } = lastSelectedCentre;
      if (productGroup && Array.isArray(productGroup)) {
        setSelectedCentre(hmac, lastSelectedCentre);
        redirectOrSetBusinessStream(lastSelectedCentre);
        return lastSelectedCentre;
      }
      isBusinessStreamError = true;
      return null;
    }
    setSelectedCentre(hmac, firstCentre);
    redirectOrSetBusinessStream(firstCentre);
    return firstCentre;
  }, [
    centresList,
    firstCentre,
    hmac,
    lastSelectedCentre,
    loading,
    selected,
    custLoading,
    canCallUseCustomersCE,
    canCallUseCustomersCI,
  ]);

  const isSupervisor = useIsSupervisor(selectedCentre && selectedCentre.id);
  useEffect(() => {
    if (isSupervisor) {
      setRedirectBusiness(false);
      setBusinessStream(BusinessStream.ENGLISH);
    }
  }, [isSupervisor]);

  const hasNoCentres = useMemo(
    () => centresList.length === 0,
    [] || (centresList.length === 1 && centresList[0].id === 'DEMO'),
  );

  const value = useMemo<CentresContextProps>(
    () => ({
      loading,
      custLoading,
      error: isBusinessStreamError ? 'failed_to_load_customers_business_unit' : error,
      centresList,
      selectedCentre,
      demoCentre,
      hasNoCentres,
      businessStream,
      allowedStreams,
      redirectToBusiness: shouldRedirectToBusiness,
      setRedirectBusiness,
      setBusinessStream,
    }),
    [
      error,
      centresList,
      selectedCentre,
      demoCentre,
      hasNoCentres,
      loading,
      custLoading,
      businessStream,
      shouldRedirectToBusiness,
      setRedirectBusiness,
      setBusinessStream,
    ],
  );

  return useProvider(Context, value, children);
};
