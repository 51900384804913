import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { childTestID } from '../../util/test-id';
import { Calendar } from './Calendar';
import { getUtcMonthStart, utcDate, toDateString, addUtcMonth, substractUtcMonth } from './date-util';
import { CalendarPairProps } from './types';
import { usePairNavigation } from './usePairNavigation';
import { Group } from './views';
import { Dayjs } from 'dayjs';

export const CalendarPair: FC<CalendarPairProps> = ({
  id,
  selected,
  onSelectedChange,
  range,
  noFutureDates,
  maxSpanOfDaysBetweenDates,
  yearFrom,
  ...rest
}) => {
  const nview = getUtcMonthStart(utcDate());
  const [date, setDate] = useState(nview);
  const next = addUtcMonth(date, 1);
  const initialNext = addUtcMonth(nview, 1);
  const initialPrevious = substractUtcMonth(date, 1);
  const [secondActive, setSecondActive] = useState(false);

  const currentDayDate = useMemo(() => {
    const newDate = secondActive ? utcDate(next) : utcDate(date);
    return utcDate().set('year', newDate.get('year')).set('month', newDate.get('month'));
  }, [date, secondActive, next]);
  const nextActive = useMemo(() => toDateString(utcDate(currentDayDate)), [currentDayDate]);
  const [active, setActive] = useState(() => utcDate(nextActive));
  const [intermediate, setIntermediate] = useState<Dayjs>();
  const viewMount = date.get('month');
  const onActiveMove = usePairNavigation(viewMount, selected, noFutureDates, maxSpanOfDaysBetweenDates, setActive);
  useEffect(() => {
    setActive(utcDate(nextActive));
  }, [nextActive]);

  const onNextMonth = useCallback(() => {
    setDate(addUtcMonth(date, 1));
  }, [date]);

  const onPreviousMonth = useCallback(() => {
    if (yearFrom && substractUtcMonth(date, 1) <= yearFrom) {
      return;
    }
    setDate(substractUtcMonth(date, 1));
  }, [date, yearFrom]);

  const onPreviousMonthChange = useCallback(
    (month, year) => {
      if (noFutureDates) {
        month = String(Number(month) + 1);
      }
      const utc = utcDate(date);
      const result = utc.set('year', year).set('month', month);
      setSecondActive(false);
      setDate(result);
    },
    [date, noFutureDates],
  );

  const onNextMonthChange = useCallback(
    (month, year) => {
      if (noFutureDates) {
        month = String(Number(month) + 1);
      }
      const utc = utcDate(date);
      const nextMonthDate = utc.set('year', year).set('month', month);
      setSecondActive(true);
      setDate(substractUtcMonth(nextMonthDate, 1));
    },
    [date, noFutureDates],
  );

  const onFirstYearsChange = useCallback((value) => {
    setDate(utcDate(value));
  }, []);

  const onSecondYearsChange = useCallback(
    (value) => {
      if (yearFrom && substractUtcMonth(utcDate(value), 1) < yearFrom) {
        setDate(addUtcMonth(utcDate(value), 1));
        return;
      }
      setDate(substractUtcMonth(utcDate(value), 1));
    },
    [yearFrom],
  );

  return (
    <Group id={id} {...rest}>
      <Calendar
        id={childTestID(id, 'from')}
        testID={childTestID(rest.testID, 'from')}
        view={noFutureDates ? initialPrevious : date}
        initialView={noFutureDates ? initialPrevious : nview}
        active={active}
        intermediate={intermediate}
        selected={selected}
        onSelectedChange={onSelectedChange}
        onActiveChange={setActive}
        onIntermediateChange={setIntermediate}
        onActiveMove={onActiveMove}
        onPreviousMonth={onPreviousMonth}
        onMonthChange={onPreviousMonthChange}
        onYearsChange={onFirstYearsChange}
        pastMonthsCount={range.monthsBefore}
        futureMonthsCount={range.monthsAfter - 1}
        noFutureDates={noFutureDates}
        maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
        yearFrom={yearFrom}
      />
      <Calendar
        id={childTestID(id, 'to')}
        testID={childTestID(rest.testID, 'to')}
        view={noFutureDates ? date : next}
        active={active}
        intermediate={intermediate}
        selected={selected}
        onSelectedChange={onSelectedChange}
        onActiveChange={setActive}
        onIntermediateChange={setIntermediate}
        onActiveMove={onActiveMove}
        onNextMonth={onNextMonth}
        onMonthChange={onNextMonthChange}
        onYearsChange={onSecondYearsChange}
        pastMonthsCount={noFutureDates ? range.monthsBefore - 1 : range.monthsBefore}
        futureMonthsCount={range.monthsAfter - 1}
        initialView={noFutureDates ? nview : initialNext}
        noFutureDates={noFutureDates}
        maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
        yearFrom={yearFrom}
      />
      {rest.children}
    </Group>
  );
};
