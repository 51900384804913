import { FC, useCallback, useState } from 'react';
import { useCentres } from '../../centres';
import { DropdownMenuContainer } from '../DropDown';
import {
  mainWrapper,
  businessDropDownLabel,
  businessContinueButton,
  doneIcon,
  menuItem,
} from './BusinessPageLayout.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { BusinessWidget, BusinessWidgetDropDownMenu } from '../../pages/views';
import clsx from 'clsx';
import { HeadingLBold, TextMRegular, BodyBold } from '../../theme/typography.module.scss';
import { Button, AnchorButton } from '../Button';
import { useHistory } from 'react-router-dom';
import { BusinessStreamName } from '../../api';
import { BusinessStream } from '../../centres/businessStreamCheck';
import { Done } from '../Icon';

const getOptionName = (businessStream: BusinessStreamName): string => {
  switch (businessStream?.toLowerCase()) {
    case BusinessStream.ENGLISH.toLowerCase():
      return `Cambridge English`;
    case BusinessStream.INTERNATIONAL.toLowerCase():
      return `Cambridge International Education`;
    default:
      return 'Choose an awarding body';
  }
};

interface BusinessItem {
  businessStream: BusinessStreamName;
  currentBusiness: BusinessStreamName;
  testID: string | undefined;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}
const MenuItem: FC<BusinessItem> = ({ businessStream, currentBusiness, testID, onClick }) => {
  return (
    <AnchorButton
      className={menuItem}
      id={childTestID(testID, `business-${businessStream}-option`)}
      size="medium"
      variant="text"
      data-item="menuitem"
      role="option"
      onClick={onClick}
      testID={childTestID(testID, `business-${businessStream}-option`)}
      tabIndex={0}
    >
      {getOptionName(businessStream)}
      {currentBusiness === businessStream && <Done className={doneIcon} />}
    </AnchorButton>
  );
};

export const BusinessPageLayout: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => {
  const buttonId = `${testID}-trigger`;
  const menuId = `${testID}-menu`;
  const businessWidgetId = `${testID}-business-widget`;
  const subHeader = 'Before you start, please select your awarding body and centre';
  const dropdownLabel = 'Awarding body';
  const buttonText = 'Continue';

  const { selectedCentre, businessStream, setRedirectBusiness, setBusinessStream } = useCentres();
  const [selectedOption, setSelectedOption] = useState<BusinessStreamName>(businessStream);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClick = useCallback(() => setIsOpen((value) => !value), []);
  const onClickContinue = useCallback(() => {
    if (selectedCentre && selectedOption) {
      setBusinessStream(selectedOption);
      setRedirectBusiness(false);
      history.push('/centre/' + selectedCentre?.id);
    }
  }, [setRedirectBusiness, setBusinessStream, selectedCentre, selectedOption, history]);

  const onClickOption = useCallback(
    (business: BusinessStreamName) => {
      setSelectedOption(business);
      setIsOpen(false);
    },
    [setSelectedOption],
  );

  return (
    <div className={clsx(className, mainWrapper)} {...dataTestID(testID)} {...rest}>
      <h1 className={clsx(className, HeadingLBold)}>Welcome</h1>
      <span className={clsx(className, TextMRegular)}>{subHeader}</span>
      <span className={clsx(className, BodyBold, businessDropDownLabel)}>{dropdownLabel}</span>
      <DropdownMenuContainer
        testID={childTestID(testID, `business-dropdown`)}
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        downOnly
      >
        <BusinessWidget
          tabIndex={0}
          optionName={getOptionName(selectedOption)}
          testID={businessWidgetId}
          id={buttonId}
          onClick={onClick}
          active={isOpen}
          aria-controls={menuId}
        />

        <BusinessWidgetDropDownMenu
          visible={isOpen}
          id={menuId}
          aria-labelledby={buttonId}
          items={[
            <MenuItem
              currentBusiness={selectedOption}
              businessStream={BusinessStream.ENGLISH}
              onClick={() => onClickOption(BusinessStream.ENGLISH)}
              testID={`${testID}-menu-item-${BusinessStream.ENGLISH?.toLocaleLowerCase()}`}
              key={BusinessStream.ENGLISH}
            />,
            <MenuItem
              currentBusiness={selectedOption}
              businessStream={BusinessStream.INTERNATIONAL}
              onClick={() => onClickOption(BusinessStream.INTERNATIONAL)}
              testID={`${testID}-menu-item-${BusinessStream.INTERNATIONAL?.toLocaleLowerCase()}`}
              key={BusinessStream.INTERNATIONAL}
            />,
          ]}
        />
      </DropdownMenuContainer>
      <Button
        onClick={onClickContinue}
        className={businessContinueButton}
        testID={childTestID(testID, `continue-button`)}
        disabled={!selectedOption}
      >
        {buttonText}
      </Button>
    </div>
  );
};
