import { useCallback, useState } from 'react';
import { reducer } from './reducer';
import { Action, Dispatch, StateResult } from './types';

export const useDispatcher = (state: StateResult): Dispatch => {
  const [, rerender] = useState({});

  const dispatch = useCallback(
    (action: Action) => {
      const { current } = state;
      const next = reducer(current, action);
      state.current = next;

      if (current !== next) {
        rerender({});
      }
    },
    [state],
  );

  return dispatch;
};
