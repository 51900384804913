import { FC, useCallback, useMemo } from 'react';
import { CentresFilterView } from './CentresFilterView';
import { useCentres } from '../../centres';
import { CentreLink, Centre } from '../../components/CentreComboBox';
import { childTestID } from '../../util/test-id';

const escapeRegex = (input: string): string => input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const filterOptions = (centres: Centre[], value: string): Centre[] => {
  const rex = new RegExp(value.split('').map(escapeRegex).join('.*?'), 'i');
  return centres.filter((centre) => rex.exec(`${centre.id} ${centre.name}`));
};

export const CentresFilter: FC<{ testID?: string }> = ({ testID }) => {
  const { centresList, selectedCentre, demoCentre } = useCentres();
  const list = useMemo(() => (centresList && centresList.filter((c) => c.id !== demoCentre.id)) || [], [
    centresList,
    demoCentre,
  ]);

  const readCentres = useCallback((input: string) => (!input ? list : [...filterOptions(list, input)]), [list]);

  if (list.length === 1) {
    return (
      <CentreLink
        testID={testID}
        centreId={list[0].id}
        productGroup={list[0].productGroup}
        label={`${list[0].id} ${list[0].name}`}
        selected={!!selectedCentre && selectedCentre.id === list[0].id}
      />
    );
  }

  return (
    <CentresFilterView
      id="centre-filter-component"
      testID={childTestID(testID, 'centre-filter')}
      selected={selectedCentre || null}
      centres={readCentres}
    />
  );
};
