import { FC } from 'react';
import { root } from './DateTime.module.scss';
import { WithIProps, dataTestID } from '../../util/test-id';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export enum DateFormat {
  DATE = 'DD MMM YYYY',
  DATE_WITHOUT_DAY = 'MMM YYYY',
  DATE_WITH_HOUR = 'DD MMM YYYY ha',
  TIME = 'hh.mm a',
  TIME_24 = 'HH:mm',
  DATE_SEP_DOT = 'DD.MM.YYYY',
  DATE_SEP_DASH = 'DD-MM-YYYY',
  YEAR = 'YYYY',
  MONTH = 'MMM',
}

type DateRange = [Dayjs, Dayjs];

export interface DateTimeProps extends WithIProps<'span'> {
  value: Dayjs | DateRange;
  format: string;
}

export const DateTime: FC<DateTimeProps> = ({ value, format, testID, className, children, ...rest }) =>
  Array.isArray(value) ? (
    <span className={clsx(root, className)} {...dataTestID(testID)} {...rest}>
      {dayjs(value[0]).utc().format(format)}
      {' -'}
      <br />
      {dayjs(value[1]).utc().format(format)}
      {children}
    </span>
  ) : (
    <span className={clsx(root, className)} {...dataTestID(testID)} {...rest}>
      {dayjs(value).utc().format(format)}
      {children}
    </span>
  );
