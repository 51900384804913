import { FC, MouseEvent, ReactNode, useCallback, KeyboardEvent } from 'react';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { TabButton, TabList, TabPanel } from './views';

export interface Tab {
  id: string;
  name: string;
  panel: ReactNode;
}

export interface TabsContainerProps extends WithIProps<'div'> {
  id: string;
  tabs: Tab[];
  activeTabPanel: string;
  onTabSelect: (id: string) => void;
}

export const Tabs: FC<TabsContainerProps> = ({ tabs, onTabSelect, activeTabPanel, id, testID, ...rest }) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const { key } = event;
      if (key === 'ArrowRight') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, 1);
        onTabSelect(nActiveTab);
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, -1);
        onTabSelect(nActiveTab);
      }
    },
    [activeTabPanel, onTabSelect, tabs],
  );

  const onTabClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { tabid } = event.currentTarget.dataset;
      tabid && onTabSelect(tabid);
    },
    [onTabSelect],
  );

  if (tabs.length === 1) {
    const tab = tabs[0];
    return <div {...dataTestID(testID, tab.id.toLowerCase())}>{tab.panel}</div>;
  }

  return (
    <>
      <TabList aria-label="Date range search options" testID={childTestID(testID, 'tab-list')} {...rest}>
        {tabs.map((tab) => (
          <TabButton
            id={tab.id.toLowerCase()}
            key={tab.id}
            group={id}
            tabId={tab.id}
            active={activeTabPanel === tab.id}
            onKeyDown={onKeyDown}
            onClick={onTabClick}
            testID={testID}
          >
            {tab.name}
          </TabButton>
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel id={tab.id.toLowerCase()} key={tab.id} group={id} active={tab.id === activeTabPanel} testID={testID}>
          {tab.panel}
        </TabPanel>
      ))}
    </>
  );
};

export const getTabId = (tabs: Tab[], activeTab: string, direction: -1 | 1): string => {
  const currentIndex = tabs.findIndex((tab) => tab.id === activeTab);
  const nextIndex = currentIndex + direction;
  if (nextIndex >= tabs.length) return tabs[0].id;
  if (nextIndex < 0) return tabs[tabs.length - 1].id;
  return tabs[nextIndex].id;
};
