import { AxiosError } from 'axios';
import { createContext, createElement, FC, useContext, useMemo } from 'react';
import { useRequest } from '../api';
import { createHash } from 'crypto';

interface Context {
  eulaText: string | null;
  eulaHash: string | null;
  pending: boolean;
  error: AxiosError | Error | null;
}

const Context = createContext<Context>({
  eulaText: null,
  eulaHash: null,
  pending: false,
  error: null,
});

export const useEula = (): Context => useContext(Context);

export const EulaProvider: FC = ({ children }) => {
  const { pending, error, result } = useRequest<string>(useMemo(() => ({ url: '/eula.html' }), []));

  const eulaText = useMemo(() => result, [result]);

  const eulaHash = useMemo(() => eulaText && createHash('MD5').update(eulaText).digest('hex'), [eulaText]);

  return createElement(Context.Provider, { value: { eulaText, eulaHash, pending, error } }, children);
};
