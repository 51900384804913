import { BusinessStreamName, Centre } from '../api/models';
import { BusinessStream } from './businessStreamCheck';

export enum GLRoles {
  CE_ADMIN = 'DELEGATED_ADMIN',
  CE_EXAMS_OFFICER = 'EXAMS_OFFICER',
  SUPERVISOR = 'SUPERVISOR',
  CI_ADMIN = 'GLCI_CONFIDENTIAL_ADMIN',
  CI_EXAMS_OFFICER = 'GLCI_CONFIDENTIAL_EXAMS_OFFICER',
  INTERNAL_HELPDESK = 'INTERNAL_HELPDESK',
  INTERNAL_COMPLIANCE = 'INTERNAL_COMPLIANCE',
}

export const globalRoles = [GLRoles.INTERNAL_COMPLIANCE, GLRoles.INTERNAL_HELPDESK];

export const BusinessStreamRolesMap = {
  [BusinessStream.ENGLISH]: [GLRoles.CE_EXAMS_OFFICER, GLRoles.CE_ADMIN, GLRoles.SUPERVISOR, ...globalRoles],
  [BusinessStream.INTERNATIONAL]: [GLRoles.CI_EXAMS_OFFICER, GLRoles.CI_ADMIN, ...globalRoles],
  [BusinessStream.LEGACY_INTERNATIONAL]: [GLRoles.CI_EXAMS_OFFICER, GLRoles.CI_ADMIN, ...globalRoles],
};

export function getBusinessStreamsFromCentre(centre: Centre): BusinessStream[] {
  return Object.entries(BusinessStreamRolesMap).reduce<BusinessStream[]>((acc, [businessStream, roles]) => {
    const roleExists = roles.some(
      (role) =>
        centre.roles.includes(role as any) && centre.productGroup?.includes(businessStream as BusinessStreamName),
    );
    if (roleExists) {
      acc.push(businessStream as any);
    }
    return acc;
  }, []);
}
