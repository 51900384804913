import { createRef, MutableRefObject, useMemo, useRef } from 'react';
import { Action, ReadArticles, ReducerState } from './types';

const refs = (): ReducerState['refs'] => ({ input: createRef(), container: createRef() });

const defaultReducerState: ReducerState = {
  input: '',
  articles: () => [],
  selected: null,
  focused: false,
  open: false,
  list: [],
  refs: refs(),
};

export const useReducerState = (): MutableRefObject<ReducerState> =>
  useRef(useMemo(() => ({ ...defaultReducerState, refs: refs() }), []));

type H<P> = (state: ReducerState, payload: P) => ReducerState;

const articlesChange: H<ReadArticles> = (state, payload) => {
  const { input } = state;
  const list = payload(input);
  return { ...state, articles: payload, list };
};

const inputChange: H<string> = (state, input): ReducerState => {
  const { articles } = state;
  const list = articles(input);
  return { ...state, input, list };
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  const { focused, open } = state;

  switch (action[0]) {
    case 'SHOW_SUGGEST':
      return open ? state : { ...state, open: true };

    case 'HIDE_SUGGEST':
      return !open ? state : { ...state, open: false };

    case 'FOCUS_IN':
      return focused ? state : { ...state, focused: true };

    case 'FOCUS_OUTSIDE':
      return !focused ? state : { ...state, focused: false, open: false };

    case 'SELECTED_CHANGE':
      return { ...state, selected: action[1] };

    case 'ARTICLES_CHANGE':
      return articlesChange(state, action[1]);

    case 'INPUT_CHANGE':
      return inputChange(state, action[1]);
  }
};
