import { AxiosRequestConfig } from 'axios';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { Result, useAuthRequest, SupervisorSession, SupervisorSessionCE } from '../../api';
import { useBootState } from '../../boot';
import { useCentres } from '../../centres';
import { isBusinessStreamCE } from '../../centres/businessStreamCheck';
import { utcDate } from '../../components/Calendar';
import { Route, useRoute } from '../../Routes';
import { useEventListener } from '../../util/EventDispatcher';
import { useProvider } from '../../util/useProvider';
import { SupervisorsError } from './SupervisorsProvider';

const useSessionsRequest = (run: number | Falsy, id: string | Falsy): Result<SupervisorSession[]> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => run && id && { url: `/venue-users/${id}/sessions` }, [
    run,
    id,
  ]);
  const request = useAuthRequest<SupervisorSession[]>(params);
  return (params && request) || null;
};

interface SessionsData {
  sorted: SupervisorSessionCE[];
  items: Record<string, SupervisorSessionCE>;
}

interface SupervisorsSessionsContextProps {
  loading: boolean;
  error: SupervisorsError | null;
  data: SessionsData | null;
}

const SupervisorsSessionsContext = createContext<SupervisorsSessionsContextProps>({
  loading: false,
  error: null,
  data: null,
});

export const useSupervisorsSessions = (): SupervisorsSessionsContextProps => useContext(SupervisorsSessionsContext);

export const useSupervisorsSessionItems = (ids: string[]): SupervisorSession[] => {
  const { data } = useSupervisorsSessions();

  const sessions: SupervisorSession[] = [];
  if (data) {
    for (const id of ids) {
      sessions.push(data.items[id]);
    }
  }

  return sessions;
};

const useRefteshToken = (): number => {
  const [token, setToken] = useState(1);
  useEventListener(
    'refreshList',
    useCallback(() => {
      setToken((token) => token + 1);
    }, [setToken]),
  );
  return token;
};

export const SupervisorsSessionsProvider: FC = ({ children }) => {
  const token = useRefteshToken();
  const { loading: centresLoading, custLoading: customersLoading, businessStream } = useCentres();
  const route = useRoute();
  const selected = route[0] === Route.SUPERVISORS && route[1].params.id;
  const ready = useMemo(() => !centresLoading && !customersLoading && token, [centresLoading, token, customersLoading]);

  const sessions = useSessionsRequest(isBusinessStreamCE(businessStream) && ready, selected);

  const loading = !!sessions && !(sessions.error || sessions.result);

  const cerror = sessions && sessions.error;
  const { dispatch } = useBootState();

  const [internalError, setInternalError] = useState<SupervisorsError>(null);

  useEffect(() => {
    if (cerror && 'isAxiosError' in cerror && cerror.response) {
      switch (cerror.response.status) {
        case 401:
          dispatch(['RESPONSE_401', cerror]);
          break;
        case 403:
          if (cerror.response.data.error === 'no_access_to_venue_users') {
            setInternalError('403');
          } else {
            dispatch(['RESPONSE_403', cerror]);
          }
          break;
        case 404:
          setInternalError('404');
          break;
        case 500:
          setInternalError('500');
          break;
        default:
          setInternalError('unknown_error');
          break;
      }
    } else if (cerror) {
      setInternalError('500');
    }
  }, [cerror, dispatch, businessStream, loading]);

  const input = useMemo(() => sessions?.result || null, [sessions]) as SupervisorSessionCE[] | null;
  const data = useMemo(() => (isBusinessStreamCE(businessStream) && input ? toSupervisorsSessionsData(input) : null), [
    input,
    businessStream,
  ]);
  const value = useMemo<SupervisorsSessionsContextProps>(() => ({ loading, error: internalError, data }), [
    data,
    loading,
    internalError,
  ]);
  return useProvider(SupervisorsSessionsContext, value, children);
};

export const toSupervisorsSessionsData = (response: SupervisorSessionCE[]): SessionsData => {
  const sessions = response.map((s) => ({ ...s, date: utcDate(s.date) }));
  sessions.sort((a, b) => compareKAD(a, b) || compareSitting(a, b) || compareProduct(a, b) || compareSA(a, b));

  const items = sessions.reduce<Record<string, SupervisorSessionCE>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  return { sorted: sessions, items };
};

type SessCompare = (a: SupervisorSessionCE, b: SupervisorSessionCE) => number;

interface SittingValues {
  [key: string]: number;
}

export const SITTING_VALUES: SittingValues = { AM: 0, PM: 1, EV: 2 };

const compareKAD: SessCompare = (a, b) => +a.date - +b.date;
const compareSitting: SessCompare = (a, b) => SITTING_VALUES[a.sitting] - SITTING_VALUES[b.sitting];
const compareProduct: SessCompare = (a, b) => a.product.localeCompare(b.product);
const compareSA: SessCompare = (a, b) => +a.specialArrangement - +b.specialArrangement;
