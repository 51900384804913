import clsx from 'clsx';
import { FC } from 'react';
import { TextSBold } from '../../theme/typography.module.scss';
import { root, title, actionsContainer, tabsContainer } from './AppBar.module.scss';
import { dataTestID, WithIProps } from '../../util/test-id';

export interface AppBarProps extends WithIProps<'header'> {
  testID?: string;
}

export const AppBar: FC<AppBarProps> = ({ className, testID, ...rest }) => {
  return <header className={clsx(root, className)} {...dataTestID(testID)} {...rest} />;
};

type AppBarTitleProps = WithIProps<'h1'>;

export const AppBarTitle: FC<AppBarTitleProps> = ({ testID, className, ...rest }) => (
  <h1 className={clsx(TextSBold, title, className)} {...dataTestID(testID, 'title')} {...rest} />
);

type AppBarTabsProps = WithIProps<'div'>;

export const AppBarTabs: FC<AppBarTabsProps> = ({ testID, className, ...rest }) => (
  <div className={clsx(tabsContainer, className)} {...dataTestID(testID, 'tabs-container')} {...rest} />
);

type AppBarActionsProps = WithIProps<'div'>;

export const AppBarActions: FC<AppBarActionsProps> = ({ testID, className, ...rest }) => (
  <div className={clsx(actionsContainer, className)} {...dataTestID(testID, 'actions-container')} {...rest} />
);
