import React from 'react';
import styles from './LoadingBar.module.scss';
interface LoadingBarProps {
  filename: string;
  progress: number;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ progress }) => {
  return (
    <div className={styles.bar}>
      <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
      <div className={styles.progressText}>{progress.toFixed(2)}%</div>
    </div>
  );
};

export default LoadingBar;
