import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { coords2date, date2coords, getUtcDay, utcDate, toDateString } from './date-util';
import { ActiveMoveEvent, CalendarSelected } from './types';

const calculateDateDifferenceInDays = (firstDate: Dayjs, secondDate: Dayjs) => {
  const diff = Math.abs(firstDate.valueOf() - secondDate.valueOf());
  const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

  return differenceInDays;
};

export const usePairNavigation = (
  viewMount: number,
  selected: CalendarSelected,
  noFutureDate: boolean | undefined,
  maxSpanOfDaysBetweenDates: number | undefined,
  setActive: (date: Dayjs) => void,
): ((event: ActiveMoveEvent) => void) => {
  return useCallback(
    (event: ActiveMoveEvent) => {
      const { date, direction } = event;
      const utc = utcDate(date);

      const current = toDateString(utcDate());
      const safeCurrent = utcDate(current);
      if (direction === 'up') {
        if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
          const previousDate = utcDate(date).set('date', date.get('date') - 7);
          const difference = calculateDateDifferenceInDays(selected[0], utcDate(previousDate));

          if (difference >= maxSpanOfDaysBetweenDates) return;
        }
        const next = utc.set('date', date.get('date') - 7);
        if (next.get('month') === date.get('month')) {
          setActive(next);
        }
      }

      if (direction === 'down') {
        const futureDate = utcDate(date).set('date', date.get('date') + 7);
        if (noFutureDate && +futureDate > +safeCurrent) return;

        if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
          const difference = calculateDateDifferenceInDays(selected[0], utcDate(futureDate));

          if (difference >= maxSpanOfDaysBetweenDates) return;
        }

        const next = utc.set('date', date.get('date') + 7);
        if (next.get('month') === date.get('month')) {
          setActive(next);
        }
      }

      if (direction === 'left') {
        if (getUtcDay(date) > 0) {
          const previousDate = utcDate(date).set('date', date.get('date') - 1);
          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], utcDate(previousDate));

            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          const nextDate = utc.set('date', utc.get('date') - 1);
          if (nextDate.get('month') === date.get('month')) {
            setActive(nextDate);
          } else {
            const nextMDate = nextDate.set('date', nextDate.get('date') + 7);
            setActive(nextMDate);
          }
        } else if (date.get('month') !== viewMount || noFutureDate) {
          const previousDate = utcDate(utc).subtract(1, 'month');
          const focusedDate = coords2date(utcDate(previousDate), [date2coords(date)[0], 6]);

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], focusedDate);
            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          const next = utc.set('month', utc.get('month') - 1);
          setActive(coords2date(next, [date2coords(date)[0], 6]));
        }
      }

      if (direction === 'right') {
        if (getUtcDay(date) < 6) {
          const futureDate = utcDate(date).set('date', date.get('date') + 1);

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], utcDate(futureDate));

            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          if (noFutureDate && +futureDate > +safeCurrent) return;

          const nextDate = utc.set('date', utc.get('date') + 1);
          if (nextDate.get('month') === date.get('month')) {
            setActive(nextDate);
          } else {
            const nextMDate = nextDate.set('date', nextDate.get('date') - 7);
            setActive(nextMDate);
          }
        } else if (date.get('month') === viewMount || noFutureDate) {
          const futureDate = utcDate(utc).set('month', utc.get('month') + 1);
          const focusedDate = coords2date(utcDate(futureDate), [date2coords(date)[0], 0]);

          if (noFutureDate && +focusedDate > +safeCurrent) return;

          if (maxSpanOfDaysBetweenDates && selected && selected[0]) {
            const difference = calculateDateDifferenceInDays(selected[0], focusedDate);
            if (difference >= maxSpanOfDaysBetweenDates) return;
          }

          const next = utc.set('month', utc.get('month') + 1);
          setActive(coords2date(next, [date2coords(date)[0], 0]));
        }
      }
    },
    [setActive, viewMount, maxSpanOfDaysBetweenDates, selected, noFutureDate],
  );
};
