import clsx from 'clsx';
import { FC, ReactNode, Ref, useRef } from 'react';
import { TextMRegular, TextSRegular } from '../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { SpanButton, SpanButtonProps, IconButton, IconProps, ButtonProps } from '../Button';
import { Done, Close } from '../Icon';
import {
  placeholder,
  empty,
  input,
  inputBox,
  inputLabel,
  suggestion,
  selectedCenter,
  doneIcon,
  clear,
  hidden,
} from './ReportsFilterComboBox.module.scss';

type EmptyMessageProps = WithIProps<'p'>;

const EmptyMessage: FC<EmptyMessageProps> = ({ className, testID, ...rest }) => (
  <p className={clsx(empty, TextSRegular, className)} {...dataTestID(testID)} {...rest} />
);

export const EmptyDropdownMessage: FC<EmptyMessageProps> = () => (
  <EmptyMessage>
    <strong>No articles found.</strong> Please try different search terms.
  </EmptyMessage>
);

export interface InputViewProps extends WithIProps<'input'> {
  forwardRef?: Ref<HTMLInputElement>;
  after?: ReactNode;
}

export const InputView: FC<InputViewProps> = ({ after, forwardRef, className, testID, ...rest }) => (
  <>
    <input
      ref={forwardRef}
      type="text"
      role="combobox"
      aria-autocomplete="list"
      autoComplete="off"
      className={clsx(input, TextMRegular, className)}
      {...dataTestID(testID)}
      {...rest}
    />
    {after}
  </>
);

export interface InputLabelViewProps extends WithIProps<'label'> {
  visible?: boolean;
}

export const InputLabelView: FC<InputLabelViewProps> = ({ visible, className, testID, ...rest }) => (
  <label
    className={clsx({ [inputLabel]: !visible, [placeholder]: visible }, TextMRegular, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export const InputContainerView: FC<WithIProps<'div'>> = ({ className, testID, children, ...rest }) => (
  <div className={clsx(inputBox, className)} role="none" {...dataTestID(testID)} {...rest}>
    {children}
  </div>
);

export interface SelectionProps extends SpanButtonProps {
  articleId: string;
  label: string;
  selected: boolean;
}

export const Selection: FC<SelectionProps> = ({ id, articleId, label, selected, testID, className, ...rest }) => {
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <SpanButton
      forwardRef={ref}
      id={childTestID(id, `article-${articleId}-link`)}
      className={clsx(TextSRegular, suggestion, { [selectedCenter]: selected }, className)}
      size="small"
      variant="text"
      role="option"
      data-item="menuitem"
      {...(selected ? { 'aria-selected': 'true' } : {})}
      testID={childTestID(testID, `article-${articleId}-link`)}
      {...rest}
    >
      {label}
      {selected && <Done className={doneIcon} />}
    </SpanButton>
  );
};

type ClearProps = IconProps<ButtonProps>;

export const Clear: FC<ClearProps> = ({ disabled, className, children, ...rest }) => (
  <IconButton
    variant="text"
    disabled={disabled}
    className={clsx(clear, { [hidden]: disabled }, className)}
    data-item={disabled ? undefined : 'menuitem'}
    tabIndex={0}
    {...rest}
  >
    <Close />
    {children}
  </IconButton>
);
