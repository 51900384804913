import { FC, Fragment, useCallback, useMemo, useRef, useEffect } from 'react';
import { childTestID } from '../../util/test-id';
import { CalendarSelected } from '../Calendar';
import { CalendarTabsContainer } from './containers';
import styles from './DatePicker.module.scss';
import { reducer, useReducerState } from './reducer';
import { StateResult, Tab, TabInformation, Range } from './types';
import { useBarInfo } from './useBarInfo';
import { useDispatcher } from './useDispatcher';
import { useFocusHandler } from './useFocusHandler';
import { useKeyboardHandler } from './useKeyboardHandler';
import {
  AssistiveHint,
  Bar,
  Clear,
  ContainerView,
  ContainerViewProps,
  DatePickerDialog,
  DialogPickerActions,
  Wrapper,
} from './views';
import { toDateString, utcDate } from '../Calendar';
import { Dayjs } from 'dayjs';

export interface DatePickerProps extends ContainerViewProps {
  id: string;
  selected: Tab | null;
  tabs: TabInformation[];
  onApply: (selectedTab: Tab | null) => void;
  range: Range;
  noFutureDates?: boolean;
  maxSpanOfDaysBetweenDates?: number;
  yearFrom?: Dayjs;
}

const useStateFromProps = (state: StateResult, { selected, tabs }: DatePickerProps) => {
  useMemo(() => {
    state.current = reducer(state.current, ['SET_TABS', tabs]);
  }, [state, tabs]);

  useMemo(() => {
    state.current = reducer(state.current, ['SET_SELECTED', selected]);
  }, [selected, state]);
};

const useClickOutside = (ref: React.RefObject<HTMLDivElement>, action: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, action]);
};

export const DatePicker: FC<DatePickerProps> = (props: DatePickerProps) => {
  const { tabs, onApply, range, noFutureDates, maxSpanOfDaysBetweenDates, yearFrom, ...rest } = props;
  const state = useReducerState();
  const dispatch = useDispatcher(state);
  useStateFromProps(state, props);
  useFocusHandler(state, dispatch);

  const skipOpen = useRef(false);

  const { id, testID } = props;
  const { current } = state;
  const { open, focused, selected, activeTabPanel, refs } = current;

  const onClick = useCallback(() => {
    if (!skipOpen.current) dispatch(['SHOW_DIALOG']);
    skipOpen.current = false;
  }, [dispatch]);

  const onClear = useCallback(() => {
    skipOpen.current = true;
    dispatch(['CLEAR_SELECTION']);
    onApply(null);
    refs.container.current?.focus();
  }, [dispatch, onApply, refs.container]);

  const onCancel = useCallback(() => {
    skipOpen.current = true;
    dispatch(['CANCEL']);
  }, [dispatch]);

  const onKeyDown = useKeyboardHandler(state, dispatch);

  useClickOutside(refs.container, () => dispatch(['CANCEL']));

  const datePickerDialogId = childTestID(id, 'date-picker-dialog');
  const labelId = childTestID(id, 'label');
  const assistiveDialogId = childTestID(id, 'assist-dialog');
  const tabsContainerId = childTestID(id, 'tabs-container');
  const actionsId = childTestID(id, 'dialog-actions');

  const canClear = !!selected && !open;

  const onSelectedChange = useCallback(
    (selected: CalendarSelected) => {
      if (noFutureDates) {
        const date = toDateString(utcDate());
        const today = utcDate(date);
        if (selected.some((date) => date && +date > +today)) {
          return;
        }
      }

      if (maxSpanOfDaysBetweenDates) {
        if (selected[0] && selected[1]) {
          const diff = Math.abs(selected[1].valueOf() - selected[0].valueOf());
          const differenceInDays = Math.ceil(diff / (1000 * 3600 * 24));

          if (differenceInDays >= maxSpanOfDaysBetweenDates) {
            return;
          }
        }
      }
      dispatch(['SELECTION_CHANGE', selected]);
    },
    [dispatch, noFutureDates, maxSpanOfDaysBetweenDates],
  );

  const onTabSelect = useCallback(
    (id: string) => {
      dispatch(['TAB_SELECT', id]);
    },
    [dispatch],
  );

  const onApplyPress = useCallback(() => {
    skipOpen.current = true;
    const { selected } = state.current;
    dispatch(['CANCEL']);
    selected && onApply(selected);
  }, [dispatch, onApply, state]);

  const barInfo = useBarInfo({ tabs, selected, activeTabPanel, open });

  return (
    <Fragment>
      {open && <span className={styles.overlay}></span>}

      <ContainerView
        focus={focused || open}
        forwardRef={refs.container}
        onClick={onClick}
        onKeyDown={onKeyDown}
        after={
          <span>
            <Clear
              forwardRef={refs.clear}
              aria-label="Clear the selected date range"
              size="small"
              disabled={!canClear}
              onClick={onClear}
              testID={childTestID(testID, 'clear-button')}
            />
          </span>
        }
        {...rest}
      >
        <Wrapper role="button" tabIndex={0} aria-label="date picker" aria-describedby={labelId}>
          <Bar {...barInfo} id={labelId} testID={childTestID(testID, 'bar-label')} />
        </Wrapper>
        <DatePickerDialog
          id={datePickerDialogId}
          aria-labelledby={`${labelId} ${assistiveDialogId}`}
          role="dialog"
          aria-modal="true"
          testID={childTestID(testID, 'dialog')}
          visible={open}
        >
          <CalendarTabsContainer
            id={tabsContainerId}
            selectedTab={selected}
            onSelectedChange={onSelectedChange}
            onTabSelect={onTabSelect}
            tabs={tabs}
            activeTabPanel={activeTabPanel || tabs[0].id}
            testID={childTestID(testID, 'tabs-container')}
            range={range}
            noFutureDates={noFutureDates || false}
            maxSpanOfDaysBetweenDates={maxSpanOfDaysBetweenDates}
            yearFrom={yearFrom}
          />

          <DialogPickerActions
            id={actionsId}
            testID={childTestID(testID, 'dialog-actions')}
            disabled={!(selected && selected.id === activeTabPanel)}
            onApply={onApplyPress}
            onCancel={onCancel}
          />
          <AssistiveHint id={assistiveDialogId}>Cursor keys can navigate dates</AssistiveHint>
        </DatePickerDialog>
      </ContainerView>
    </Fragment>
  );
};
