import { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { AssistHint } from '../AssistHint';
import { DropdownMenuContainer } from '../DropDown';
import { useDropdownMessagesNavigation } from './hooks';
import { MessagesButton, MessagesDropDownContainer, MessagesListTitle, MessagesWrapper, Separator } from './views';

export interface MessagesDropdownProps extends WithIProps<'div'> {
  count: number;
  showCount: boolean;
  list: JSX.Element[];
  loading: boolean;
  onDropdownOpen: () => void;
}

export const MessagesDropdown: FC<MessagesDropdownProps> = ({
  count,
  showCount,
  list,
  loading,
  onDropdownOpen,
  testID,
  ...rest
}) => {
  const buttonId = `messages-list-trigger`;
  const menuId = `messages-list`;
  const messagesTitleId = 'messages-list-title';
  const assistId1 = 'messages-list-trigger-assist-1';
  const assistId2 = 'messages-list-trigger-assist-2';

  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);

  useEffect(() => {
    if (open) {
      onDropdownOpen();
    }
  }, [onDropdownOpen, open]);

  const { onKeyDown } = useDropdownMessagesNavigation(
    useMemo(() => ({ containerRef: ref, open, loading }), [loading, open]),
  );

  return (
    <DropdownMenuContainer
      testID={childTestID(testID, `messages-dropdown`)}
      open={open}
      downOnly={true}
      onClose={onClose}
      onOpen={onOpen}
      {...rest}
    >
      <MessagesButton
        testID={testID}
        id={buttonId}
        onClick={onClick}
        active={open}
        aria-controls={menuId}
        aria-expanded={open}
        aria-haspopup="true"
        aria-label="messages dropdown"
        aria-describedby={`${assistId1} ${assistId2}`}
        count={count}
        showCount={!loading && showCount}
      />
      <MessagesDropDownContainer
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        testID={childTestID(testID, menuId)}
        role="none"
        tabIndex={-1}
      >
        <MessagesListTitle id={messagesTitleId} testID={testID} key="title" role="none" onClose={onClose} />
        <Separator key="separator" role="separator" testID={testID} aria-hidden />
        <MessagesWrapper
          forwardRef={ref}
          onKeyDown={onKeyDown}
          key="list"
          list={list}
          loading={loading}
          role="none"
          testID={testID}
        />
      </MessagesDropDownContainer>
      <AssistHint id={assistId1} hide>
        total messages received: {list.length},
      </AssistHint>
      {showCount && (
        <AssistHint id={assistId2} hide>
          unread messages: {count}
        </AssistHint>
      )}
    </DropdownMenuContainer>
  );
};
