import clsx from 'clsx';
import { FC, ReactNode, KeyboardEvent, MouseEvent, useCallback, useMemo } from 'react';
import { BusinessStreamName, Sitting } from '../api';
import { DateFormat, DateTime } from '../components/DateTime';
import { SortIcon } from '../components/SortIcon';
import { Alert, Copy, DownloadChildReportIcon } from '../components/Icon';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '../components/Dialog';
import { useDialogState } from '../pages/DialogState';
import { TabList, TabPanel, TabsContainerProps, getTabId, TabButton } from '../components/Tabs';
import { useSessionSort, OrderKeyType } from '../sessions';
import { HeadingMBold, TextMBold, TextMRegular, TextSBold } from '../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../util/test-id';
import { ProgressBar } from '../notifications/views';
import { tableProgress } from '../notifications/views.module.scss';
import { SessionsFilterComboBox, SessionsDatePicker } from './index';
import {
  actionsArea,
  actionsTableCell,
  getHelp,
  pagination as paginationStyle,
  table,
  tableCell,
  tableHeaderCell,
  availabilityDate,
  articlesFilter,
  replacementFileWarning,
  newFileWarning,
  noSessionContainer,
  noSessionMessage,
  noArticleContainer,
  noArticleMessage,
  sessionsTabButton,
  activeTab,
  sessionsTabList,
  dialog,
  dialogActions,
  dialogHeader,
  dialogContent,
  cancelButton,
  centresListButton,
  childReportDownloadButton,
} from './styles.module.scss';
import { Dayjs } from 'dayjs';
import { BusinessStream, isBusinessStreamCE } from '../centres/businessStreamCheck';
import { useNotification } from '../notifications';
import { Button, ButtonProps } from '../components/Button';
import { useReports } from '../pages';
import { useIsHelpdesk, useIsInternalCompliance } from '../boot';
import { useHistory } from 'react-router-dom';

export const GetHelpMessage: FC<WithIProps<'span'>> = ({ testID, children, className, ...rest }) => (
  <span className={clsx(getHelp, className)} {...dataTestID(testID, 'get-help-message')} {...rest}>
    <Alert /> File not available. Contact support for assistance.
    {children}
  </span>
);

export const ReplacementFileWarning: FC<WithIProps<'span'>> = ({ testID, className, ...rest }) => (
  <span
    className={clsx(replacementFileWarning, className)}
    {...dataTestID(testID, 'replacement-file-warning')}
    {...rest}
  >
    Replacement file
  </span>
);

export const NewFileWarning: FC<WithIProps<'span'>> = ({ testID, ...rest }) => (
  <span className={clsx(newFileWarning, TextMBold)} {...dataTestID(testID, 'new-file-warning')} {...rest}>
    (New)
  </span>
);

interface AvailableFromMessageProps extends WithIProps<'span'> {
  date: Dayjs;
}

export const AvailableFromMessage: FC<AvailableFromMessageProps> = ({ date, testID, children, ...rest }) => (
  <span {...dataTestID(testID, 'get-help-message')} {...rest}>
    Available from <DateTime className={clsx(availabilityDate, TextMBold)} format={DateFormat.DATE} value={date} />
    {children}
  </span>
);

type ARIA_SORT = {
  asc: 'ascending';
  desc: 'descending';
  default: 'none';
};

const ARIA_SORT_VALUES: ARIA_SORT = { asc: 'ascending', desc: 'descending', default: 'none' };

export const SessionsTableHeaderCE: FC<WithIProps<'thead'>> = ({ testID, ...rest }) => {
  const {
    sort: { onClickSort, order },
  } = useSessionSort();

  const product = order.find((o) => o.key === 'product');
  const KAD = order.find((o) => o.key === 'KAD');
  const sitting = order.find((o) => o.key === 'sitting');
  const download = order.find((o) => o.key === 'download');

  return (
    <thead {...dataTestID(testID)} {...rest}>
      <tr>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'product')}
          aria-sort={(ARIA_SORT_VALUES as any)[product?.order || 'default']}
        >
          <SortIcon
            order={(product?.order as OrderKeyType) || 'default'}
            isActive={product?.order !== 'default'}
            onClick={() => {
              onClickSort('product');
            }}
            columnLabel={'Product'}
            testID={childTestID(testID, 'product')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'key-assessment-date')}
          aria-sort={(ARIA_SORT_VALUES as any)[KAD?.order || 'default']}
        >
          <SortIcon
            order={(KAD?.order as OrderKeyType) || 'default'}
            isActive={KAD?.order !== 'default'}
            onClick={() => {
              onClickSort('KAD');
            }}
            columnLabel={'Exam date'}
            testID={childTestID(testID, 'key-assessment-date')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'sitting')}
          aria-sort={(ARIA_SORT_VALUES as any)[sitting?.order || 'default']}
        >
          <SortIcon
            order={(sitting?.order as OrderKeyType) || 'default'}
            isActive={sitting?.order !== 'default'}
            onClick={() => {
              onClickSort('sitting');
            }}
            columnLabel={'Sitting'}
            testID={childTestID(testID, 'sitting')}
          />
        </th>
        <th
          className={clsx(actionsArea, tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'audio-files')}
          aria-sort={(ARIA_SORT_VALUES as any)[download?.order || 'default']}
        >
          <SortIcon
            order={(download?.order as OrderKeyType) || 'default'}
            isActive={download?.order !== 'default'}
            onClick={() => {
              onClickSort('download');
            }}
            columnLabel={'Audio files'}
            testID={childTestID(testID, 'audio-files')}
          />
        </th>
      </tr>
    </thead>
  );
};

interface SessionsTableHeaderCIProps extends WithIProps<'thead'> {
  tabId: string;
}

export const SessionsTableHeaderCI: FC<SessionsTableHeaderCIProps> = ({ tabId, testID, ...rest }) => {
  const {
    sort: { onClickSort, order },
  } = useSessionSort();

  const customers = order.find((o) => o.key === 'customers');
  const examDate = order.find((o) => o.key === 'KAD');
  const qualificationShortName = order.find((o) => o.key === 'qualificationShortName');
  const componentCode = order.find((o) => o.key === 'componentCode');
  const componentName = order.find((o) => o.key === 'componentName');
  const syllabusCode = order.find((o) => o.key === 'syllabusCode');
  const syllabusName = order.find((o) => o.key === 'syllabusName');
  const download = order.find((o) => o.key === 'download');
  const sitting = order.find((o) => o.key === 'sitting');

  return (
    <thead {...dataTestID(testID)} {...rest}>
      <tr>
        {tabId === 'CHILD_CENTRE' && (
          <th
            className={clsx(tableHeaderCell, TextSBold)}
            scope="col"
            {...dataTestID(testID, 'customers')}
            aria-sort={(ARIA_SORT_VALUES as any)[customers?.order || 'default']}
          >
            Total number of centres
          </th>
        )}
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'qualification-short-name')}
          aria-sort={(ARIA_SORT_VALUES as any)[qualificationShortName?.order || 'default']}
        >
          <SortIcon
            order={(qualificationShortName?.order as OrderKeyType) || 'default'}
            isActive={qualificationShortName?.order !== 'default'}
            onClick={() => {
              onClickSort('qualificationShortName');
            }}
            columnLabel={'Qualification name'}
            testID={childTestID(testID, 'qualification-short-name')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'syllabus-code')}
          aria-sort={(ARIA_SORT_VALUES as any)[syllabusCode?.order || 'default']}
        >
          <SortIcon
            order={(syllabusCode?.order as OrderKeyType) || 'default'}
            isActive={syllabusCode?.order !== 'default'}
            onClick={() => {
              onClickSort('syllabusCode');
            }}
            columnLabel={'Syllabus code'}
            testID={childTestID(testID, 'syllabus-code')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'component-code')}
          aria-sort={(ARIA_SORT_VALUES as any)[componentCode?.order || 'default']}
        >
          <SortIcon
            order={(componentCode?.order as OrderKeyType) || 'default'}
            isActive={componentCode?.order !== 'default'}
            onClick={() => {
              onClickSort('componentCode');
            }}
            columnLabel={'Component code'}
            testID={childTestID(testID, 'component-code')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'syllabus-name')}
          aria-sort={(ARIA_SORT_VALUES as any)[syllabusName?.order || 'default']}
        >
          <SortIcon
            order={(syllabusName?.order as OrderKeyType) || 'default'}
            isActive={syllabusName?.order !== 'default'}
            onClick={() => {
              onClickSort('syllabusName');
            }}
            columnLabel={'Syllabus name'}
            testID={childTestID(testID, 'syllabus-name')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'component-name')}
          aria-sort={(ARIA_SORT_VALUES as any)[componentName?.order || 'default']}
        >
          <SortIcon
            order={(componentName?.order as OrderKeyType) || 'default'}
            isActive={componentName?.order !== 'default'}
            onClick={() => {
              onClickSort('componentName');
            }}
            columnLabel={'Component name'}
            testID={childTestID(testID, 'component-name')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'exam-date')}
          aria-sort={(ARIA_SORT_VALUES as any)[examDate?.order || 'default']}
        >
          <SortIcon
            order={(examDate?.order as OrderKeyType) || 'default'}
            isActive={examDate?.order !== 'default'}
            onClick={() => {
              onClickSort('KAD');
            }}
            columnLabel={'Exam date'}
            testID={childTestID(testID, 'exam-date')}
          />
        </th>
        <th
          className={clsx(tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'sitting')}
          aria-sort={(ARIA_SORT_VALUES as any)[sitting?.order || 'default']}
        >
          <SortIcon
            order={(sitting?.order as OrderKeyType) || 'default'}
            isActive={sitting?.order !== 'default'}
            onClick={() => {
              onClickSort('sitting');
            }}
            columnLabel={'Sitting'}
            testID={childTestID(testID, 'sitting')}
          />
        </th>
        <th
          className={clsx(actionsArea, tableHeaderCell, TextSBold)}
          scope="col"
          {...dataTestID(testID, 'audio-files')}
          aria-sort={(ARIA_SORT_VALUES as any)[download?.order || 'default']}
        >
          <SortIcon
            order={(download?.order as OrderKeyType) || 'default'}
            isActive={download?.order !== 'default'}
            onClick={() => {
              onClickSort('download');
            }}
            columnLabel={'Audio files'}
            testID={childTestID(testID, 'audio-files')}
          />
        </th>
      </tr>
    </thead>
  );
};

export interface SessionsTableProps extends WithIProps<'table'> {
  rows: ReactNode;
  businessStream: BusinessStreamName;
  pagination?: ReactNode;
  tabId: string;
}

export const SessionsTable: FC<SessionsTableProps> = ({
  testID,
  rows,
  className,
  children,
  pagination,
  tabId,
  businessStream,
  ...rest
}) => {
  return (
    <table className={clsx(table, className)} {...dataTestID(testID)} {...rest}>
      {isBusinessStreamCE(businessStream) ? (
        <SessionsTableHeaderCE testID={childTestID(testID, 'header')} />
      ) : (
        <SessionsTableHeaderCI testID={childTestID(testID, 'header')} tabId={tabId} />
      )}
      <tbody {...dataTestID(testID, 'body')}>{rows}</tbody>
      {pagination && (
        <tfoot>
          <tr>
            <td colSpan={12} className={paginationStyle}>
              {pagination}
            </td>
          </tr>
        </tfoot>
      )}
      {children}
    </table>
  );
};

export interface SessionsRowPropsCE extends WithIProps<'tr'> {
  product: string;
  date: Dayjs;
  sitting: Sitting;
  actions: NonNullable<ReactNode>;
  specialArrangements: boolean;
}

export interface SessionsRowPropsCI extends WithIProps<'tr'> {
  tabId: string;
  actions: NonNullable<ReactNode>;
  assessmentId: string;
  assessmentShortName: string;
  qualificationShortName: string;
  componentId: string;
  componentShortName: string;
  startDate?: Dayjs;
  examDate: Dayjs;
  sitting?: Sitting;
  customers?: string[];
}

const sittings = {
  AM: 'Morning',
  PM: 'Afternoon',
  EV: 'Evening',
};

export const SessionRowCE: FC<SessionsRowPropsCE> = ({
  testID,
  product,
  date,
  actions,
  sitting,
  specialArrangements,
  ...rest
}) => (
  <tr {...dataTestID(testID)} {...rest}>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'product')}>
      {product} {specialArrangements && <span className={TextMBold}>Special Arr.</span>}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'key-assessment-date')}>
      <DateTime format={DateFormat.DATE} value={date} />
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'sitting')}>
      {sittings[sitting]}
    </td>
    <td className={clsx(tableCell, actionsTableCell, TextMRegular)} {...dataTestID(testID, 'audio-files')}>
      {actions}
    </td>
  </tr>
);

const getStartDateRepresentation = (examDate: Dayjs, startDate?: Dayjs): JSX.Element =>
  startDate
    ? examDate && <DateTime format={DateFormat.DATE} value={[startDate, examDate]} />
    : examDate && <DateTime format={DateFormat.DATE} value={examDate} />;

export const SessionRowCI: FC<SessionsRowPropsCI & { isCIDemoCentre?: boolean }> = ({
  testID,
  tabId,
  assessmentId,
  assessmentShortName,
  qualificationShortName,
  componentId,
  componentShortName,
  sitting,
  startDate,
  examDate,
  actions,
  customers,
  isCIDemoCentre,
  ...rest
}) => (
  <tr {...dataTestID(testID)} {...rest}>
    {tabId === 'CHILD_CENTRE' && (
      <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'customers')}>
        {customers && customers.length ? <CentresListAction centres={customers} /> : ''}
      </td>
    )}
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'qualification-short-name')}>
      {qualificationShortName}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'syllabus-code')}>
      {assessmentId}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'component-code')}>
      {componentId}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'syllabus-name')}>
      {assessmentShortName}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'component-name')}>
      {componentShortName}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'exam-date')}>
      {isCIDemoCentre ? '00/00/0000' : getStartDateRepresentation(examDate, startDate)}
    </td>
    <td className={clsx(tableCell, TextMRegular)} {...dataTestID(testID, 'sitting')}>
      {isCIDemoCentre ? '-' : sitting && sittings[sitting]}
    </td>
    <td className={clsx(tableCell, actionsTableCell, TextMRegular)} {...dataTestID(testID, 'audio-files')}>
      {actions}
    </td>
  </tr>
);

export interface SessionsTitleProps extends Omit<WithIProps<'h2'>, 'title'> {
  title: ReactNode;
}

export interface ParentChildTabsContainerProps extends TabsContainerProps {
  businessStream: string;
  hasChildSessions: boolean;
}

interface SessionsFilter extends WithIProps<'div'> {
  isChild?: boolean;
}

export const SessionsFilter: FC<SessionsFilter> = ({ className, isChild, ...rest }) => (
  <div className={clsx(articlesFilter, className)} {...rest}>
    <SessionsFilterComboBox isChild={isChild} />
    <SessionsDatePicker />
  </div>
);

export const PreparingFileMessage: FC<WithIProps<'div'>> = ({ testID }) => (
  <div {...dataTestID(testID, 'message')}>
    <span>Preparing file</span>
    <ProgressBar className={tableProgress} testID="sessions-table" />
  </div>
);

export interface NoSessionsMessageViewProps extends WithIProps<'div'> {
  isSupervisor: boolean;
}

export const NoSessionsMessageView: FC<NoSessionsMessageViewProps> = ({
  isSupervisor,
  testID,
  className,
  children,
  ...rest
}) => (
  <div
    aria-live="assertive"
    aria-describedby={`error-${testID}`}
    className={clsx(noSessionContainer, className)}
    {...dataTestID(testID, 'container')}
    {...rest}
  >
    <span className={clsx(noSessionMessage, HeadingMBold)} aria-describedby={testID} {...dataTestID(testID, 'message')}>
      {isSupervisor ? (
        <>
          You do not currently have any exam sessions assigned to you that have audio available for download.
          <br />
          Please contact your Centre Exams Manager for more information.
        </>
      ) : (
        <>There are currently no audio downloads available to your centre.</>
      )}
    </span>
    {children}
  </div>
);

export const NoArticlesMessageView: FC<WithIProps<'div'>> = ({ testID, className, children, ...rest }) => (
  <div
    aria-live="assertive"
    aria-describedby={`error-${testID}`}
    className={clsx(noArticleContainer, className)}
    {...dataTestID(testID, 'container')}
    {...rest}
  >
    <span className={clsx(noArticleMessage, HeadingMBold)} {...dataTestID(testID, 'message')}>
      <>Articles are not available.</>
    </span>
    {children}
  </div>
);

export const Tabs: FC<ParentChildTabsContainerProps> = ({
  hasChildSessions,
  businessStream,
  tabs,
  onTabSelect,
  activeTabPanel,
  id,
  testID,
  className,
  ...rest
}) => {
  const { onChildCentresReportDownloadClick } = useReports();
  const isInternalCompliance = useIsInternalCompliance();
  const isHelpdesk = useIsHelpdesk();
  const history = useHistory();
  const {
    sort: { resetOrder },
  } = useSessionSort();

  const isUserWithGlobalRole = useMemo(() => isHelpdesk || isInternalCompliance, [isHelpdesk, isInternalCompliance]);

  const onClick = () => {
    onChildCentresReportDownloadClick();
  };

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const { key } = event;
      if (key === 'ArrowRight') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, 1);
        onTabSelect(nActiveTab);
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        const nActiveTab = getTabId(tabs, activeTabPanel, -1);
        onTabSelect(nActiveTab);
      }
    },
    [activeTabPanel, onTabSelect, tabs],
  );

  const onTabClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { tabid } = event.currentTarget.dataset;
      tabid && onTabSelect(tabid);
      if (tabid !== activeTabPanel) {
        resetOrder();
        history.push(window.location.pathname);
      }
    },
    [onTabSelect, history, activeTabPanel, resetOrder],
  );

  if (tabs.length === 1) {
    const tab = tabs[0];
    return <div {...dataTestID(testID, tab.id.toLowerCase())}>{tab.panel}</div>;
  }

  return (
    <>
      <TabList
        aria-label="Parent and child centres switch"
        testID={childTestID(testID, 'tab-list')}
        {...rest}
        className={sessionsTabList}
      >
        {tabs.map((tab) => (
          <TabButton
            id={tab.id.toLowerCase()}
            key={tab.id}
            group={id}
            tabId={tab.id}
            active={activeTabPanel === tab.id}
            onKeyDown={onKeyDown}
            className={clsx(sessionsTabButton, { [activeTab]: activeTabPanel === tab.id })}
            onClick={onTabClick}
            testID={testID}
          >
            {tab.name}
          </TabButton>
        ))}
        {hasChildSessions &&
          businessStream === BusinessStream.INTERNATIONAL &&
          activeTabPanel === 'CHILD_CENTRE' &&
          !isUserWithGlobalRole && (
            <Button
              className={clsx(childReportDownloadButton, className)}
              disabled={false}
              id="downloadChildCentresReportButton"
              variant="text"
              size="small"
              startIcon={<DownloadChildReportIcon />}
              aria-controls={'downloadChildCentresReportMenu'}
              onClick={onClick}
              active={false}
            >
              Download report
            </Button>
          )}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel id={tab.id.toLowerCase()} key={tab.id} group={id} active={tab.id === activeTabPanel} testID={testID}>
          {tab.panel}
        </TabPanel>
      ))}
    </>
  );
};

export const SessionsTabContainerView: FC<WithIProps<'div'>> = ({ className, children, testID, ...rest }) => (
  <div className={clsx(className)} {...dataTestID(testID)} {...rest}>
    {children}
  </div>
);

export const CentresListDialog: FC<DialogProps> = ({ children, className, ...rest }) => (
  <Dialog className={clsx(className)} {...rest}>
    <DialogTitle className={dialogHeader} onClose={rest.onCancel} testID={childTestID(rest.testID, 'title')}>
      You will need to share these files offline with the centres listed
      <br />
      below. Click on ‘Copy centres’ to copy the list.
    </DialogTitle>
    <DialogContent className={dialogContent}>{children}</DialogContent>
  </Dialog>
);

interface CentresListActionProps extends ButtonProps {
  centres: string[];
}

export const CentresListAction: FC<CentresListActionProps> = ({ centres, testID, ...rest }) => {
  const { dispatch: dispatchNotification } = useNotification();
  const renderDialog = useDialogState();
  const onOpen = useCallback(() => {
    const close = (): void => renderDialog(null);
    const content = centres.join(', ').concat('.');
    centres &&
      renderDialog(
        <CentresListDialog className={dialog} testID={childTestID(testID, 'centres-list-dialog')} open onCancel={close}>
          <p>{content}</p>
          <div className={dialogActions}>
            <Button
              type="button"
              variant="text"
              className={clsx(cancelButton)}
              onClick={close}
              testID={childTestID(testID, 'centres-list-dialog-action-cancel')}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(content);
                dispatchNotification(['ADD', { title: `Centre numbers copied successfully`, body: '' }, 'SUCCESS']);
              }}
              startIcon={<Copy />}
              testID={childTestID(testID, 'centres-list-dialog-action-copy')}
            >
              Copy centres
            </Button>
          </div>
        </CentresListDialog>,
      );
  }, [renderDialog, centres, testID, dispatchNotification]);
  return (
    <Button
      onClick={onOpen}
      title="View and copy centres"
      className={centresListButton}
      testID={childTestID(testID, 'centres-list-button')}
      variant="text"
      {...rest}
    >
      {centres.length}
    </Button>
  );
};
