import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Group, Option } from '../../components/FilterComboBox';
import { useProvider } from '../../util/useProvider';
import { Tab, TabInformation } from '../../components/DatePicker';
import { useSupervisorsSessions } from './SupervisorsSessionsProvider';
import {
  buildQuery,
  parseSearchQuery,
  parseDateQuery,
  readSupervisorOptions,
  buildDateQuery,
} from '../../sessions/session-filter-util';

interface FilterContextProps {
  comboBox: ComboBox;
  datePicker: DatePicker;
}

interface ComboBox {
  options: Option[];
  groups: Group[];
  selected: Option[];
  setSelected: (options: Option[]) => void;
}

interface DatePicker {
  tabs: TabInformation[];
  onRangeSelect: (selectedRange: Tab | null) => void;
  selectedRange: Tab | null;
}

const FilterContext = createContext<FilterContextProps>({
  comboBox: {
    selected: [],
    setSelected: () => {
      throw new Error('Missing `SupervisorsSessionsFilterProvider`');
    },
    options: [],
    groups: [],
  },
  datePicker: {
    onRangeSelect: () => {},
    selectedRange: null,
    tabs: [],
  },
});

export const useSupervisorSessionFilter = (): FilterContextProps => useContext(FilterContext);

const groups: Group[] = [
  { name: 'product', label: 'Product' },
  { name: 'sitting', label: 'Sitting' },
  { name: 'specialArrangement', label: 'Special arrangements', shortLabel: 'Spec arr.' },
  { name: 'supervisors', label: 'Supervisors' },
];

const tabs: TabInformation[] = [{ id: 'date', name: 'Exam date' }];

export const SupervisorsSessionsFilterProvider: FC = ({ children }) => {
  const { data } = useSupervisorsSessions();

  const options = useMemo(() => (data && readSupervisorOptions(Object.values(data.items))) || [], [data]);

  const history = useHistory();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const selected = useMemo(() => parseSearchQuery(params.get('q'), options), [options, params]);
  const selectedRange = useMemo(() => parseDateQuery(params.get('d')), [params]);

  const setSelected = useCallback(
    (selected: Option[]) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);
      params.delete('page');

      const query = buildQuery(selected);

      if (!query) params.delete('q');
      else params.set('q', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const onRangeSelect = useCallback(
    (selectedRange: Tab | null) => {
      const { pathname, search } = history.location;
      const params = new URLSearchParams(search);
      params.delete('page');

      const query = selectedRange && buildDateQuery(selectedRange);

      if (!query) params.delete('d');
      else params.set('d', query);

      history.push(`${pathname}?${params}`);
    },
    [history],
  );

  const value = useMemo<FilterContextProps>(
    () => ({
      comboBox: { setSelected, selected, options, groups },
      datePicker: { tabs, onRangeSelect, selectedRange },
    }),
    [options, selected, setSelected, selectedRange, onRangeSelect],
  );

  return useProvider(FilterContext, value, children);
};
