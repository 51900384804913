import clsx from 'clsx';
import { FC } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';

import {
  wrapper,
  line1,
  line2,
  line3,
  line4,
  line5,
  line6,
  line7,
  line8,
  line9,
  line10,
  line11,
  line12,
  line13,
} from './SoundWave.module.scss';

export const SoundWave: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => {
  const dots = [];
  for (let i = 1; i <= 37; i++) {
    dots.push(<div className={line13} key={i} />);
  }

  return (
    <div className={clsx(className, wrapper)} {...dataTestID(testID)} {...rest}>
      <div className={line1} />
      <div className={line2} />
      <div className={line3} />
      <div className={line4} />
      <div className={line5} />
      <div className={line6} />
      <div className={line7} />
      <div className={line8} />
      <div className={line9} />
      <div className={line10} />
      <div className={line11} />
      <div className={line12} />
      <div className={line9} />
      <div className={line10} />
      <div className={line1} />
      {dots}
    </div>
  );
};
