import clsx from 'clsx';
import { FC, KeyboardEvent } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { SpanButton } from '../Button';
import { SortArrowDown, SortArrowUp } from '../Icon';

import { active, sortArrowIcon, tableHeaderButton } from './SortIcon.module.scss';

interface SortArrowProps extends WithIProps<'div'> {
  order: 'asc' | 'desc' | 'default';
  isActive?: boolean;
  columnLabel: string;
  onClick: () => void;
  testID?: string;
}

export const SortIcon: FC<SortArrowProps> = ({ order, isActive, columnLabel, onClick, testID }) => {
  let arrowComponent = null;
  switch (order) {
    case 'asc':
      arrowComponent = <SortArrowDown className={sortArrowIcon} />;
      break;
    case 'desc':
      arrowComponent = <SortArrowUp className={sortArrowIcon} />;
      break;
    default:
      arrowComponent = <SortArrowDown className={sortArrowIcon} />;
  }

  const onKeyDown = (event: KeyboardEvent<HTMLSpanElement>) => {
    if (event?.key !== 'Enter') {
      return;
    }
    onClick();
  };

  return (
    <SpanButton
      variant="text"
      tabIndex={0}
      onKeyDown={(event) => {
        onKeyDown(event);
      }}
      onClick={onClick}
      className={clsx(tableHeaderButton, isActive && active)}
      testID={childTestID(testID, `sorting-${order}`)}
    >
      {columnLabel}
      {arrowComponent}
    </SpanButton>
  );
};
