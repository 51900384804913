import { ReducerState } from './types';

type H<P> = (state: ReducerState, payload: P) => ReducerState;
type HP = (state: ReducerState) => ReducerState;

export const moveSuggestionCursor: H<-1 | 1> = (state, direction) => {
  const { activeSuggest, suggested } = state;
  const last = suggested.length - 1;
  const moved = activeSuggest + direction;
  const result = moved < 0 ? 0 : moved >= last ? last : moved;
  if (result !== activeSuggest) return { ...state, activeSuggest: result };
  return state;
};

export const nextSuggestionLine: HP = (state) => {
  const { open, activeSuggest, refs } = state;

  if (!open || activeSuggest < 0) {
    return { ...state, activeSuggest: 0, activeSelect: -1, open: true };
  }

  const list = refs.suggestions.current;
  if (!list) return state;

  const elements = list.querySelectorAll<HTMLElement>('[role="option"]');

  const last = activeSuggest;
  const top = elements[last].offsetTop;
  const left = elements[last].offsetLeft;
  for (let i = last; i < elements.length; i++) {
    const e = elements[i];
    if (top !== e.offsetTop) {
      const n = elements[i + 1];
      if (!n || n.offsetTop !== e.offsetTop || Math.abs(left - n.offsetLeft) >= Math.abs(left - e.offsetLeft)) {
        return { ...state, activeSelect: -1, activeSuggest: i };
      }
    }
  }

  return { ...state, activeSelect: -1, activeSuggest: -1 };
};

export const prevSuggestionLine: HP = (state) => {
  const { open, activeSuggest, suggested, refs } = state;
  if (!open) return { ...state, open: true, activeSelect: -1, activeSuggest: suggested.length - 1 };

  const list = refs.suggestions.current;
  if (!list) return state;

  const elements = list.querySelectorAll<HTMLElement>('[role="option"]');

  if (activeSuggest < 0) {
    let last = elements.length - 1;
    const top = elements[last].offsetTop;
    for (let i = last; i-- > 0; ) {
      if (top === elements[i].offsetTop) last = i;
      else break;
    }

    return { ...state, activeSelect: -1, activeSuggest: last };
  }

  const last = activeSuggest;
  const top = elements[last].offsetTop;
  const left = elements[last].offsetLeft;
  for (let i = last; i-- > 0; ) {
    const e = elements[i];
    if (top !== e.offsetTop) {
      const n = elements[i - 1];
      if (!n || n.offsetTop !== e.offsetTop || Math.abs(left - n.offsetLeft) >= Math.abs(left - e.offsetLeft)) {
        return { ...state, activeSelect: -1, activeSuggest: i };
      }
    }
  }

  return { ...state, activeSelect: -1, activeSuggest: -1 };
};
