import { useCallback, useRef, useState } from 'react';
import { reducer } from './reducer';
import { Action, Dispatch, Option, ReducerState, StateResult } from './types';
import { useHasChanged, useRefWrap } from './util';

interface FilterComboBoxProps {
  selected: Option[];
  onSelectionChange: (options: Option[]) => void;
}

export const useDispatcher = (state: StateResult, { onSelectionChange, selected }: FilterComboBoxProps): Dispatch => {
  const [, rerender] = useState({});
  const event = useRefWrap(onSelectionChange);
  const previous = useRef<ReducerState | null>(null);

  // Rollback previouse state if onSelectionChange did not produce any effect on selection
  const changed = useHasChanged(selected);
  if (!changed && previous.current) state.current = previous.current;
  previous.current = null;

  const dispatch = useCallback(
    (action: Action) => {
      const { current } = state;
      const next = reducer(current, action);
      state.current = next;

      if (current !== next) {
        rerender({});
        if (current.selected !== next.selected) {
          // Save previous state for rollback
          previous.current = current;
          event.current(next.selected);
        }
      }
    },
    [event, state],
  );

  return dispatch;
};
