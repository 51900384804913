import clsx from 'clsx';
import { FC, Fragment, useCallback, useEffect, useRef, useState, MouseEvent } from 'react';
import { AnchorButton, Button } from '../components/Button';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '../components/Dialog';
import { Notification, NotificationContent, NotificationProps, NotificationTitle } from '../components/Notification';
import { childTestID, dataTestID } from '../util/test-id';
import { HourGlass } from '../components/Icon';
import { TextMRegular, TextMBold } from '../theme/typography.module.scss';
import {
  dialog,
  dialogContent,
  dialogActions,
  confirmationButton,
  overlay,
  hourGlass,
  notificationMessage,
} from './views.module.scss';

interface LoggedOutDialogProps extends DialogProps {
  message: string;
  link: string;
}

export const LoggedOutDialog: FC<LoggedOutDialogProps> = ({ link, message, className, ...rest }) => (
  <Dialog className={clsx(dialog, className)} open {...rest}>
    <DialogTitle onClose={rest.onCancel} testID={childTestID(rest.testID, 'title')}>
      Session expired
    </DialogTitle>
    <DialogContent className={clsx(dialogContent, TextMRegular)} testID={childTestID(rest.testID, 'content')}>
      {message}
      <div className={dialogActions}>
        <AnchorButton className={confirmationButton} href={link} testID={childTestID(rest.testID, 'action-ok')}>
          Go to My Cambridge
        </AnchorButton>
      </div>
    </DialogContent>
  </Dialog>
);

interface RedirectNotificationProps extends NotificationProps {
  reset: () => void;
  time: number;
}

export const RedirectNotification: FC<RedirectNotificationProps> = ({ testID, reset, time, className, ...rest }) => {
  const [seconds, setSeconds] = useState(time);
  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => setSeconds((prevSeconds) => prevSeconds - 1), 1000);
    }
    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Fragment>
      <span className={overlay} onClick={onClick} {...dataTestID(testID, 'overlay')} />
      <Notification onClick={onClick} className={clsx(className)} testID={testID} {...rest}>
        <NotificationTitle className={TextMBold} icon={<HourGlassIcon />} testID={testID}>
          You will be redirected in {seconds} seconds
        </NotificationTitle>
        <NotificationContent testID={testID}>
          <span className={clsx(notificationMessage, TextMRegular)}>
            You will be redirected to My Cambridge due to inactivity.
          </span>
          <Button size="small" onClick={reset} variant="outlined" testID={childTestID(testID, 'keep-working-button')}>
            Keep working
          </Button>
        </NotificationContent>
      </Notification>
    </Fragment>
  );
};

export const HourGlassIcon: FC = () => <HourGlass className={hourGlass} />;
