/* eslint-disable */
import { AxiosRequestConfig } from 'axios';
import { createContext, createElement, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { getBaseURL, Result, useAuthRequest, UserInfo } from '../api';
import { BusinessStream } from '../centres/businessStreamCheck';
import { useBootState } from '../boot';
import { SupervisorsError } from '../pages/supervisors';
import { useEventListener } from '../util/EventDispatcher';
import { useEula } from './EulaProvider';

const useUserInfoRequest = (run: number | Falsy): Result<UserInfo> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => 
    run && { url: '/user', baseURL: getBaseURL(BusinessStream.ENGLISH) }, [run]);
  const request = useAuthRequest<UserInfo>(params);
  return (params && request) || null;
};

export const useUpdateUserInfoRequest = (userInfo: UserInfo | Falsy): Result<string> | null => {
  const params = useMemo(
    (): AxiosRequestConfig | Falsy =>
      userInfo && {
        url: '/user/update',
        method: 'POST',
        data: JSON.stringify(userInfo),
        baseURL: getBaseURL(BusinessStream.ENGLISH),
      },
    [userInfo],
  );
  const request = useAuthRequest<string>(params);
  return (params && request) || null;
};
interface Context {
  userInfo: UserInfo | null;
  loading: boolean;
  error: SupervisorsError | null;
  agreedToEULA: boolean;
}

const Context = createContext<Context>({
  userInfo: null,
  loading: false,
  error: null,
  agreedToEULA: false,
});

export const useUserInfo = (): Context => useContext(Context);

const useRefteshToken = (): number => {
  const [token, setToken] = useState(1);
  useEventListener(
    'refreshUserInfo',
    useCallback(() => {
      setToken((token) => token + 1);
    }, [setToken]),
  );
  return token;
};

export const UserInfoProvider: FC = ({ children }) => {
  const [responseError, setResponseError] = useState<SupervisorsError>(null);
  const { loading: bootLoading } = useBootState();
  const token = useRefteshToken();
  const { eulaHash, error: eulaError } = useEula();

  const ready = useMemo(() => !bootLoading && eulaHash && token, [bootLoading, eulaHash, token]);
  const userInfo = useUserInfoRequest(ready);

  const loading = useMemo(() => !!eulaHash && !!userInfo && !((userInfo.error && responseError) || userInfo.result), [
    eulaHash,
    responseError,
    userInfo,
  ]);
  const error = useMemo(() => userInfo && userInfo.error, [userInfo]);

  const { dispatch } = useBootState();

  useEffect(() => {
    if (error && 'isAxiosError' in error && error.response) {
      switch (error.response.status) {
        case 401:
          dispatch(['RESPONSE_401', error]);
          break;
        case 403:
          dispatch(['RESPONSE_403', error]);
          break;
        case 500:
          setResponseError('500');
          break;
        default:
          setResponseError('unknown_error');
          break;
      }
    } else if (error || eulaError) {
      setResponseError('500');
    }
  }, [error, eulaError, dispatch]);

  const data = useMemo(() => userInfo?.result || null, [userInfo]);
  const eulaVersion = useMemo(() => data && data.eulaVersion, [data]);
  const agreedToEULA = useMemo(() => !!eulaHash && !!eulaVersion && eulaVersion === eulaHash, [eulaHash, eulaVersion]);

  return createElement(
    Context.Provider,
    { value: { userInfo: data, loading, error: responseError, agreedToEULA } },
    children,
  );
};
