import { FC } from 'react';
import { ErrorPageLayout } from '../components/ErrorPageLayout';
import { SoundWave } from '../components/SoundWave';
import { GoToHomepageButton } from './views';

export const InternalServerErrorPage: FC = () => {
  const upperContentSection = 'The server encountered an internal error and was unable to complete your request.';
  const lowerContentSection = 'Please go back and try again.';

  return (
    <ErrorPageLayout
      errorCode="500"
      upperContentSection={upperContentSection}
      lowerContentSection={lowerContentSection}
      testID="page-internal-error"
      rightColumnChildren={<SoundWave />}
      leftColumnChildren={<GoToHomepageButton testID="page-internal-error" />}
    />
  );
};
