import { createContext, FC, useContext, useMemo } from 'react';
import { useCurrentPage } from '../components/Pagination';
import { useProvider } from '../util/useProvider';
import { useChildSessionFilter } from './ChildSessionFilterProvider';
import { useSessionSort } from './SessionSortProvider';
import { getSortedSessions } from './sessions-sort-util';
import { useSessions } from './SessionsProvider';
import { getLastPage } from './SessionListProvider';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';
import { Session } from '../api';

interface ChildCentreSessionListContextProps {
  childCentreSessionList: string[] | null;
  totalChildCentreSessionPages: number;
}

const ChildCentreSessionListContext = createContext<ChildCentreSessionListContextProps>({
  childCentreSessionList: null,
  totalChildCentreSessionPages: 0,
});

export const useChildCentreSessionList = (): ChildCentreSessionListContextProps =>
  useContext(ChildCentreSessionListContext);

export const ChildSessionListProvider: FC = ({ children }) => {
  const { businessStream } = useCentres();
  const currentPage = useCurrentPage();
  const pageSize = 25;
  const sessions = useSessions();

  const sortMap = sessions.data?.sortedChildCentreSessions;

  const sort: Session<'processed'>[] = useMemo(() => {
    if (sortMap && (sortMap?.English || sortMap?.International)) {
      return isBusinessStreamCI(businessStream) ? [...sortMap.International] : [...sortMap.English];
    } else return [];
  }, [sortMap, businessStream]);
  const sorted = useMemo(() => sessions.data && sort, [sessions.data, sort]);
  const {
    comboBox: { filter },
  } = useChildSessionFilter();

  const {
    sort: { order },
  } = useSessionSort();

  const dataList = useMemo(() => sorted && getSortedSessions(filter([...sorted]), order, businessStream), [
    filter,
    order,
    sorted,
    businessStream,
  ]);

  const lastPage = getLastPage(dataList, pageSize);

  const sliced = useMemo(() => {
    if (currentPage > lastPage) return [];
    else return dataList && dataList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [dataList, currentPage, lastPage]);
  const list = useMemo(() => sliced && sliced.map((e) => e.id), [sliced]);

  const value = useMemo<ChildCentreSessionListContextProps>(
    () => ({ childCentreSessionList: list, totalChildCentreSessionPages: lastPage }),
    [lastPage, list],
  );

  return useProvider(ChildCentreSessionListContext, value, children);
};
