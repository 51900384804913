import { FC, useState, useCallback } from 'react';
import { childTestID, WithIProps } from '../../util/test-id';
import { DropdownMenuContainer, DropdownMenuList } from '../DropDown';
import { MonthYearLabel } from './views';
import { dropdownBox } from './styles.module.scss';

interface MonthYearDropdownProps extends WithIProps<'div'> {
  items: JSX.Element[];
  selected: string | number;
}

export const MonthYearDropdown: FC<MonthYearDropdownProps> = ({ selected, className, items, testID, ...rest }) => {
  const buttonId = `${testID}-trigger`;
  const menuId = `${testID}-menu`;

  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <DropdownMenuContainer
      testID={childTestID(testID, `articles-dropdown`)}
      open={isOpen}
      onClose={onClose}
      onClick={onOpen}
      onOpen={onOpen}
      className={className}
      downOnly
      {...rest}
    >
      <MonthYearLabel
        id={buttonId}
        aria-haspopup="true"
        aria-controls={menuId}
        aria-expanded={isOpen}
        testID={childTestID(testID, `label`)}
      >
        {selected}
      </MonthYearLabel>
      <DropdownMenuList
        visible={isOpen}
        id={menuId}
        aria-labelledby={buttonId}
        testID={menuId}
        className={dropdownBox}
        items={items}
      />
    </DropdownMenuContainer>
  );
};
