import { createContext, createElement, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TokenResponse } from '../api';
import { useBootState, useMyCambridgeLink, useUserEmailHmac } from '../boot';
import { useNotification } from '../notifications/NotificationProvider';
import { useSidebar } from '../pages';
import { useDialogState } from '../pages/DialogState';
import { useSignature } from '../singnature';
import { useUserSessionExtension } from './UserSessionExtensionProvider';
import { LoggedOutDialog, RedirectNotification } from './views';

interface Context {
  seconds: number;
  reset: () => void;
  isRunning: boolean;
}

const Context = createContext<Context>({
  seconds: 0,
  reset: () => {
    throw new Error('Missing `UserSessionTimerProvider`');
  },
  isRunning: false,
});

export const useUserSessionTimer = (): Context => useContext(Context);

const maxInactivity = 15 * 60;
let startTime = Date.now();

export const UserSessionTimerProvider: FC = ({ children }) => {
  const [seconds, setSeconds] = useState(maxInactivity);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timer>();
  const { refreshToken, token, timeout, error: refreshError } = useUserSessionExtension();
  const prevToken = useRef<TokenResponse | null>(token);

  const { dispatch } = useBootState();
  const { dispatch: dispatchSidebar } = useSidebar();
  const { dispatch: dispatchSignature } = useSignature();
  const emailHmac = useUserEmailHmac();
  const myCambridgeLink = useMyCambridgeLink();

  const modal = useDialogState();
  const { dispatch: dispatchNotification } = useNotification();

  const clearIntervalRef = useCallback(() => {
    if (intervalRef.current) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, []);

  const start = useCallback(() => {
    if (!intervalRef.current) {
      startTime = Date.now();
      setIsRunning(true);
      intervalRef.current = setInterval(
        () => setSeconds(maxInactivity - Math.floor((Date.now() - startTime) / 1000)),
        1000,
      );
    }
  }, []);

  const reset = useCallback(() => {
    clearIntervalRef();
    setSeconds(maxInactivity);
    start();
  }, [clearIntervalRef, start]);

  const resetAndRefresh = useCallback(() => {
    refreshToken();
    modal(null);
    reset();
  }, [modal, refreshToken, reset]);

  const logout = useCallback(() => {
    sessionStorage.removeItem('token');
    emailHmac && sessionStorage.removeItem(emailHmac);
    dispatchSignature(['RESET_STATE']);
    dispatchNotification(['REMOVE_ALL']);
    dispatchSidebar(['RESET_STATE']);
    dispatch(['CLEAR_STATE']);
    timeout && clearTimeout(timeout);
  }, [dispatch, dispatchNotification, dispatchSidebar, dispatchSignature, emailHmac, timeout]);

  useEffect(() => {
    start();
    return clearIntervalRef;
  }, [clearIntervalRef, start]);

  const onCancel = useCallback(() => myCambridgeLink && location.assign(myCambridgeLink), [myCambridgeLink]);

  useEffect(() => {
    if (seconds <= 0) {
      clearIntervalRef();
      modal(null);
      logout();
      myCambridgeLink && location.assign(myCambridgeLink);
    }
  }, [clearIntervalRef, logout, modal, myCambridgeLink, seconds]);

  useEffect(() => {
    if (seconds <= 60 && seconds > 0) {
      modal(<RedirectNotification testID="logging-out-notification" reset={resetAndRefresh} time={seconds} />);
    }
  }, [modal, resetAndRefresh, seconds]);

  useEffect(() => {
    if (seconds > 60 && token !== prevToken.current) {
      reset();
      prevToken.current = token;
    }
  }, [reset, seconds, token]);

  useEffect(() => {
    if (refreshError) {
      clearIntervalRef();
      modal(null);
      logout();
      myCambridgeLink &&
        modal(
          <LoggedOutDialog
            message="Your Global Listening session has expired."
            testID="logged-out-dialog"
            onCancel={onCancel}
            link={myCambridgeLink}
          />,
        );
    }
  }, [clearIntervalRef, logout, modal, myCambridgeLink, onCancel, refreshError, seconds]);

  return createElement(Context.Provider, { value: { reset, seconds, isRunning } }, children);
};
