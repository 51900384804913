import clsx from 'clsx';
import { FC, Ref, ChangeEvent } from 'react';
import { CheckBoxBlank, CheckBoxChecked } from '../Icon';
import { dataTestID, WithIProps } from '../../util/test-id';
import { checkedIcon, container, input, restingIcon } from './SupervisorsCheckBox.module.scss';

export interface SupervisorsCheckBoxProps extends WithIProps<'span'> {
  forwardRef?: Ref<HTMLInputElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: WithIProps<'input'>;
}

export const SupervisorsCheckBox: FC<SupervisorsCheckBoxProps> = ({
  forwardRef,
  onChange,
  inputProps = {},
  className,
  children,
  testID,
  ...rest
}) => {
  const { testID: inputTestID, ...iprops } = inputProps;

  return (
    <span className={clsx(container, className)} {...dataTestID(testID)} {...rest}>
      <input
        ref={forwardRef}
        onChange={onChange}
        type="checkbox"
        {...iprops}
        {...(inputTestID ? dataTestID(inputTestID) : dataTestID(testID, 'input'))}
        className={clsx(input, inputProps.className)}
      />
      <CheckBoxBlank className={restingIcon} aria-hidden="true" focusable="false" />
      <CheckBoxChecked className={checkedIcon} aria-hidden="true" focusable="false" />
      {children}
    </span>
  );
};
