import clsx from 'clsx';
import { FC } from 'react';
import { WithIProps } from '../../util/test-id';
import { visuallyhidden } from './styles.module.scss';

export interface AssistHintProps extends WithIProps<'span'> {
  hide?: boolean;
}

export const AssistHint: FC<AssistHintProps> = ({ hide, className, children, ...rest }) => (
  <span aria-hidden={hide} className={clsx(visuallyhidden, className)} {...rest}>
    {children}
  </span>
);
