import { FC } from 'react';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import clsx from 'clsx';
import { eulaContainer, eulaText as eulaTextStyle, eulaActionContainer } from './styles.module.scss';
import { Button, ButtonProps, SpanButton } from '../../components/Button';
import { TextMRegular } from '../../theme/typography.module.scss';
import { Done } from '../../components/Icon';
import sanitizeHtml from 'sanitize-html';

export type EulaFormViewProps = WithIProps<'form'>;

export const EulaFormView: FC<EulaFormViewProps> = ({ onSubmit, testID, children, ...rest }) => (
  <form onSubmit={onSubmit} {...rest}>
    <Button startIcon={<Done />} type="submit" testID={childTestID(testID, 'agree-button')}>
      Agree
    </Button>
    {children}
  </form>
);

export interface SanitizedTextProps extends WithIProps<'div'> {
  text: string;
}

const SanitizedText: FC<SanitizedTextProps> = ({ text, testID, ...rest }) => {
  const clean = sanitizeHtml(text);
  return <div dangerouslySetInnerHTML={{ __html: clean }} {...dataTestID(testID)} {...rest} />;
};

export interface EulaTextViewProps extends WithIProps<'div'> {
  eulaText: string;
}

export const EulaTextView: FC<EulaTextViewProps> = ({ eulaText, testID, className, children, ...rest }) => (
  <div className={clsx(eulaContainer, TextMRegular, className)} {...dataTestID(testID, 'container')} {...rest}>
    <SanitizedText text={eulaText} className={eulaTextStyle} testID={testID} />
    {children}
  </div>
);

export const Agreed: FC<ButtonProps> = ({ testID, children, ...rest }) => (
  <SpanButton disabled={true} startIcon={<Done />} testID={childTestID(testID, 'disabled-agree-button')} {...rest}>
    Agree
    {children}
  </SpanButton>
);

export const EulaActionWrapper: FC<WithIProps<'div'>> = ({ children, className, ...rest }) => (
  <div className={clsx(eulaActionContainer, className)} {...rest}>
    {children}
  </div>
);
