import clsx from 'clsx';
import { FC, ReactNode, Ref } from 'react';
import FocusLock from 'react-focus-lock';
import { TextMRegular, TextSBold } from '../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { AssistHint } from '../AssistHint';
import { Button, ButtonProps, IconButton, IconProps } from '../Button';
import { DateFormat, DateTime } from '../DateTime';
import { Calendar, Close } from '../Icon';
import styles from './DatePicker.module.scss';
import { Dayjs } from 'dayjs';

export type ClearProps = IconProps<ButtonProps>;

export const Clear: FC<ClearProps> = ({ disabled, className, children, ...rest }) => (
  <IconButton
    variant="text"
    disabled={disabled}
    className={clsx(styles.clear, { [styles.hidden]: disabled }, className)}
    tabIndex={0}
    {...rest}
  >
    <Close />
    {children}
  </IconButton>
);

export type ContentProps = WithIProps<'div'>;

export const Content: FC<ContentProps> = ({ className, testID, ...rest }) => (
  <div className={clsx(styles.contents, className)} {...dataTestID(testID)} {...rest} />
);

export type WrapperProps = WithIProps<'div'>;

export const Wrapper: FC<WrapperProps> = ({ className, testID, ...rest }) => (
  <div className={clsx(styles.wrapper, className)} {...dataTestID(testID)} {...rest} />
);

export interface ContainerViewProps extends WithIProps<'div'> {
  forwardRef?: Ref<HTMLDivElement>;
  contentProps?: ContentProps;
  before?: ReactNode;
  after?: ReactNode;
  focus?: boolean;
}

export const ContainerView: FC<ContainerViewProps> = ({
  forwardRef,
  contentProps,
  before = <Decoration />,
  after,
  focus,
  className,
  testID,
  children,
  ...rest
}) => (
  <div
    ref={forwardRef}
    className={clsx(styles.container, { [styles.focused]: focus }, className)}
    tabIndex={-1}
    {...dataTestID(testID)}
    {...rest}
  >
    {before}
    <Content {...contentProps}>{children}</Content>
    {after}
  </div>
);

export interface BarProps extends WithIProps<'div'> {
  name?: string;
  from?: Dayjs | null;
  to?: Dayjs | null;
  multiple?: boolean;
}

const toDateString = (date: Dayjs): string => date.toISOString().slice(0, 10);

export const Bar: FC<BarProps> = ({ name, from, to, multiple, className, testID, ...rest }) => {
  if (!name) {
    return (
      <div className={clsx(styles.barLabel, TextSBold, className)} {...dataTestID(testID)} {...rest}>
        <span className={clsx(TextMRegular, styles.barLabelDate)}>Filter by date</span>
        <AssistHint>, no date selected.</AssistHint>
      </div>
    );
  }

  if (!from && !multiple) {
    return (
      <div className={clsx(styles.barLabel, TextSBold, className)} {...dataTestID(testID)} {...rest}>
        <span className={clsx(TextMRegular)}>Select any date or date range</span>
        <AssistHint>, no date selected.</AssistHint>
      </div>
    );
  }

  if (!from) {
    return (
      <div className={clsx(styles.barLabel, TextSBold, className)} {...dataTestID(testID)} {...rest}>
        <span className={clsx(TextMRegular)}>Select any date or date range</span>
        <AssistHint>, no date selected.</AssistHint>
      </div>
    );
  }

  if (!to || toDateString(from) === toDateString(to)) {
    return (
      <div className={clsx(styles.barLabel, TextSBold, className)} {...dataTestID(testID)} {...rest}>
        <AssistHint>Selected date:</AssistHint>
        {name}
        {': '}
        <DateTime className={clsx(TextMRegular, styles.barLabelDate)} value={from} format={DateFormat.DATE} />
      </div>
    );
  }

  return (
    <div className={clsx(styles.barLabel, TextSBold, className)} {...dataTestID(testID)} {...rest}>
      <AssistHint>Selected date range:</AssistHint>
      {name} from:{' '}
      <DateTime className={clsx(TextMRegular, styles.barLabelDate)} value={from} format={DateFormat.DATE} /> to:{' '}
      <DateTime className={clsx(TextMRegular, styles.barLabelDate)} value={to} format={DateFormat.DATE} />
    </div>
  );
};

export type DecorationProps = WithIProps<'span'>;

export const Decoration: FC<DecorationProps> = ({ className, testID, children, ...rest }) => (
  <span aria-hidden="true" className={clsx(styles.decor, className)} {...dataTestID(testID)} {...rest}>
    <Calendar className={styles.icon} />
    {children}
  </span>
);

export interface DatePickerDialogProps extends WithIProps<'div'> {
  visible?: boolean;
}

export const DatePickerDialog: FC<DatePickerDialogProps> = ({
  visible = false,
  className,
  testID,
  children,
  ...rest
}) => {
  return (
    <FocusLock className={clsx({ [styles.hidden]: !visible })} disabled={!visible} returnFocus>
      <div className={clsx(styles.dialog, className)} {...dataTestID(testID)} {...rest}>
        {children}
      </div>
    </FocusLock>
  );
};

interface DialogPickerActionsProps extends WithIProps<'div'> {
  onApply: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

export const DialogPickerActions: FC<DialogPickerActionsProps> = ({
  onApply,
  onCancel,
  disabled = true,
  testID,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(styles.dialogActions, className)} {...dataTestID(testID)} {...rest}>
      <Button
        className={styles.cancelButton}
        variant="text"
        size="small"
        testID={childTestID(testID, 'cancel')}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button size="small" disabled={disabled} testID={childTestID(testID, 'apply')} onClick={onApply}>
        Apply
      </Button>
    </div>
  );
};

export const AssistiveHint: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span className={clsx(styles.screenReader, className)} {...dataTestID(testID)} {...rest} />
);
