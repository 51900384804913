import clsx from 'clsx';
import { FC, ReactNode, Ref, RefObject, useCallback, useMemo } from 'react';
import { useIsAdmin, useIsHelpdesk } from '../../boot';
import { Route, useRoute } from '../../Routes';
import { BusinessStreamName } from '../../api/models';
import { BusinessStream } from '../../centres/businessStreamCheck';
import { TextSRegular, TextMRegular } from '../../theme/typography.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { AnchorButton, AnchorButtonProps, ButtonProps, IconButton, IconProps } from '../Button';
import { Search, Done, Close } from '../Icon';
import { useDialogState } from '../../pages/DialogState';
import { useSignature } from '../../singnature';
import { DiscardChangesDialog } from '../../pages/views';
import {
  container,
  decor,
  empty,
  focused,
  hiddenLabel,
  icon,
  input,
  inputBox,
  inputLabel,
  suggestionListBox,
  centreLink,
  selectedCenter,
  doneIcon,
  screenReader,
  clear,
  hidden,
} from './CentreComboBox.module.scss';
import { useHistory } from 'react-router-dom';

export type EmptyMessageProps = WithIProps<'p'>;

export const EmptyMessage: FC<EmptyMessageProps> = ({ className, testID, ...rest }) => (
  <p className={clsx(empty, TextSRegular, className)} {...dataTestID(testID)} {...rest} />
);

export interface InputViewProps extends WithIProps<'input'> {
  forwardRef?: Ref<HTMLInputElement>;
}

export const InputView: FC<InputViewProps> = ({ forwardRef, className, testID, ...rest }) => (
  <input
    ref={forwardRef}
    type="text"
    role="textbox"
    data-item="menuitem"
    aria-autocomplete="list"
    autoComplete="off"
    tabIndex={-1}
    className={clsx(input, TextMRegular, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export interface InputLabelViewProps extends WithIProps<'label'> {
  visible?: boolean;
  focus: boolean;
}

export const InputLabelView: FC<InputLabelViewProps> = ({ className, visible, focus, testID, ...rest }) => (
  <label
    className={clsx(inputLabel, { [hiddenLabel]: !visible, [focused]: focus }, TextMRegular, className)}
    {...dataTestID(testID)}
    {...rest}
  />
);

export interface InputContainerViewProps extends WithIProps<'div'> {
  forwardRef?: RefObject<HTMLDivElement>;
  focus?: boolean;
}
export const InputContainerView: FC<InputContainerViewProps> = ({
  forwardRef,
  className,
  focus,
  testID,
  children,
  ...rest
}) => (
  <div
    ref={forwardRef}
    className={clsx(inputBox, { [focused]: focus }, className)}
    role="none"
    {...dataTestID(testID)}
    {...rest}
  >
    <Decoration />
    {children}
  </div>
);

export type DecorationProps = WithIProps<'span'>;

export const Decoration: FC<DecorationProps> = ({ className, testID, children, ...rest }) => (
  <span aria-hidden="true" className={clsx(decor, className)} {...dataTestID(testID)} {...rest}>
    <Search className={icon} />
    {children}
  </span>
);

export interface ContainerViewProps extends WithIProps<'div'> {
  before?: ReactNode;
  after?: ReactNode;
}

export const ContainerView: FC<ContainerViewProps> = ({ className, testID, children, ...rest }) => (
  <div className={clsx(container, className)} role="none" tabIndex={-1} {...dataTestID(testID)} {...rest}>
    {children}
  </div>
);

export interface ListBoxViewProps extends WithIProps<'div'> {
  forwardRef?: Ref<HTMLDivElement>;
}

export const ListBoxView: FC<ListBoxViewProps> = ({ className, testID, ...rest }) => (
  <div role="listbox" className={clsx(suggestionListBox, className)} {...dataTestID(testID)} {...rest} />
);

export interface CentreLinkProps extends AnchorButtonProps {
  centreId: string;
  label: string;
  selected: boolean;
  productGroup: BusinessStreamName[] | undefined;
}

export const CentreLink: FC<CentreLinkProps> = ({
  id,
  centreId,
  label,
  productGroup,
  selected,
  testID,
  className,
  ...rest
}) => {
  const route = useRoute();
  const history = useHistory();
  const isHelpdesk = useIsHelpdesk();
  const isAdmin = useIsAdmin(centreId);
  const { isDownloadActive, dispatch: dispatchSignature } = useSignature();
  const hasAccess = useMemo(() => isHelpdesk || isAdmin, [isAdmin, isHelpdesk]);
  const isSupervisorsRoute = useMemo(() => route[0] === Route.SUPERVISORS, [route]);
  const hasSupervisorsPage = useMemo(() => productGroup?.includes(BusinessStream.ENGLISH), [productGroup]);
  const href = useMemo(
    () => `/centre/${centreId}${isSupervisorsRoute && hasAccess && hasSupervisorsPage ? '/supervisors' : ''}`,
    [centreId, isSupervisorsRoute, hasAccess, hasSupervisorsPage],
  );

  const modal = useDialogState();

  const onModalCancel = useCallback(() => {
    modal(null);
  }, [modal]);

  const onClick = useCallback(() => {
    if (isDownloadActive) {
      modal(<DiscardChangesDialog href={href} onCancel={onModalCancel} testID={testID} />);
    } else {
      dispatchSignature(['RESET_STATE']);
      sessionStorage.removeItem('table');
      history.push(href);
    }
  }, [isDownloadActive, modal, href, onModalCancel, testID, dispatchSignature, history]);

  return (
    <AnchorButton
      id={childTestID(id, `centre-${centreId}-link`)}
      className={clsx(TextSRegular, centreLink, { [selectedCenter]: selected }, className)}
      size="small"
      variant="text"
      data-item="menuitem"
      role="option"
      onClick={onClick}
      testID={childTestID(testID, `centre-${centreId}-link`)}
      {...rest}
    >
      {label}
      {selected && <Done className={doneIcon} />}
    </AnchorButton>
  );
};

export const AssistiveHint: FC<WithIProps<'span'>> = ({ className, testID, ...rest }) => (
  <span className={clsx(screenReader, className)} {...dataTestID(testID)} {...rest} />
);

export type ClearProps = IconProps<ButtonProps>;

export const Clear: FC<ClearProps> = ({ disabled, className, children, ...rest }) => (
  <IconButton
    variant="text"
    disabled={disabled}
    className={clsx(clear, { [hidden]: disabled }, className)}
    data-item={disabled ? undefined : 'menuitem'}
    tabIndex={-1}
    role="menuitem"
    {...rest}
  >
    <Close />
    {children}
  </IconButton>
);
