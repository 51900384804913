import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { dataTestID, WithIProps } from '../../util/test-id';
import { IconButton } from '../Button';
import { Close as CloseIcon } from '../../components/Icon';
import { HeadingSBold } from '../../theme/typography.module.scss';
import { closeButton, container, content, title } from './Sidebar.module.scss';

interface SidebarTitleProps extends WithIProps<'h1'> {
  onClose?: () => void;
}

export const Sidebar: FC<WithIProps<'div'>> = ({ children, className, testID, ...rest }) => {
  return (
    <div className={clsx(container, className)} {...dataTestID(testID)} {...rest}>
      {children}
    </div>
  );
};

export const SidebarTitle: FC<SidebarTitleProps> = ({ onClose, children, className, testID, ...rest }) => (
  <Fragment>
    {onClose && (
      <IconButton
        aria-label="close"
        className={closeButton}
        variant="text"
        size="small"
        onClick={onClose}
        {...dataTestID(testID, 'close')}
      >
        <CloseIcon />
      </IconButton>
    )}
    <h1 id="dialog-title" className={clsx(title, HeadingSBold, className)} {...dataTestID(testID)} {...rest}>
      {children}
    </h1>
  </Fragment>
);

export const SidebarContent: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => (
  <div id="sidebar-content" className={clsx(content, className)} {...dataTestID(testID)} {...rest} />
);
